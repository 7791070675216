<template>
    <b-tabs content-class="" class="mt-2" lazy>
        <b-tab title="Kunde" active>
            <div class="row customer">
                <div class="col-12 pt-2">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                            <h5>Firmainfo</h5>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="Firmanavn" class="mb-2">
                                        <b-form-input type="text" v-model="customer.name" name="name" v-validate="'required'"
                                                      data-vv-as="Firmanavn" :state="!errors.has('name') && customer.name != null" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="Adresse" class="mb-2">
                                        <b-form-textarea :rows="1" v-model="customer.address" name="address" autocomplete="disabled"></b-form-textarea>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="Postnr." class="mb-2">
                                        <b-form-input type="text" v-model="customer.zipCode" name="zipCode" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="By" class="mb-2">
                                        <b-form-input type="text" v-model="customer.city" name="city" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>

                            <div class="row" v-for="(item, i) in customer.contacts" :key="i">
                                <div class="col-12 col-lg-6">
                                    <b-input-group prepend="Navn" class="mb-2">
                                        <b-form-input type="text" v-model="item.name" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-10 col-lg-5">
                                    <b-input-group prepend="Mobil" class="mb-2">
                                        <b-form-input type="text" v-model="item.mobile" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-2 col-lg-1 text-right">
                                    <b-button type="button" variant="danger" size="sm" @click="customer.contacts.splice(i,1)"><fa icon="times" /></b-button>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <b-input-group prepend="E-mail" class="mb-2">
                                        <b-form-input type="text" v-model="item.email" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <b-input-group prepend="Tlf." class="mb-2">
                                        <b-form-input type="text" v-model="item.phone" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                            <b-button type="button" variant="outline-dark" size="sm" @click="addContact"><fa icon="plus" /> Tilføj kontaktperson</b-button>

                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <h5>&nbsp;</h5>

                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="E-mail" class="mb-2">
                                        <b-form-input type="text" v-model="customer.email" name="email" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="Telefon" class="mb-2">
                                        <b-form-input type="text" v-model="customer.phone" name="phone" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="CVR" class="mb-2">
                                        <b-form-input type="text" v-model="customer.vatNumber" name="vatNumber" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="Hjemmeside" class="mb-2">
                                        <b-form-input type="text" v-model="customer.website" name="website" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="Status" class="mb-2">
                                        <b-form-select v-model="customer.status" :options="statuses" autocomplete="disabled"></b-form-select>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="Aftale status" class="mb-2">
                                        <b-form-select v-model="customer.contractStatus" :options="contractStatuses" autocomplete="disabled"></b-form-select>
                                    </b-input-group>
                                </div>
                                <div class="col-12  col-md-6">
                                    <b-input-group prepend="Betalingsbetingelser" append="dage" class="mb-2">
                                        <b-form-input type="number" v-model="customer.paymentTerms" name="paymentTerms" class="text-right" autocomplete="disabled"></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>

                            <template v-if="!newCustomer">
                                <b-form-file ref="file" class="mb-2 mt-4" placeholder="Upload filer..." @input="uploadFile"></b-form-file>
                                <b-card no-body header="Filer">
                                    <b-list-group flush>
                                        <b-list-group-item v-for="(item, key) in files" :key="key" :href="item.path + item.file" target="_blank">
                                            <fa icon="file" class="mr-2"></fa> {{item.file}}
                                            <b-badge style="padding: 6px 7px; float: right;" @click.prevent="deleteFile(item.file, key)" class="ml-2" variant="danger" pill><fa icon="trash"></fa></b-badge>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>

                            <b-input-group prepend="Ansvarlige" class="mb-2 mt-3" v-if="users">
                                <b-form-select v-model="owner" :options="users" text-field="name" value-field="id" @input="selectUser" autocomplete="disabled"></b-form-select>
                            </b-input-group>

                            <b-input-group v-for="(item, key) in customer.owners" :key="item" class="mb-2 mr-2" :prepend="getUser(item, key).name" v-if="users">
                                <b-btn size="sm" variant="danger" @click="removeOwner(key)">
                                    <fa icon="times"></fa>
                                </b-btn>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <h5>Betalingsinfo</h5>

                            <b-input-group prepend="Sponsorkategori" class="mb-2">
                                <b-form-select v-model="customer.type" :options="sponsorTypes" autocomplete="disabled">
                                    <template slot="first">
                                        <option :value="null">Ingen</option>
                                    </template>
                                </b-form-select>
                            </b-input-group>

                            <b-input-group prepend="Faktura produkt" class="mb-2" v-if="invoiceProducts">
                                <b-form-select v-model="customer.invoiceProductId" :options="invoiceProducts" autocomplete="disabled">
                                    <template slot="first">
                                        <option :value="null">Ingen</option>
                                    </template>
                                </b-form-select>
                            </b-input-group>

                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="Dato start" class="mb-2">
                                        <v-date-picker mode="single" popover-visibility="focus" autocomplete="disabled"
                                                       :value="$moment(customer.dateStart).toDate()" @input="customer.dateStart = $moment($event).utc().format()"
                                                       title-transition="none" weeks-transition="none" class="form-control"
                                                       :input-props="{ class: 'form-control', readOnly: true }">
                                        </v-date-picker>
                                    </b-input-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-input-group prepend="Dato slut" class="mb-2">
                                        <v-date-picker mode="single" popover-visibility="focus" autocomplete="disabled"
                                                       :value="$moment(customer.dateEnd).toDate()" @input="endDateChange($event)"
                                                       title-transition="none" weeks-transition="none" class="form-control"
                                                       :input-props="{ class: 'form-control', readOnly: true }" popover-align="right">
                                        </v-date-picker>
                                    </b-input-group>
                                </div>
                            </div>

                            <b-input-group prepend="Genforhandling" class="mb-2">
                                <v-date-picker mode="single" popover-visibility="focus" autocomplete="disabled"
                                               :value="$moment(customer.renegotiationDate).toDate()" @input="customer.renegotiationDate = $moment($event).utc().format()"
                                               title-transition="none" weeks-transition="none" class="form-control"
                                               :input-props="{ class: 'form-control', readOnly: true }">
                                </v-date-picker>
                            </b-input-group>

                            <b-form-checkbox v-model="customer.vatExemption" class="mb-2">
                                Momsfritaget
                            </b-form-checkbox>

                            <b-form-checkbox v-model="customer.underRenegotiation" class="mb-2">
                                Under genforhandling
                            </b-form-checkbox>

                            <b-input-group prepend="Sponsorat pr. sæson" append="kr." class="mb-2 mt-2">
                                <b-form-input type="number" v-model="customer.sponsorship" name="sponsorship" class="text-right" autocomplete="disabled"></b-form-input>
                            </b-input-group>

                            <b-input-group v-for="(item, i) in sponsorshipYears" :key="i" :prepend="'Sponsorat i ' + item.year" class="mb-2">
                                <b-form-input type="number" v-model="item.value" class="text-right" autocomplete="disabled"></b-form-input>

                                <template #append>
                                    <b-form-select v-model="item.status" :options="invoiceStatus" autocomplete="disabled" class=""></b-form-select>
                                </template>
                            </b-input-group>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <hr />
                </div>

                <div class="grouped-products col-12 col-md-4" v-for="(group, key) in groupedProducts" :key="'gp-' + key">
                    <h5>
                        <span v-if="key == 0">Erhvervsklub</span>
                        <span v-if="key == 1">Reklame & eksponering</span>
                        <span v-if="key == 2">Diverse</span>
                    </h5>
                    <div class="mb-2" v-for="item in group" :key="item.id">
                        <b-input-group v-if="item.type == 'bool-text'">
                            <b-input-group-prepend is-text>
                                <label class="m-0">
                                    <span class="d-inline-block float-left mr-2">{{item.name}}</span>
                                    <b-form-checkbox :id="item.id" :checked="hasProduct(item)" @change="selectProduct($event, item)" plain class="m-0 mt-1" />
                                </label>
                            </b-input-group-prepend>

                            <b-form-input type="text" v-model="item.text" @input="selectProduct(true, item)" :value="getProduct(item).text" autocomplete="disabled" />
                        </b-input-group>
                        <b-input-group v-if="item.type == 'int-text'" :prepend="item.name">
                            <b-form-input type="number" v-model="item.value" @input="selectProduct($event != '', item)" :value="getProduct(item).value" autocomplete="disabled" />
                            <b-form-input type="text" v-model="item.text" @input="selectProduct(true, item)" :value="getProduct(item).text" autocomplete="disabled" />
                        </b-input-group>
                        <b-input-group v-if="item.type == 'int'" :prepend="item.name">
                            <b-form-input type="number" v-model="item.value" @input="selectProduct($event != '', item)" :value="getProduct(item).value" autocomplete="disabled" />
                        </b-input-group>
                        <b-input-group v-if="item.type == 'bool'">
                            <b-input-group-prepend is-text>
                                <label :for="item.id" class="m-0">
                                    {{item.name}}
                                </label>
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                                <b-form-checkbox :id="item.id" @change="selectProduct($event, item)" plain class="m-0" :checked="hasProduct(item)" />
                            </b-input-group-prepend>
                        </b-input-group>
                        <b-input-group v-if="item.type == 'dropdown'">
                            <b-input-group-prepend is-text>
                                <label class="m-0">
                                    <span class="d-inline-block float-left mr-2">{{item.name}}</span>
                                    <b-form-checkbox :id="item.id" :checked="hasProduct(item)" @change="selectProduct($event, item)" plain class="m-0 mt-1" />
                                </label>
                            </b-input-group-prepend>
                            <b-form-select :options="item.typeValues" @change="x => { item.text = x; selectProduct(x != '', item); }" :value="getProduct(item).text" autocomplete="disabled" />
                        </b-input-group>
                    </div>
                </div>

                <div class="col-12">
                    <hr />
                </div>


                <div class="col-12 col-lg-6">
                    <h5>Ordrebekræftelse</h5>
                    <b-form-input type="text" v-model="customer.orderConfirmation" autocomplete="disabled" />

                    <h5 class="mt-3">Information</h5>

                    <b-form-group label="Særligt:">
                        <editor v-model="customer.optionalText"></editor>
                    </b-form-group>

                    <b-form-group label="Interne kommentarer:">
                        <editor v-model="customer.comment"></editor>
                    </b-form-group>
                </div>


                <div class="col-12 col-lg-6">
                    <h5>Fakturering</h5>

                    <table class="table table-striped" v-if="!newCustomer">
                        <thead>
                            <tr>
                                <th>Dato</th>
                                <th>Beløb</th>
                                <th>Tekst</th>
                                <th class="text-right">
                                    <b-button size="sm" variant="success" @click="createInvoices">
                                        Opret faktura
                                    </b-button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, key) in invoices" :key="'i-' + key">
                                <td>{{$moment(item.date).format("DD-MM-YYYY")}}</td>
                                <td>{{$numeral(item.value).format("$ ##.##")}}</td>
                                <td>{{item.text}}</td>
                                <td class="text-right">
                                    <span class="d-inline-block px-2" v-if="item.invoiced">Faktura sendt</span>
                                    <b-button @click="sendInvoice(key)" variant="primary" size="sm" class="py-0 mx-2" v-if="!item.invoiced">Send til e-conomic</b-button>
                                    <b-button @click="deleteInvoice(key)" variant="danger" size="sm" class="px-2 py-0"><fa icon="times" /></b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-12 action-footer">
                    <b-button type="button" variant="primary" v-if="!this.customer.id" @click="onSubmit">
                        <fa icon="plus" /> Opret kunde
                    </b-button>
                    <b-button type="button" variant="primary" v-else @click="onSubmit">
                        <fa icon="save" /> Gem ændringer på kunden
                    </b-button>

                    <b-button type="button" variant="danger" @click="onReset">
                        <fa icon="times" /> Fortryd
                    </b-button>


                    <b-button type="button" variant="success" @click="sponsorDeal" v-if="!newCustomer">
                        <fa icon="edit" /> Rediger Sponsoraftale
                    </b-button>

                    <b-button :href="'/customers/pdf/' + this.id" target="_blank" type="button" variant="info" v-if="!newCustomer">
                        <fa icon="download" /> Download Sponsoraftale
                    </b-button>

                    <b-button type="button" variant="info" @click="sendToPrintModal" v-if="!newCustomer">
                        <fa icon="envelope" /> Send til skiltefirma
                    </b-button>

                    <b-button type="button" variant="dark" @click="sendToCustomerModal" v-if="!newCustomer">
                        <fa icon="envelope" /> Send til kunde
                    </b-button>


                    <b-button type="button" variant="success" v-if="!newCustomer" v-b-modal.signature-modal>
                        <fa icon="pen-alt" /> Send til underskrift
                    </b-button>
                </div>

                <b-modal title="Opret faktura" v-model="invoiceModal" @ok="addInvoice" @cancel="newInvoices = []" ok-title="Gem faktura" cancel-title="Fortryd">
                    <div class="row">
                        <div class="col-12">
                            <b-form-group label="Antal delfakturaer:" horizontal label-class="pt-1">
                                <b-form-input type="number" v-model="partialInvoices" @input="updatePartialInvoices" autocomplete="disabled" />
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row mb-2" v-for="(item, key) in newInvoices" :key="key">
                        <div class="col-12 col-md-6">
                            <b-form-group label="Faktura dato:" class="mb-2">
                                <v-date-picker mode="single" popover-visibility="focus" autocomplete="disabled"
                                               :value="$moment(item.date).toDate()" @input="item.date = $moment($event).utc().format()"
                                               title-transition="none" weeks-transition="none" class="form-control"
                                               :input-props="{ class: 'form-control', readOnly: true }">
                                </v-date-picker>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-group label="Beløb:">
                                <b-form-input type="number" v-model="item.value" autocomplete="disabled" />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-checkbox v-model="item.invoiced" class="m-0 mt-1">Allerede afsendt</b-form-checkbox>
                        </div>
                        <div class="col-12 col-md-6 pt-1">
                            {{item.text}}
                        </div>
                    </div>
                </b-modal>

                <b-modal title="Sponsoraftale" v-model="sponsorModal" @ok="onSubmit" size="lg" modal-class="modal-full-width" ok-title="Gem sponsoraftale" cancel-title="Fortryd">
                    <table class="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Navn</th>
                                <th>Beskrivelse</th>
                                <th>Tekst</th>
                                <th>Antal</th>
                                <th></th>
                            </tr>
                        </thead>
                        <draggable element="tbody" class="" v-model="customer.products" :options="{}">
                            <tr v-for="item in customer.products" :key="item.id">
                                <td>
                                    {{getRelatedProduct(item).name}}
                                </td>
                                <td>
                                    {{getRelatedProduct(item).description}}
                                    <!-- <b-form-input type="text" v-model="item.description" autocomplete="disabled" /> -->
                                </td>
                                <td>
                                    <b-form-input type="text" v-model="item.text" v-if="getRelatedProduct(item).type == 'int-text' || getRelatedProduct(item).type == 'bool-text'" autocomplete="disabled" />
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="item.value" v-if="getRelatedProduct(item).type == 'int-text' || getRelatedProduct(item).type == 'int'" autocomplete="disabled" />
                                </td>
                                <td>
                                    <b-form-checkbox v-model="item.hidden" class="mb-0">
                                        Skjul på aftalen
                                    </b-form-checkbox>
                                </td>
                            </tr>
                        </draggable>
                    </table>
                </b-modal>

                <b-modal title="Send aftale" v-model="email.modal" @ok="sendToCustomer" @cancel="email.message = null" ok-title="Send aftale" cancel-title="Fortryd">
                    <b-form-group label="E-mail:" class="mb-2">
                        <b-form-input type="text" v-model="email.to" autocomplete="disabled" />
                    </b-form-group>

                    <b-form-group label="Send kopi til:" class="mb-2">
                        <b-form-input type="text" v-model="email.copyTo" autocomplete="disabled" />
                    </b-form-group>

                    <b-form-group label="Besked:">
                        <editor v-model="email.message"></editor>
                    </b-form-group>
                </b-modal>

                <b-modal title="Send til skiltefirma" v-model="email.printModal" @ok="sendToPrint" @cancel="email.message = null" ok-title="Send information" cancel-title="Fortryd">
                    <b-form-group label="E-mail:" class="mb-2">
                        <b-form-input type="text" v-model="email.to" autocomplete="disabled" />
                    </b-form-group>

                    <b-form-group label="Send kopi til:" class="mb-2">
                        <b-form-input type="text" v-model="email.copyTo" autocomplete="disabled" />
                    </b-form-group>

                    <b-form-group label="Besked:">
                        <editor v-model="email.message"></editor>
                    </b-form-group>
                </b-modal>


                <b-modal title="Send aftale til underskrift" id="signature-modal" @ok="sendToSigning" @show="sendToSigningModal" @cancel="email.message = null" ok-title="Send til underskrift" cancel-title="Fortryd">
                    <b-form-group label="E-mail:" class="mb-2">
                        <b-form-input type="text" v-model="email.to" autocomplete="disabled" />
                    </b-form-group>
                </b-modal>

                <v-toast ref="toast"></v-toast>
            </div>
        </b-tab>
        <b-tab title="Billetter" v-if="customer.id">
            <Tickets :customer="customer"></Tickets>
        </b-tab>
    </b-tabs>
</template>

<script>
import Tickets from "./tickets";

export default {
    data: () => ({
        customer: {
            paymentTerms: 8,
            owners: null,
            contacts: [],
            dateStart: null,
            dateEnd: null,
            sponsorshipYears: [],
            products: [],
            type: null,
            renegotiationDate: null,
            optionalText: null,
            invoices: [],
            status: null,
            underRenegotiation: false
        },
        users: null,
        statuses: [
            { text: "Lead", value: "Lead" },
            { text: "Kunde", value: "Customer" },
            { text: "Opsagt", value: "Lost" },
            { text: "Gæst", value: "Guest" }
        ],
        contractStatuses: [
            { text: "Genforhandling", value: "Genforhandling" },
            { text: "Aftale sendt 2024", value: "Aftale sendt 2024" },
            { text: "Aftale underskrevet 2024", value: "Aftale underskrevet 2024" },
            { text: "Faktura sendt", value: "Faktura sendt" },
            { text: "Betalt", value: "Betalt" },
            { text: "Manglende betaling", value: "Manglende betaling" },
            { text: "Aftale sendt 2023", value: "Aftale sendt 2023" },
        ],
        owner: null,
        contracts: null,
        products: [],
        groupedProducts: [],
        files: [],
        settings: null,
        toast: null,
        invoiceModal: false,
        newInvoices: [],
        sponsorModal: false,
        changes: false,
        email: {
            to: null,
            message: null,
            copyTo: null,
            modal: false,
            printModal: false
        },
        partialInvoices: 1,
        invoiceProducts: null,
        invoiceStatus: {
            0: "Ikke faktureret",
            10: "Faktureret",
            15: "Delvis faktureret"
        }
    }),
    components: { Tickets },
    computed: {
        id() {
            return this.$route.params.id;
        },
        newCustomer() {
            return this.$route.params.id == 'new';
        },
        sponsorshipYears() {
            if (this.customer.sponsorshipYears == null) {
                return [];
            }

            return this.customer.sponsorshipYears.sort((a, b) => a.year - b.year);
        },
        sponsorTypes() {
            if (this.settings) {
                return this.settings.sponsorTypes;
            }
            return [];
        },
        invoices() {
            if (!this.customer.invoices) {
                return [];
            }
            return this.customer.invoices.sort((x,y) => x.date < y.date ? 0 : 1);
        }
    },
    watch: {
        "customer.dateStart"() {
            this.getYears();
        },
        "customer.dateEnd"() {
            this.getYears();
        },
        customer: {
            handler(val, oldVal) {
                if (oldVal) {
                    this.changes = true;
                }
            },
            deep: true
        },
    },
	methods: {
        status(item) {
            if (item) {
                if (this.$moment().isAfter(item.durationTo)) {
                    return { status: "Udløbet", class: "info" };
                }
            }

            if (item.status) {
                if (item.status == "Accepted") {
                    return { status: "Godkendt", class: "success" };
                }
                if (item.status == "CustomerAccepted") {
                    return { status: "Godkendt af kunden", class: "success" };
                }
                if (item.status == "Draft") {
                    return { status: "Kladde", class: "warning" };
                }
                if (item.status == "Pending") {
                    return { status: "Afventer godkendelse", class: "warning" };
                }
                if (item.status == "Cancelled") {
                    return { status: "Opsagt", class: "danger" };
                }
            }

            return null;
        },
        onSubmit() {
            this.$validator.validate().then(x => {
                if (x === true) {
                    this.$http.post("/api/customers/update", this.customer).then(x => {
                        this.toast.showToast('Ændringerne blev gemt!', { theme: "success" });

                        if (this.newCustomer) {
                            this.$router.push('/customers/' + x.data);
                        } else {
                            setTimeout(() => {
                                this.changes = false;
                            }, 200);
                        }
                    });


                }
            });
        },
        onReset() {
            this.$router.push('/');
        },
        getSettings() {
            this.$http.get("/api/settings").then(x => {
                if (x.data) {
                    this.settings = x.data;
                }
            });
        },
        getUsers() {
            this.$http.get("/api/customers/users").then(x => {
                if (x.data) {
                    this.users = x.data;
                }
            });
        },
        selectUser(e) {
            if (e) {
                if (this.customer.owners == null) {
                    this.customer.owners = [];
                }
                if (this.customer.owners.indexOf(e) == -1) {
                    this.customer.owners.push(e);
                }
                setTimeout(() => {
                    this.owner = null;
                }, 100);
            }
        },
        getUser(e, key) {
            var user = this.users.find(x => x.id == e);

            if (user) {
                return this.users.find(x => x.id == e);
            }
            
            this.customer.owners.splice(key, 1);

            return { name: "" };
        },
        removeOwner(key) {
            this.customer.owners.splice(key, 1);
        },
        async getProducts() {
            await this.$http.get("/api/customers/products").then(x => {
                if (x.data) {
                    this.products = x.data.products;
                    this.groupedProducts = x.data.grouped;

                    this.customer.products = this.customer.products.filter(x => this.products.find(e => e.id == x.productId));

                    for (let index = 0; index < this.products.length; index++) {
                        const product = this.products[index];
                        
                        if (product.preSelected) {
                            if (product.column == 3 || this.newCustomer) {
                                this.selectProduct(true, product, true);
                            }
                        }
                    }
                    
                }
            });
        },
        selectProduct(e, item, insertOnly) {
            if (this.customer.products == null) {
                this.customer.products = [];
            }

            var val = item.value ? item.value : 0;
            var product = this.customer.products.find(x => x.productId == item.id);
            if (e === true) {                
                if (product == null) {
                    this.customer.products.push({
                        productId: item.id,
                        text: item.text,
                        value: val,
                        description: item.description,
                        hidden: false
                    });
                } else if(!insertOnly) {
                    product.productId = item.id;
                    product.text = item.text;
                    product.value = val;
                }
            } else {
                if (product != null || item.value == "") {
                    var index = this.customer.products.indexOf(product);
                    this.customer.products.splice(index, 1);
                }

                item.text = null;
                item.value = null;
            }

            this.$nextTick();
        },
        getProduct(item) {
            if (this.customer.products == null) {
                this.customer.products = [];
            }
            var product = this.customer.products.find(x => x.productId == item.id);
            if (product == null) {
                this.$set(item, "value", null);

                return {
                    productId: item.id,
                    text: null,
                    description: null,
                    value: null,
                    hidden: false
                };
            } else if (product.text) {
                this.$set(item, "text", product.text);
            }

            this.$set(item, "value", product.value);
            
            return product;
        },
        getRelatedProduct(item) {
            var product = this.products.find(x => item.productId == x.id);
            if (product == null) {
                return {};
            }
            return product;
        },
        hasProduct(item) {
            if (this.customer.products == null) {
                this.customer.products = [];
            }
            return this.customer.products.find(x => x.productId == item.id) != null;
        },
        uploadFile(e) {
            var formData = new FormData();
            formData.append("file", e);

            this.$http.post("/api/customers/upload/" + this.id, formData).then(x => {
                if (x.data === true) {
                    this.toast.showToast('Filen blev uploadet', { theme: "success" });

                    this.getFiles();

                    this.$refs.file.files = null;
                }
			});
        },
        getFiles() {
			this.$http.get("/api/customers/" + this.id + "/files").then(x => {
				this.files = x.data;
			});
        },
        deleteFile(file, key) {
            if (confirm("Er du sikker på at du vil slette denne fil?")) {
                this.$http.post("/api/customers/" + this.id + "/files/delete?file=" + file).then(x => {
                    this.files.splice(key, 1);
                });
            }
        },
        addContact() {
            if (this.customer.contacts == null) {
                this.customer.contacts = [];
            }
            this.customer.contacts.push({
                name: null,
                email: null,
                phone: null
            });
        },
        getYears() {
            var startYear = this.$moment(this.customer.dateStart).year();
            var endYear = this.$moment(this.customer.dateEnd).year();

            if (isNaN(endYear)) {
                endYear = startYear;
            }

            if (this.customer.sponsorshipYears == null) {
                this.customer.sponsorshipYears = [];
            }

            for (let i = startYear; i <= endYear; i++) {
                const year = this.customer.sponsorshipYears.find(x => x.year == i);
                if (year == null) {
                    this.customer.sponsorshipYears.push({
                        year: i,
                        value: 0
                    });
                }
            }

            this.customer.sponsorshipYears.forEach((x, i) => {
                if (x.year > endYear) {
                    this.customer.sponsorshipYears.splice(i, 1);
                }
            });
        },
        addInvoice() {
            this.$http.post("/api/customers/add-invoice/" + this.id, this.newInvoices).then(x => {
                if (x.data) {
                    if (!this.customer.invoices) {
                        this.customer.invoices = [];
                    }
                    this.customer.invoices = this.customer.invoices.concat(x.data);
                    this.newInvoices = [];
                }
            });
        },
        deleteInvoice(item) {
            if (confirm("Er du sikker på at du vil slette denne faktura?")) {
                this.customer.invoices.splice(item, 1);
            }
        },
        createInvoices() {
            this.newInvoices = [];
            this.partialInvoices = 1;
            this.newInvoices.push({ date: null, value: 0, invoiced: false, text: null });
            this.invoiceModal = true; 
        },
        updatePartialInvoices() {
            
            if (this.partialInvoices > this.newInvoices.length) {
                this.newInvoices.push({ date: null, value: 0, invoiced: false, text: null });
            } else {
                this.newInvoices.splice(this.partialInvoices, 1);
            }

            this.newInvoices.forEach((x, i) => {
                x.text = 'Faktura ' + (i+1) + ' ud af ' + this.partialInvoices;
            });
        },
        sponsorDeal() {
            this.sponsorModal = true;
        },
        sendToPrintModal() {
            if (this.settings.printProducts == null) {
                this.settings.printProducts = "";
            }
            var printProducts = this.settings.printProducts.split(",");
            var emailProducts = this.customer.products.filter(x => printProducts.includes(x.productId));
            var body = "Virksomhed: <br />";
            body += this.customer.name + "<br />";
            if (this.customer.address) {
                body += this.customer.address + "<br />";
            }
            if (this.customer.city) {
                if (this.customer.zipCode) {
                    body += this.customer.zipCode + " " + this.customer.city + "<br />";
                } else {
                    body += this.customer.city + "<br />";
                }
            }
            if (this.customer.vatNumber) {
                body += "CVR: " + this.customer.vatNumber + "<br />";
            }
            if (this.customer.email) {
                body += this.customer.email + "<br />";
            }
            if (this.customer.phone) {
                body += "Tlf: " + this.customer.phone + "<br /><br />";
            }

            if (this.customer.type) {
                body += "Sponsorkategori: <br />";
                body += this.customer.type + "<br /><br />";
            }

            if (this.customer.contacts.length > 0) {
                body += "Kontaktpersoner: <br />";
                this.customer.contacts.forEach(x => {
                    if (x.name) {
                        body += "Navn: " + x.name + "<br />";
                    }
                    if (x.email) {
                        body += "E-mail: " + x.email + "<br />";
                    }
                    if (x.phone) {
                        body += "Tlf: " + x.phone + "<br />";
                    }
                    if (x.mobile) {
                        body += "Mobil: " + x.mobile + "<br />";
                    }
                    body += "<br />";
                });
                body += "<br />";
            }

            if (emailProducts.length > 0) {
                body += "Produkter: <br />";
                emailProducts.forEach(x => {
                    var product = this.products.find(e => e.id == x.productId);
                    if (product != null) {
                        body += "Navn: " + product.name + "<br />";
                        body += "Beskrivelse: " + x.description + (x.text == null ? "" :  ": " + x.text) + "<br />";
                        if (x.value > 0) {
                            body += "Antal: " + x.value + "<br /><br />";
                        }
                    }
                });
            }

                 
            body += "<br /><br />";

            this.email.to = this.settings.painterEmail;
            this.email.message = body;
            this.email.printModal = true;
        },
        sendToCustomerModal() {
            if (this.customer.contacts != null && this.customer.contacts[0] != null) {
                this.email.to = this.customer.contacts[0].email;
            } else {
                this.email.to = this.customer.email;
            }

            var name = this.customer.name;
            if (this.customer.contacts[0]) {
                name = this.customer.contacts[0].name;
            }

            this.email.message = "Hej " + name + "<br /><br />";
            //     "Herved tilsendes du et tilbud på samarbejde med " + this.settings.name + ".<br />" +
            //     "Tilbuddet er vedhæftet, og kan godkendes ved at besvare denne mail med: " + 
            //     "“Tilsendte tilbud på aftale med " + this.settings.name + " er godkendt” eller ved at underskrive " + 
            //     "den vedhæftede PDF og sende den til " + this.settings.email + ".<br /><br />";

            this.email.modal = true;
        },
        sendToCustomer() {
            if (confirm("Er du sikker på at du vil sende aftalen til " + this.email.to + "? \nHUSK at gemme dine ændringer først!")) {
                this.$http.post("/api/customers/send-contract/" + this.id, this.email).then(x => {
                    if (x.data) {
                        this.email.message = null;
                        this.email.copyTo = null;
                        this.email.modal = false;
                        this.toast.showToast('Aftalen blev sendt!', { theme: "success" });
                    }
                });
            }
        },
        sendToPrint() {
            if (confirm("Er du sikker på at du vil sende skilte information til " + this.settings.painterEmail + "? \nHUSK at gemme dine ændringer først!")) {
                this.$http.post("/api/customers/send-print/" + this.id, this.email).then(x => {
                    if (x.data) {
                        this.email.message = null;
                        this.email.copyTo = null;
                        this.email.printModal = false;
                        this.toast.showToast('Aftalen blev sendt!', { theme: "success" });
                    }
                });
            }
        },

        sendToSigningModal() {
            if (this.customer.contacts != null && this.customer.contacts[0] != null) {
                this.email.to = this.customer.contacts[0].email;
            } else {
                this.email.to = this.customer.email;
            }

            var name = this.customer.name;
            if (this.customer.contacts[0]) {
                name = this.customer.contacts[0].name;
            }

            this.email.message = "Hej " + name + "<br /><br />";

        },
        sendToSigning() {
            if (confirm("Er du sikker på at du vil sende aftalen til underskrift? \nHUSK at gemme dine ændringer først!")) {
                this.$http.post("/api/customers/send-signing/" + this.id, this.email).then(x => {
                    if (x.data) {
                        this.email.message = null;
                        this.email.copyTo = null;
                        this.email.modal = false;
                        this.toast.showToast('Aftalen blev sendt til underskrift!', { theme: "success" });
                    }
                });
            }
        },

        endDateChange(evt) {
            this.customer.dateEnd = this.$moment(evt).utc().format();

            var interval = this.$store.state.renegotiationInterval
            this.customer.renegotiationDate = this.$moment(evt).subtract(interval, "months").utc().format();
        },
        sendInvoice(id) {
            this.$http.post("/api/customers/send-invoice/" + this.id, null, {
                params: {
                    invoiceId: id
                }
            }).then(x => {
                this.customer.invoices[id].invoiced = true;
            });
        },

        getInvoiceProducts() {
            this.$http.get("/api/customers/invoice-products").then(x => {
                this.invoiceProducts = x.data;
            });
        },
	},
    async created() {
        this.getSettings();
        this.getUsers();
        this.getInvoiceProducts();

        if (!this.newCustomer) {
            await this.$http.get("/api/customers/" + this.id).then(x => {
                if (x.data) {
                    this.customer = x.data;
                    
                    this.getFiles();

                    setTimeout(() => {
                        this.changes = false;
                    }, 200);
                }
            });
        }

        await this.getProducts();
    },
    mounted() {
        this.toast = this.$refs.toast;
        this.toast.setOptions({ position: "bottom right" });
    },
    beforeRouteLeave(to, from, next) {
        if (this.changes) {
            const answer = window.confirm('Du har ændringer som ikke er gemt! Er du sikker på, at du vil forlade denne side?');
            if (answer) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    }
};
</script>
