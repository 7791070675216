<template>
    <div ref="signer" class="sign-container">
        <button type="button" class="btn btn-danger btn-sm sign-clear" @click="clearCanvas">
            <fa icon="trash"></fa>
        </button>
        <canvas ref="canvas" id="sig-canvas" width="0" height="0">
            Din browser er ikke understøttet.
        </canvas>

        <canvas ref="result" width="0" height="0" class="d-none"></canvas>
    </div>
</template>

<script>
    export default {
        data: () => ({
            canvas: null,
            ctx: null,

        }),
        props: {
            value: null,
        },
        computed: {
            signature: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            }
        },
        methods: {
            init() {
                var container = this.$refs.signer;

                this.canvas = this.$refs.canvas;
                this.ctx = this.canvas.getContext("2d");

                this.ctx.canvas.width = container.offsetWidth - 4;
                this.ctx.canvas.height = 100;

                this.ctx.strokeStyle = "#222222";
                this.ctx.lineWidth = 2;

                if (this.signature) {
                    var image = new Image()
                    image.onload = () => {
                        this.ctx.drawImage(image, 0, 0)
                    };
                    image.src = this.signature
                }

                var drawing = false;
                var mousePos = {
                    x: 0,
                    y: 0
                };
                var lastPos = mousePos;

                this.canvas.addEventListener("mousedown", (e) => {
                    drawing = true;
                    lastPos = this.getMousePos(this.canvas, e);
                }, false);

                this.canvas.addEventListener("mouseup", (e) => {
                    drawing = false;

                    this.signature = this.cropImageFromCanvas(this.ctx)
                }, false);

                this.canvas.addEventListener("mousemove", (e) => {
                    mousePos = this.getMousePos(this.canvas, e);
                }, false);

                // Add touch event support for mobile
                this.canvas.addEventListener("touchstart", (e) => {

                }, false);

                this.canvas.addEventListener("touchmove", (e) => {
                    var touch = e.touches[0];
                    var me = new MouseEvent("mousemove", {
                        clientX: touch.clientX,
                        clientY: touch.clientY
                    });
                    this.canvas.dispatchEvent(me);
                }, false);

                this.canvas.addEventListener("touchstart", (e) => {
                    mousePos = this.getTouchPos(this.canvas, e);
                    var touch = e.touches[0];
                    var me = new MouseEvent("mousedown", {
                        clientX: touch.clientX,
                        clientY: touch.clientY
                    });
                    this.canvas.dispatchEvent(me);
                }, false);

                this.canvas.addEventListener("touchend", (e) => {
                    var me = new MouseEvent("mouseup", {});
                    this.canvas.dispatchEvent(me);
                }, false);


                var renderCanvas = () => {
                    if (drawing) {
                        this.ctx.moveTo(lastPos.x, lastPos.y);
                        this.ctx.lineTo(mousePos.x, mousePos.y);
                        this.ctx.stroke();
                        lastPos = mousePos;
                    }
                }

                // Prevent scrolling when touching the canvas
                document.body.addEventListener("touchstart", (e) => {
                    if (e.target == this.canvas) {
                        e.preventDefault()
                    }
                }, false);
                document.body.addEventListener("touchend", (e) => {
                    if (e.target == this.canvas) {
                        e.preventDefault()
                    }
                }, false);
                document.body.addEventListener("touchmove", (e) => {
                    if (e.target == this.canvas) {
                        e.preventDefault()
                    }
                }, false);

                var drawLoop = () => {
                    requestAnimFrame(drawLoop)
                    renderCanvas()
                }
                drawLoop()
            },

            clearCanvas() {
                this.canvas.width = this.canvas.width

                this.ctx.strokeStyle = "#222222"
                this.ctx.lineWidth = 2

                this.signature = null
            },
            getMousePos(canvasDom, mouseEvent) {
                var rect = canvasDom.getBoundingClientRect();
                //console.log(mouseEvent.clientX, rect.left, mouseEvent.clientY, rect.top)
                return {
                    x: mouseEvent.clientX - rect.left,
                    y: mouseEvent.clientY - rect.top
                }
            },
            getTouchPos(canvasDom, touchEvent) {
                var rect = canvasDom.getBoundingClientRect();

                return {
                    x: touchEvent.touches[0].clientX - rect.left,
                    y: touchEvent.touches[0].clientY - rect.top
                }
            },
            cropImageFromCanvas(ctx) {
                var canvas = ctx.canvas,
                    w = canvas.width, h = canvas.height,
                    pix = { x: [], y: [] },
                    imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
                    x, y, index;

                for (y = 0; y < h; y++) {
                    for (x = 0; x < w; x++) {
                        index = (y * w + x) * 4;
                        if (imageData.data[index + 3] > 0) {
                            pix.x.push(x);
                            pix.y.push(y);
                        }
                    }
                }
                pix.x.sort(function (a, b) { return a - b });
                pix.y.sort(function (a, b) { return a - b });
                var n = pix.x.length - 1;

                w = 1 + pix.x[n] - pix.x[0];
                h = 1 + pix.y[n] - pix.y[0];
                var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

                var result = this.$refs.result;
                var resultCtx = result.getContext("2d");

                result.width = w;
                result.height = h;
                resultCtx.putImageData(cut, 0, 0);

                var image = result.toDataURL()

                return image
            }
        },
        created() {

        },
        mounted() {
            window.requestAnimFrame = (function (callback) {
                return window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame ||
                    window.oRequestAnimationFrame ||
                    window.msRequestAnimaitonFrame ||
                    function (callback) {
                        window.setTimeout(callback, 1000 / 60);
                    };
            })();

            setTimeout(() => {
                this.init()
            }, 30)
        }
    };
</script>


<style lang="scss" scoped>
    .sign-container {
        position: relative;
    }

    .sign-clear {
        position: absolute;
        top: 0;
        right: 0;
    }

    #sig-canvas {
        border: 2px dotted #CCCCCC;
        border-radius: 15px;
        cursor: crosshair;
    }
</style>
