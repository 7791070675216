import App from "./components/app.vue";

import Login from "./components/login/login.vue";
import Register from "./components/login/register.vue";
import ForgotPassword from "./components/login/forgot-password.vue";
import ResetPassword from "./components/login/reset-password.vue";

import Dashboard from "./components/dashboard/dashboard.vue";
import CreateClub from "./components/dashboard/create-club.vue";

import Users from "./components/users/users.vue";
import Invite from "./components/users/invite.vue";

import Customers from "./components/customers/customers.vue";
import Customer from "./components/customers/customer.vue";
import Esign from "./components/customers/esign.vue";

import Leads from "./components/leads/leads.vue";
import Lead from "./components/leads/lead.vue";

import Products from "./components/products/products.vue";
import UpdateProduct from "./components/products/product.vue";

import Events from "./components/events/events.vue";
import Event from "./components/events/event.vue";

import Tickets from "./components/tickets/events.vue";
import Ticket from "./components/tickets/event.vue";

import Offers from "./components/offers/offers.vue";
import Offer from "./components/offers/offer.vue";

import Tilbud from "./components/offers/tilbud.vue";


import Contracts from "./components/contracts/contracts.vue";
import Contract from "./components/contracts/contract.vue";
import CustomerContract from "./components/contracts/customerContract.vue";

import Settings from "./components/settings/settings.vue";
import Templates from "./components/settings/templates.vue";
import Template from "./components/settings/template.vue";

import Invitation from "./components/invite.vue";

export const routes = [
    {
        path: "/",
        component: App,
        meta: { area: 'home' },
        children: [
            { path: "", component: Dashboard, name: "home" },
            { path: "/create-club", component: CreateClub, name: "create-club" },
            { path: "/profile", component: Dashboard, name: "profile" }
        ]
    },
    {
        path: "/users",
        component: App,
        meta: { area: 'users' },
        children: [
            { path: "", component: Users, name: "users" },
            { path: "invite", component: Invite, name: "user-invite" }
        ]
    },
    {
        path: "/products",
        component: App,
        meta: { area: 'products' },
        children: [
            { path: "", component: Products, name: "products" },
            { path: ":id", component: UpdateProduct, name: "update-product" }
        ]
    },
    {
        path: "/customers",
        component: App,
        meta: { area: 'customers' },
        children: [
            { path: "", component: Customers, name: "customers" },
            { path: ":id", component: Customer, name: "customer" },
        ]
    },
    {
        path: "/esign/:id",
        component: Esign,
        name: "esign",
        meta: { area: 'sign', isPublic: true }
    },

    {
        path: "/events",
        component: App,
        meta: { area: 'events' },
        children: [
            { path: "", component: Events, name: "events" },
            { path: ":id", component: Event, name: "event" }
        ]
    },
    {
        path: "/tickets",
        component: App,
        meta: { area: 'tickets' },
        children: [
            { path: "", component: Tickets, name: "tickets" },
            { path: ":id", component: Ticket, name: "ticket" }
        ]
    },
    {
        path: "/campaigns",
        component: App,
        meta: { area: 'campaigns' },
        children: [
            { path: "", component: Offers, name: "campaigns" },
            { path: ":id", component: Offer, name: "campaign" }
        ]
    },
    {
        path: "/leads",
        component: App,
        meta: { area: 'leads' },
        children: [
            { path: "", component: Leads, name: "leads" },
            { path: ":id", component: Lead, name: "lead" }
        ]
    },
    {
        path: "/contracts",
        component: App,
        meta: { area: 'contracts' },
        children: [
            { path: "", component: Contracts, name: "contracts" },
            { path: ":id", component: Contract, name: "contract" }
        ]
    },
    {
        path: "/contract/:id",
        component: CustomerContract,
        meta: { area: 'customer-contract' }
    },
    {
        path: "/settings",
        component: App,
        meta: { area: 'settings' },
        children: [
            { path: "", component: Settings, name: "settings" },
            { path: "templates", component: Templates, name: "templates" },
            { path: "templates/:id", component: Templates, name: "template" }
        ]
    },
    {
        path: "/admin",
        component: App,
        meta: { area: 'admin' },
        children: [
            { path: "", component: Dashboard, name: "admin" },
            { path: "clubs", component: Dashboard, name: "admin-clubs" },
            { path: "users", component: Dashboard, name: "admin-users" }
        ]
    },

    { path: "/login", component: Login, name: "login", meta: { isPublic: true } },
    { path: "/register", component: Register, name: "register", meta: { isPublic: true } },
    { path: "/forgot-password", component: ForgotPassword, name: "forgot-password", meta: { isPublic: true } },
    { path: "/reset-password", component: ResetPassword, name: "reset-password", meta: { isPublic: true } },
    { path: "/invite", component: Invitation, name: "invite", meta: { isPublic: true } },
    { path: "/kampagne", component: Tilbud, name: "kampagne", meta: { isPublic: true } },
    //{ path: "/", redirect: "/customers" },
    { path: "*", redirect: "/login" }
];
