<template>
    <div class="row">
        <div class="col-12 pt-3" v-if="!template">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <b-card title="Blank aftale" class="mb-2">
                        <p></p>
                        <b-button @click="selectTemplate()" variant="primary" block>Vælg</b-button>
                    </b-card>
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(item, key) in templates" :key="key">
                    <b-card :title="item.title" class="mb-2">
                        <p v-html="item.description"></p>
                        <b-button @click="selectTemplate(item)" variant="primary" block>Vælg</b-button>
                    </b-card>
                </div>
            </div>
        </div>

        <div class="col-12 pt-3 contract-container" v-if="template">
            <div class="row">
                <div class="col-12 col-md-3 col-lg-3 col-xl-2 order-md-last">
                    <template v-if="contract.status == 'Draft' || newContract">
                        <b-button @click="saveContract" variant="warning" block>
                            <fa icon="save" class="mr-2" /> Gem kladde</b-button>

                        <b-dropdown v-if="contract.status == 'Draft'" variant="success" class="btn-block" toggle-class="btn-block">
                            <template slot="button-content">
                                <fa icon="check" class="mr-2" /> Godkend aftale
                            </template>
                            <b-dropdown-item @click="approveContract">
                                <fa icon="thumbs-up" class="mr-2" /> Godkend aftale manuelt</b-dropdown-item>
                            <b-dropdown-item v-b-modal="'request-approval-modal'">
                                <fa icon="file-signature" class="mr-2" /> Godkend aftale via e-mail</b-dropdown-item>
                        </b-dropdown>
                    </template>

                    <template v-if="contract.status == 'Accepted' || contract.status == 'CustomerAccepted'">
                        <template v-if="contract.paymentStatus != 'Paid' && contract.paymentStatus != 'Credited'">
                            <b-button variant="success" block v-b-modal="'payment-modal'">
                                <fa icon="money-bill-alt" class="mr-2" /> Registrer betaling</b-button>
                        </template>

                        <b-button variant="primary" block @click="renewContract">
                            <fa icon="plus" class="mr-2" /> Forlæng aftale</b-button>
                    </template>

                    <template v-if="(contract.status != 'Draft' || contract.status == 'Pending') && !newContract">
                        <b-dropdown variant="secondary" class="btn-block" toggle-class="btn-block">
                            <template slot="button-content">
                                <fa icon="file-invoice-dollar" class="mr-2" /> Send faktura
                            </template>
                            <b-dropdown-item v-b-modal="'send-invoice-modal'">
                                <fa icon="envelope" class="mr-2" /> Send faktura som e-mail</b-dropdown-item>
                            <b-dropdown-item :href="'/invoice/pdf/' + id" target="_blank">
                                <fa icon="file-invoice-dollar" class="mr-2" /> Download faktura</b-dropdown-item>
                            <b-dropdown-item @click="markAsSend">
                                <fa icon="share-square" class="mr-2" /> Markér som sendt</b-dropdown-item>
                        </b-dropdown>
                    </template>

                    <template v-if="contract.status != 'Cancelled' && contract.status != 'Draft' && !newContract">
                        <b-button variant="secondary" block :href="'/contracts/pdf/' + id" target="_blank">
                            <fa icon="file-invoice" class="mr-2" /> Download aftale</b-button>
                    </template>

                    <template v-if="contract.status != 'Draft' && contract.status != 'Cancelled'">
                        <b-button variant="danger" block @click="cancelContract">
                            <fa icon="ban" class="mr-2" /> Annuller aftale</b-button>
                    </template>
                    <template v-else>
                        <b-button variant="danger" block @click="deleteContract">
                            <fa icon="trash" class="mr-2" /> Slet aftale</b-button>
                    </template>

                    <template v-if="!newContract">
                        <b-form-file ref="file" class="mb-2 mt-4" placeholder="Upload filer..." @input="uploadFile"></b-form-file>
                        <b-card no-body header="<b>Filer</b>">
                            <b-list-group flush>
                                <b-list-group-item v-for="(item, key) in files" :key="key" :href="item.path + item.file" target="_blank">
                                    <fa icon="file" class="mr-2"></fa> {{item.file}}
                                    <b-badge @click.prevent="deleteFile(item.file, key)" class="ml-2" variant="danger" pill><fa icon="trash"></fa></b-badge>
                                </b-list-group-item>
                            </b-list-group>
                        </b-card>
                    </template>


                    <template v-if="!newContract">
                        <b-card no-body header="<b>Betalinger</b>" class="mb-2 mt-4">
                            <b-list-group flush>
                                <b-list-group-item v-for="(item, key) in contract.payments" :key="key">
                                    <fa icon="money-bill-alt" class="mr-2"></fa> {{item.description}} ({{$numeral(item.amount).format('0,0.##')}})
                                    <b-badge @click.prevent="deletePayment(item, key)" class="ml-2" variant="danger" pill><fa icon="trash"></fa></b-badge>
                                </b-list-group-item>
                            </b-list-group>
                        </b-card>

                        <b-card no-body class="internal-comment mb-2 mt-4"  header="<b>Intern kommentar</b>">
                            <editor v-model="contract.internalComment"></editor>

                            <b-button @click="saveComment" variant="success" class="m-2"><fa icon="save"></fa> Gem kommentar</b-button>
                        </b-card>
                    </template>
                </div>
                <div class="col-12 col-md-9 col-lg-9 col-xl-7 offset-xl-1 order-md-first py-2 text-center" v-if="status">
                    <b-badge :variant="status.class" pill class="py-2 px-3" style="font-size: 14px;">{{status.status}}</b-badge>
                    <b-badge :variant="paymentStatus.class" pill class="py-2 px-3" style="font-size: 14px;">{{paymentStatus.status}}</b-badge>
                </div>
                <div class="col-12 col-md-9 col-lg-9 col-xl-7 offset-xl-1 order-md-first" v-if="!contract.status || contract.status == 'Draft'">
                    <div class="contract-page">
                        <div class="row">
                            <div class="col-12 col-md-6">

                                <b-form-group label="Kunde:">
                                    <div class="row">
                                        <div class="col-10">
                                            <v-select :value="contract.customer ? { name: contract.customer, id: contract.customerId, address: contract.customerAddress, paymentTerms: contract.paymentDays } : null" :options="customers" :filterable="false" @search="searchCustomers" @input="selectCustomer" label="name">
                                                <div slot="no-options">
                                                    <b-button to="/customers/new" variant="link" block size="sm">
                                                        <fa icon="plus" class="mr-2" /> Opret ny kunde</b-button>
                                                </div>
                                            </v-select>
                                        </div>
                                        <div class="col-2" v-if="contract.customerId">
                                            <router-link :to="'/customers/' + contract.customerId">
                                                <fa icon="external-link-alt"></fa>
                                            </router-link>
                                        </div>
                                    </div>
                                </b-form-group>

                                <editor v-model="contract.customerAddress"></editor>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="row">
                                    <div class="col-12 col-md-10 col-lg-8 offset-md-2 offset-lg-4">
                                        <b-form-group label="Dato:">
                                            <v-date-picker mode="single" popover-visibility="focus" :value="$moment(contract.date).toDate()" @input="contract.date = $moment($event).utc().format()" title-transition="none" weeks-transition="none" :input-props="{ class: 'form-control', readOnly: true }">
                                            </v-date-picker>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-5 offset-lg-2">
                                        <b-form-group label="Løbetid fra:">
                                            <v-date-picker mode="single" popover-visibility="focus" :value="$moment(contract.durationFrom).toDate()" @input="contract.durationFrom = $moment($event).utc().format()" title-transition="none" weeks-transition="none" :input-props="{ class: 'form-control', readOnly: true }">
                                            </v-date-picker>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-5">
                                        <b-form-group label="Løbetid til:">
                                            <v-date-picker mode="single" popover-visibility="focus" :value="$moment(contract.durationTo).toDate()" @input="contract.durationTo = $moment($event).utc().format()" title-transition="none" weeks-transition="none" :input-props="{ class: 'form-control', readOnly: true }">
                                            </v-date-picker>
                                        </b-form-group>
                                    </div>

                                    <div class="col-12 col-md-6 col-lg-5 offset-lg-2">
                                        <b-form-group label="Betalingsdage:">
                                            <b-input-group style="" append="dage">
                                                <b-form-input type="number" v-model="contract.paymentDays"></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-5">
                                        <b-form-group label="Forfaldsdato:">
                                            <b-form-input disabled type="text" :value.sync="$moment(contract.dueDate).format('DD-MM-YYYY')"></b-form-input>
                                        </b-form-group>
                                    </div>



                                    <div class="col-12 col-md-6 col-lg-5 offset-lg-2">
                                        <b-form-group label="Rate:">
                                            <b-input-group style="" append="Månedere">
                                                <b-form-input type="number" v-model="contract.ratePayment"></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-3">
                                <b-form-group label="Kommentarer:">
                                    <editor v-model="contract.comment"></editor>
                                </b-form-group>
                            </div>
                            <div class="col-12 pb-3">
                                <b-form-group label="Overskrift:">
                                    <b-form-input type="text" v-model="contract.title"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <table class="table table-sm table-striped table-bordered">
                                    <tr>
                                        <th style="width: 45%">Produkt</th>
                                        <th>Antal</th>
                                        <th>Stk. pris</th>
                                        <th>Stk. rabat</th>
                                        <th class="text-right" style="width: 12%">Pris</th>
                                        <th></th>
                                    </tr>
                                    <tr v-for="(item, key) in contract.items" :key="key">
                                        <td>
                                            <p class="m-0">
                                                {{item.name}}
                                            </p>
                                            <div class="product-description">
                                                <b-form-textarea v-model="item.description" rows="2" size="sm" v-if="item.hide || item.description" placeholder="Beskrivelse ..."></b-form-textarea>
                                                <button class="btn btn-link" @click="$set(contract.items[key], 'hide', !item.hide); item.description = null" size="sm">
                                                    <span v-if="!item.hide && !item.description">Tilføj beskrivelse</span>
                                                    <span v-if="item.hide || item.description">Fjern</span>
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <b-form-input type="number" v-model="item.quantity"></b-form-input>
                                        </td>
                                        <td>
                                            <b-form-input type="number" v-model="item.price"></b-form-input>
                                        </td>
                                        <td>
                                            <b-form-input type="number" v-model="item.discount"></b-form-input>
                                        </td>
                                        <td class="align-middle text-right">{{item.quantity * (item.price - item.discount)}}</td>
                                        <td class="align-middle text-right">
                                            <b-button @click="removeItem(key)" variant="danger" size="sm">
                                                <fa icon="times" />
                                            </b-button>
                                        </td>
                                    </tr>
                                </table>
                                <b-button v-b-modal="'add-product-modal'" variant="primary" size="sm">
                                    <fa icon="plus" /> Tilføj produkter
                                </b-button>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 offset-md-8" v-if="totals">
                                <table class="table table-sm mt-3">
                                    <tr>
                                        <td>Subtotal</td>
                                        <td class="text-right">{{$numeral(totals.subtotal).format('$ 0,0.00')}}</td>
                                    </tr>
                                    <tr>
                                        <td>Moms</td>
                                        <td class="text-right">{{$numeral(totals.vatAmount).format('$ 0,0.00')}}</td>
                                    </tr>
                                    <tr>
                                        <th>Total</th>
                                        <th class="text-right">{{$numeral(totals.total).format('$ 0,0.00')}}</th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-9 col-lg-9 col-xl-7 offset-xl-1 order-md-first" v-if="contract.status && contract.status != 'Draft'">
                    <div class="contract-page">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <strong>
                                    {{contract.customer}}
                                    <router-link :to="'/customers/' + contract.customerId">
                                        <fa icon="external-link-alt"></fa>
                                    </router-link>
                                </strong>
                                <p v-html="contract.customerAddress"></p>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="row">
                                    <div class="col-12 col-md-8 offset-md-4 text-right">
                                        <p>Dato: <strong>{{$moment(contract.date).format('DD-MM-YYYY')}}</strong></p>
                                        <b-form-group label="Fakturanummer:" horizontal :label-cols="6" label-class="text-right">
                                            <b-input-group style="" class="float-right">
                                                <b-input-group-prepend>
                                                    <b-btn variant="dark" v-b-modal="'invoice-modal'">
                                                        <fa icon="pencil-alt"></fa>
                                                    </b-btn>
                                                </b-input-group-prepend>
                                                <b-form-input class="text-right" disabled v-model="contract.invoiceNumber"></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                        <b-form-group label="Betalingsdage:" horizontal :label-cols="6" label-class="text-right">
                                            <b-input-group style="" class="float-right">
                                                <b-input-group-prepend>
                                                    <b-btn variant="dark" v-b-modal="'invoice-modal'">
                                                        <fa icon="pencil-alt"></fa>
                                                    </b-btn>
                                                </b-input-group-prepend>
                                                <b-form-input class="text-right" disabled v-model="contract.paymentDays"></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                        <p>Forfaldsdato: <strong>{{$moment(contract.dueDate).format('DD-MM-YYYY')}}</strong></p>
                                        <p>
                                            Løbetid:
                                            <strong v-if="contract.durationFrom">{{$moment(contract.durationFrom).format('DD-MM-YYYY')}}</strong>
                                            til
                                            <strong v-if="contract.durationTo">{{$moment(contract.durationTo).format('DD-MM-YYYY')}}</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-3">
                                <p v-html="contract.comment"></p>
                            </div>
                            <div class="col-12 pb-3">
                                <h3>{{contract.title}}</h3>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <table class="table table-sm table-striped ">
                                    <tr>
                                        <th style="width: 45%">Produkt</th>
                                        <th>Antal</th>
                                        <th class="text-right">Stk. pris</th>
                                        <th class="text-right">Stk. rabat</th>
                                        <th class="text-right">Pris</th>
                                    </tr>
                                    <tr v-for="(item, key) in contract.items" :key="key">
                                        <td>
                                            {{item.name}}
                                            <small><br />{{item.description}}</small>
                                        </td>
                                        <td>
                                            {{item.quantity}}
                                        </td>
                                        <td class="align-middle text-right">
                                            {{$numeral(item.price).format('$ 0,0.00')}}
                                        </td>
                                        <td class="align-middle text-right">
                                            {{$numeral(item.discount).format('$ 0,0.00')}}
                                        </td>
                                        <td class="align-middle text-right">
                                            <strong>{{$numeral(item.quantity * (item.price - item.discount)).format('$ 0,0.00')}}</strong>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 offset-md-8">
                                <table class="table table-sm mt-3">
                                    <tr>
                                        <td>Subtotal</td>
                                        <td class="text-right">{{$numeral(contract.subtotal).format('$ 0,0.00')}}</td>
                                    </tr>
                                    <tr>
                                        <td>Moms</td>
                                        <td class="text-right">{{$numeral(contract.vatAmount).format('$ 0,0.00')}}</td>
                                    </tr>
                                    <tr>
                                        <th>Total</th>
                                        <th class="text-right">{{$numeral(contract.total).format('$ 0,0.00')}}</th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <b-modal id="payment-modal" centered title="Registrer betaling" no-close-on-backdrop
            @shown="payment = { date: null, description: 'Betaling af Faktura ' + contract.invoiceNumber, amount: contract.total }"
            @ok="registerPayment" ok-title="Gem betaling" cancel-title="Fortryd">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <b-form-group label="Beløb:">
                        <b-form-input type="number" v-model="payment.amount"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6">
                    <b-form-group label="Dato:">
                        <v-date-picker mode="single" popover-visibility="focus"
                            :value="$moment(payment.date).toDate()"
                            @input="payment.date = $moment($event).utc().format()"
                            title-transition="none" weeks-transition="none"
                            :input-props="{ class: 'form-control', readOnly: true }">
                        </v-date-picker>
                    </b-form-group>
                </div>
                <div class="col-12">
                    <b-form-group label="Beskrivelse:">
                        <b-form-textarea rows="2" v-model="payment.description"></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
        </b-modal>

        <b-modal id="invoice-modal" centered title="Rediger fakturanummer" no-close-on-backdrop
            @ok="updateInvoiceNumber" ok-title="Gem fakturanummer" cancel-title="Fortryd"
            @cancel="getContract">
            <div class="row">
                <div class="col-12">
                    <b-form-group label="Fakturanummer:">
                        <b-form-input type="number" v-model="contract.invoiceNumber"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12">
                </div>

                <div class="col-12 col-md-6">
                    <b-form-group label="Betalingsdage:">
                        <b-input-group style="" append="dage">
                            <b-form-input type="number" v-model="contract.paymentDays"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <b-form-group label="Forfaldsdato:">
                        <b-form-input disabled type="text" :value.sync="$moment(contract.dueDate).format('DD-MM-YYYY')"></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </b-modal>


        <b-modal id="send-invoice-modal" centered title="Send faktura" no-close-on-backdrop
            @ok="sendInvoice" ok-title="Send faktura" cancel-title="Fortryd"
            @cancel="getContract">
            <div class="row">
                <div class="col-12">
                    <b-form-group label="E-mail:">
                        <b-form-input type="text" v-model="contract.invoiceEmail"></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </b-modal>


        <b-modal id="request-approval-modal" centered title="Anmod om godkendelse" no-close-on-backdrop
            @ok="requestApproval" ok-title="Send anmodning" cancel-title="Fortryd"
            @cancel="getContract">
            <div class="row">
                <div class="col-12">
                    <b-form-group label="E-mail:">
                        <b-form-input type="text" v-model="contract.invoiceEmail"></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </b-modal>


        <b-modal id="add-product-modal" centered title="Tilføj produkter" no-close-on-backdrop
            @ok="addProducts" ok-title="Tilføj" cancel-title="Fortryd" size="lg"
            @cancel="items = []">
            <div class="row">
                <div class="col-6 col-md-5 categories">
                    <b-btn v-for="(item, key) in products" :key="key" v-b-toggle="'product-' + key" variant="secondary" block size="sm">
                        {{item.group || 'Ingen kategori'}}
                    </b-btn>
                </div>
                <div class="col-6 col-md-7">
                    <b-collapse :id="'product-' + key" v-for="(item, key) in products" :key="key" accordion="product-groups">
                        <b-form-checkbox v-for="product in item.products" :key="product.id"
                                        v-model="items" :value="product">
                            {{product.name}}
                        </b-form-checkbox>
                    </b-collapse>
                </div>
            </div>
        </b-modal>

        <v-toast ref="toast"></v-toast>
    </div>
</template>


<script>
export default {
	data: () => ({
		contract: {
			title: null,
			comment: null,
			items: [],
			customerAddress: null,
			customer: null,
            customerId: null,
            date: null,
            dueDate: null,
            durationFrom: null,
            durationTo: null,
            paymentDays: 8
		},
		templates: [],
		template: false,
        customers: [],
        files: [],
        settings: null,
        payment: { date: null },
        products: [],
        items: []
	}),
	components: {},
	computed: {
        id() {
            return this.$route.params.id;
        },
        newContract() {
            return this.$route.params.id == '0';
        },
        totals() {
            if (this.settings) {
                var subtotal = 0;
                for (let index = 0; index < this.contract.items.length; index++) {
                    const element = this.contract.items[index];
                    subtotal += (element.price - element.discount) * element.quantity;
                }
                var vatRate = (this.settings.vatRate + 100) / 100;
                var vatAmount = (subtotal * vatRate) - subtotal;
                var total = subtotal + vatAmount;

                return {
                    subtotal: subtotal,
                    vatAmount: vatAmount,
                    total: total
                };
            }

            return null;
        },
        status() {
            if (this.contract.status) {
                if (this.contract.status == "Accepted") {
                    return { status: "Godkendt", class: "success" };
                }
                if (this.contract.status == "CustomerAccepted") {
                    return { status: "Godkendt af kunden", class: "success" };
                }
                if (this.contract.status == "Draft") {
                    return { status: "Kladde", class: "warning" };
                }
                if (this.contract.status == "Pending") {
                    return { status: "Afventer godkendelse", class: "warning" };
                }
                if (this.contract.status == "Cancelled") {
                    return { status: "Opsagt", class: "danger" };
                }
            }

            return null;
        },
        paymentStatus() {
            if (this.contract.paymentStatus) {
                if (this.contract.paymentStatus == "Unsent") {
                    return { status: "Faktura ikke sendt", class: "warning" };
                }
                if (this.contract.paymentStatus == "Billed") {
                    return { status: "Faktureret", class: "warning" };
                }
                if (this.contract.paymentStatus == "Paid") {
                    return { status: "Betalt", class: "success" };
                }
                if (this.contract.paymentStatus == "Credited") {
                    return { status: "Krediteret", class: "danger" };
                }
            }

            return null;
        }
    },
    watch: {
        "contract.paymentDays"() {
            this.contract.dueDate = this.$moment().add(this.contract.paymentDays, 'd').utc().format();
        }
    },
	methods: {
		getTemplates() {
			this.$http.get("/api/templates").then(x => {
				this.templates = x.data.items;
            });
        },

        ratePayment(){

        },

        getSettings() {
			this.$http.get("/api/contract/settings").then(x => {
				this.settings = x.data;
			});
        },
        getProducts() {
			this.$http.get("/api/contracts/products").then(x => {
				this.products = x.data;
			});
        },
		selectTemplate(item) {
			this.contract = {
				title: null,
				comment: null,
				items: [],
				customerAddress: null,
				customer: null,
                customerId: null,
                date: null,
                dueDate: null,
                durationFrom: null,
                durationTo: null,
                paymentDays: 8
            };

            this.contract.dueDate = this.$moment().add(8, 'd').utc().format();

			if (item) {
				this.contract.title = item.title;
				this.contract.comment = item.comment;
				this.contract.items = item.items;
			}

			this.template = true;

			this.$router.replace("/contracts/0");
		},
		removeItem(key) {
			this.contract.items.splice(key, 1);
        },
		searchCustomers(search, loading) {
			loading(true);

			this.$http.get("/api/contracts/customers?q=" + search).then(x => {
				this.customers = x.data;

				loading(false);
			});
		},
		selectCustomer(customer) {
			if (customer) {
                var address = "";
				address = customer.address;
                if (customer.zipCode) {
                    address += "<br />" + customer.zipCode
                }
                if (customer.city) {
                    address += " " + customer.city
                }
                if (customer.contact) {
                    address += "<br />Att: " + customer.contact
                }
                if (customer.phone) {
                    address += "<br />Tlf: " + customer.phone
                }
                if (customer.vatNumber) {
                    address += "<br />CVR: " + customer.vatNumber
                }
                this.contract.customerAddress = address;
				this.contract.customer = customer.name;
				this.contract.customerId = customer.id || null;
                this.contract.paymentDays = customer.paymentTerms || 0;
                this.contract.dueDate = this.$moment().add(customer.paymentTerms, 'd').utc().format();
			} else {
				this.contract.customer = null;
				this.contract.customerId = null;
			}
        },
        uploadFile(e) {
            var formData = new FormData();
            formData.append("file", e);

            this.$http.post("/api/contracts/upload/" + this.id, formData).then(x => {
                if (x.data === true) {
                    this.toast.showToast('Filen blev uploadet', { theme: "success" });

                    this.getFiles();

                    this.$refs.file.files = null;
                }
			});
        },
        getFiles() {
			this.$http.get("/api/contracts/" + this.id + "/files").then(x => {
				this.files = x.data;
			});
        },
        deleteFile(file, key) {
            this.$http.post("/api/contracts/" + this.id + "/files/delete?file=" + file).then(x => {
				this.files.splice(key, 1);
			});
        },
        getContract() {
            this.$http.get("/api/contracts/" + this.id).then(x => {
                if (x.data) {
                    this.contract = x.data;
                    this.template = true;

                    this.getFiles();
                }
            });
        },
		saveContract() {
			this.$validator.validate().then(x => {
				if (x === true) {
					this.$http
						.post("/api/contracts/update", this.contract)
						.then(x => {
							if (x.data) {
                                if (this.newContract) {
                                    //location = "/contracts/" + x.data;
                                    this.$router.push("/contracts/" + x.data);
                                    this.getContract();
                                } else {
                                    this.getContract();
                                }
							}
						});
				}
			});
        },
        approveContract() {
            this.$http.post("/api/contracts/approve", this.contract).then(x => {
                this.getContract();
            });
        },
        requestApproval() {
            if (confirm("Er du sikker på du vil sende denne anmodning?")) {
                this.$http.post("/api/contracts/request-approval", this.contract).then(x => {
                    if (x.data) {
                        this.getContract();
                    }
                });
            }
        },
        sendInvoice() {
            if (confirm("Er du sikker på du vil sende denne faktura?")) {
                this.$http.post("/api/contracts/send-invoice", this.contract).then(x => {
                    if (x.data) {
                        this.getContract();
                    }
                });
            }
        },
        markAsSend() {
            if (confirm("Er du sikker på du vil markere fakturaen som afsendt?")) {
                this.$http.post("/api/contracts/mark-send/" + this.id).then(x => {
                    if (x.data) {
                        this.getContract();
                    }
                });
            }
        },
        renewContract() {
            if (confirm("Er du sikker på du vil forlænge denne aftale?")) {
                this.$http.post("/api/contracts/extend/" + this.id).then(x => {
                    if (x.data) {
                        this.$router.push("/contracts/" + x.data);
                        this.getContract();
                    }
                });
            }
        },
        registerPayment() {
            this.$http.post("/api/contracts/payments/add/" + this.id, this.payment).then(x => {
                this.getContract();
            });
        },
        deletePayment(item) {
            if (confirm("Er du sikker på du vil slette denne betaling?")) {
                this.$http.post("/api/contracts/payments/delete/" + this.id, item).then(x => {
                    this.getContract();
                });
            }
        },
        updateInvoiceNumber() {
            this.$http.post("/api/contracts/update-invoice", this.contract).then(x => {
                this.getContract();
            });
        },
        cancelContract() {
            if (confirm("Er du sikker på du vil anullere denne aftale?")) {
                if (this.newContract) {
                    this.$router.replace("/contracts");
                } else {
                    this.$http.post("/api/contracts/cancel/" + this.id).then(x => {
                        this.$router.replace("/contracts");
                    });
                }
            }
        },
        deleteContract() {
            if (confirm("Er du sikker på du vil slette denne aftale?")) {
                this.$http.post("/api/contracts/delete/" + this.id).then(x => {
                    this.$router.replace("/contracts");
                });
            }
        },
        saveComment() {
            this.$http.post("/api/contracts/update-comment", this.contract).then(x => {
                this.getContract();
            });
        },
        addProducts() {
            for (let index = 0; index < this.items.length; index++) {
                const element = this.items[index];

                this.contract.items.push({
                    productId: element.id,
                    name: element.name,
                    description: element.description,
                    quantity: 1,
                    price: element.price,
                    discount: 0
                });
            }

            this.items = [];
        },
        paymentSelect(){
            el: ''
        }
	},
	created() {
		this.getTemplates();
        this.getSettings();
        this.getProducts();

		if (this.id == "new") {
			this.template = false;
		} else {
			if (this.id != "0") {
                this.getContract();
			} else {
				this.template = true;
			}
		}
	},
	mounted() {
        this.toast = this.$refs.toast;
        this.toast.setOptions({ position: "bottom right" });
    },
	beforeRouteUpdate(to, from, next) {
		if (to.params.id == "new") {
			this.template = false;
		}

		next();
	}
};
</script>
