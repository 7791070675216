<template>
    <div class="row">
        <div class="col-12 pt-3">
            <h2 class="" v-if="!this.form.id">Opret produkt</h2>
            <h2 class="" v-else>Rediger produkt</h2>
        </div>
        <div class="col-12 col-md-4 col-lg-3 ">

            <b-form-group label="Navn:" :state="!errors.has('name')" :invalid-feedback="errors.first('name')">
                <b-form-input type="text" v-model="form.name" name="name" 
                    v-validate="'required'" data-vv-as="Navn"
                    :state="!errors.has('name') && form.name"></b-form-input>
            </b-form-group>

            <b-form-group label="Produktnummer:">
                <b-form-input type="text" v-model="form.productNumber" name="productNumber" 
                    data-vv-as="Produktnummer"></b-form-input>
            </b-form-group>

            <b-form-group label="Beskrivelse:">
                <b-form-textarea :rows="3" v-model="form.description" name="description"></b-form-textarea>
            </b-form-group>

            <b-form-group label="Pris:">
                <b-form-input type="number" v-model="form.price" name="price" 
                    data-vv-as="Pris"></b-form-input>
            </b-form-group>
            
            <b-form-group label="Kategori:" v-if="productGroups">
                <b-form-select v-model="form.category" :options="productGroups" class="" />
            </b-form-group>

            <b-form-group label="Sortering:">
                <b-form-input type="number" v-model="form.sort" name="sort" 
                    data-vv-as="Sortering"></b-form-input>
            </b-form-group>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <b-form-group label="Faktura gruppe:" v-if="invoiceGroups">
                <b-form-select v-model="form.invoiceGroup" :options="invoiceGroups" class="">
                    <template slot="first">
                        <option :value="null">Ingen</option>
                    </template>
                </b-form-select>
            </b-form-group>
            <b-form-group label="Valgmulighed på kunden:">
                <b-form-select v-model="form.type" :options="types" class="">
                    <template slot="first">
                        <option :value="null">Ingen</option>
                    </template>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Værdier:" v-if="form.type == 'dropdown'">
                <b-form-input v-model="typeValue" @change="addTypeValue"></b-form-input>
            </b-form-group>
            <div>
                <b-badge v-for="(item, key) in form.typeValues" :key="item" pill variant="primary" @click="removeTypeValue(key)" style="padding: 5px 8px; margin: 0 7px 8px 0">
                    {{item}} <fa icon="times" /> 
                </b-badge>
            </div>

            <b-form-checkbox v-model="form.isTicket" v-if="false">
                Produktet er en billet
            </b-form-checkbox>
            
            <b-form-checkbox v-model="form.ownInvoiceLine">
                Vis produktet på sin egen linje på fakturaen
            </b-form-checkbox>
            
            <b-form-checkbox v-model="form.showPriceSeparately">
                Vis produktets individuelle pris på fakturaen
            </b-form-checkbox>

            <b-form-group label="Billet type:" v-if="invoiceGroups" class="mt-2">
                <b-form-select v-model="form.ticketType" :options="ticketTypes" class=""></b-form-select>
            </b-form-group>

            <b-form-group label="Kolonne:">
                <b-form-select v-model="form.column" :options="columns" class="" />
            </b-form-group>

            <b-form-group label="Tal type:">
                <b-form-select v-model="form.numberType" :options="numberTypes" class="" />
            </b-form-group>
            
            <b-form-checkbox v-model="form.preSelected">
                Forudvælg produkt
            </b-form-checkbox>

            <b-form-checkbox v-model="form.exportHidden">
                Skjul i eksport
            </b-form-checkbox>
        </div>


        <div class="col-12 action-footer">
            <b-button type="button" variant="primary" v-if="!this.form.id" @click="onSubmit">
                <fa icon="plus" /> Opret produkt</b-button>
            <b-button type="button" variant="primary" v-else @click="onSubmit">
                <fa icon="save" /> Gem produkt</b-button>

            <b-button type="reset" variant="danger" @click="onReset">
                <fa icon="times" /> Fortryd</b-button>
        </div>

        <v-toast ref="toast"></v-toast>
    </div>
</template>

<script>
export default {
	data: () => ({
		form: {
            name: null,
            productNumber: null,
            description: null,
            id: null,
            price: 0,
            invoiceGroup: null,
            type: null,
            typeValues: [],
            column: 0,
            preSelected: false,
            sort: 0,
            exportHidden: false,
            ticketType: 0
        },
        contracts: null,
        invoiceGroups: null,
        productGroups: null,
        types: [
            { value: "bool", text: "Checkboks" },
            { value: "int", text: "Tal" },
            { value: "bool-text", text: "Checkboks og tekst" },
            { value: "int-text", text: "Tal og tekst" },
            { value: "dropdown", text: "Dropdown" },
        ],
        columns: [
            { value: 0, text: "Kolonne 1" },
            { value: 1, text: "Kolonne 2" },
            { value: 2, text: "Kolonne 3" },
            { value: 3, text: "Skjult" },
        ],
        numberTypes: [
            { value: 0, text: "Stk" },
            { value: 1, text: "Personer" },
            { value: 2, text: "Beløb" },
        ],
        ticketTypes: [
            { value: 0, text: "Ikke billet" },
            { value: 10, text: "Engangsbillet" },
            { value: 20, text: "Sæsonbillet" },
        ],
        toast: null,
        typeValue: null
	}),
	methods: {
        status(status) {
            if (status) {
                if (status == "Accepted") {
                    return { status: "Godkendt", class: "success" };
                }
                if (status == "CustomerAccepted") {
                    return { status: "Godkendt af kunden", class: "success" };
                }
                if (status == "Draft") {
                    return { status: "Kladde", class: "warning" };
                }
                if (status == "Pending") {
                    return { status: "Afventer godkendelse", class: "warning" };
                }
                if (status == "Cancelled") {
                    return { status: "Opsagt", class: "danger" };
                }
            }

            return null;
        },
		onSubmit() {
			this.$validator.validate().then(x => {
				if (x === true) {
					this.$http.post("/api/products/update", this.form).then(x => {
                        this.toast.showToast('Ændringerne blev gemt!', { theme: "success" });
					});
				}
			});
		},
		onReset() {
			this.$router.push('/products');
		},
        getSettings() {
            this.$http.get("/api/product/settings").then(x => {
                if (x.data) {
                    this.invoiceGroups = x.data.invoiceGroups;
                    this.productGroups = x.data.productGroups;
                }
            });
        },
        addTypeValue(val) {
            if (!this.form.typeValues) {
                this.form.typeValues = [];
            }
            this.form.typeValues.push(val);
            this.typeValue = null;
        },
        removeTypeValue(key) {
            console.log(this.form.typeValues);
            this.form.typeValues.splice(0, 1);
        }
    },
    created() {
        this.getSettings();

        if (this.$route.params.id && this.$route.params.id != "new") {
            var id = this.$route.params.id;

            this.$http.get("/api/product/" + id).then(x => {
                if (x.data) {
                    this.form = x.data;
                    if (!this.form.typeValues) {
                        this.form.typeValues = [];
                    }
                }
            });
        }
    },
	mounted() {
        this.toast = this.$refs.toast;
        this.toast.setOptions({ position: "bottom right" });
    }
};
</script>