<template>
    <div class="row">
        <div class="col-12 pt-3">
            <DataTable ref="dataTable" get-url="/api/products" :fields="fields" filter="true" :take="25" cache-search="products-search">
                <template v-slot:cell(name)="data">
                    <router-link :to="'/products/' + data.item.id" variant="primary" size="sm">
                        {{data.item.name}}
                    </router-link>
                </template>

                <template v-slot:cell(edit)="data">
                    <div>
                        <b-button :to="'/products/' + data.item.id" variant="primary" size="sm">
                            <fa icon="edit" /> Rediger
                        </b-button>
                        <b-button @click="deleteProduct(data.item.id)" variant="danger" size="sm">
                            <fa icon="trash" /> Slet
                        </b-button>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import DataTable from "../_ui/dataTable";

export default {
    data: () => ({
        fields: [
			{ key: "name", label: "Produkt" },
			{ key: "description", label: "Beskrivelse" },
			{ key: "price", label: "Pris" },
			{ key: "category", label: "Kategori" },
			{ key: "edit", label: "", thStyle: "width: 180px", class: "text-right" }
        ]
    }),
    components: { DataTable },
	methods: {
        deleteProduct(id) {
            if (confirm("Er du sikker på at du vil fjerne dette produkt?")) {
                this.$http.post("/api/products/delete?id=" + id).then(x => {
                    this.$refs.dataTable.refresh();
                });
            }
        }
	},
    created() {

    },
    mounted() {
    }
};
</script>