import 'core-js/es6/promise'
import 'core-js/es6/array'
import 'core-js/es6/string'
import 'core-js/es6/function'
import 'core-js/es6/map'
import 'core-js/es6/object'
import 'core-js/es6/typed'

import Vue from "vue";
import axios from "axios";
import router from "./router";
import store from "./store";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./css/main.scss";

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import moment from "moment";
moment.locale("da");

import numeral from "numeral";
import "numeral/locales/da-dk";
numeral.locale("da-dk");

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
Vue.component("fa", FontAwesomeIcon);
library.add(fas, far, fab);

import icon from "components/icons/icon.vue";
Vue.component("icon", icon);

import da from "vee-validate/dist/locale/da";
import VeeValidate, { Validator } from "vee-validate";
Validator.localize("da", da);
Vue.use(VeeValidate, {
    locale: "da",
    fieldsBagName: "veeFields"
});

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
Vue.component("v-select", vSelect);

import "vue-toast/dist/vue-toast.min.css";
import VueToast from "vue-toast";
Vue.component("v-toast", VueToast);

import VCalendar from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';

// Use v-calendar, v-date-picker & v-popover components
Vue.use(VCalendar, {
    firstDayOfWeek: 2,  // Monday
    locale: "da-DK",
    formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['DD-MM-YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'],
        dayPopover: 'L',
        data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD']
    }
});

import draggable from 'vuedraggable';
Vue.component("draggable", draggable);

import TinyEditor from '@tinymce/tinymce-vue'
Vue.component("tiny-editor", TinyEditor)
import editor from "components/_ui/editor.vue";
Vue.component("editor", editor);


Vue.component("v-render", {
    props: ["item"],
    render(h) {
        return this.item[0];
    }
});

import App from "components/root";

axios.interceptors.response.use(undefined, function(err) {
    return new Promise(function(resolve, reject) {
        if (err.status === 401) {
            // if you ever get an unauthorized, logout the user

            console.log("Unauthorized");
            //this.$store.dispatch(AUTH_LOGOUT);
            // you can also redirect to /login if needed !
        }
        throw err;
    });
});

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$numeral = numeral;

Vue.mixin({
    methods: {
        fieldState(name, scope) {
            var field = this.$validator.fields.find({ name: name, scope: scope });
            if (!field) {
                return null;
            }

            if (field.flags.untouched) {
                return null;
            } else {
                return field.flags.valid;
            }
        }
    }
})

const app = new Vue({
    store,
    router,
    ...App
}).$mount('#app');

export { app, router, store };
