import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import { routes } from './routes'


Vue.use(VueRouter);

let router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

router.beforeEach(async (to, _, next) => {
    if (!store.state.init) {
        var state = await store.dispatch("INIT_STATE")
        store.replaceState({ ...state, init: true })
    }

    var isLoggedIn = store.state.loggedIn
    if (!isLoggedIn && !to.meta.isPublic)
        return next({ name: 'login' })

    return next()
})

export default router
