<template>
    <div class="row">
        <div class="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <h2>Opret klub</h2>
            <b-form @submit.prevent="onSubmit" @reset="onReset" novalidate>
                <b-form-group label="Klubnavn:" :state="!errors.has('name')" :invalid-feedback="errors.first('name')">
                    <b-form-input type="text" v-model="form.name" name="name" v-validate="'required'" data-vv-as="Klubnavn" :state="!errors.has('name') && form.name"></b-form-input>
                </b-form-group>

                <b-form-group :state="!errors.has('accept')" :invalid-feedback="'Betingelserne skal accepteres!'">
                    <b-form-checkbox v-model="form.accept" class="mr-1">
                        Accepter betingelser
                    </b-form-checkbox>
                    <a href="/">(Læs her)</a>

                    <input type="checkbox" class="d-none" v-model="form.accept" v-validate="'required'" name="accept" />
                </b-form-group>

                <b-button type="submit" variant="primary">
                    <fa icon="save" /> Opret</b-button>
                <b-button to="/" variant="danger" exact>
                    <fa icon="times" /> Fortryd</b-button>
            </b-form>
        </div>
    </div>
</template>

<script>
export default {
	data: () => ({
		form: {
			accept: false
		}
	}),
	methods: {
		onSubmit() {
			this.$validator.validate().then(x => {
				if (x === true) {
					this.$http.post("/api/create-club", this.form).then(x => {
						location = "/";
					});
				}
			});
		},
		onReset() {
			this.form = {};
		}
	},
	mounted() {}
};
</script>