<template>
    <div class="row">
        <div class="col-12 pt-3">
            <DataTable ref="dataTable" get-url="/api/leads" :fields="fields" filter="true" :take="10000" sort="contactDate" :desc="true"
                       cache-search="leads-search" :filters="filters" :searchFilter.sync="searchFilter">
                <template slot="create">
                    <div class="col-12 col-sm-3 text-right">
                        <b-btn :href="'/api/leads/export?filter=' + JSON.stringify(searchFilter)" variant="success" class="mb-3 mr-2">
                            <fa icon="file-export" /> Eksportér leads
                        </b-btn>
                    </div>
                </template>


                <template v-slot:cell(name)="data">
                    <router-link :to="'/leads/' + data.item.id" variant="primary" size="sm" v-if="hasAccess(data.item.creatorId)">
                        {{data.item.name}}
                    </router-link>
                    <span v-else>
                        {{data.item.name}}
                    </span>
                </template>

                <template v-slot:cell(owners)="data">
                    <span v-if="data.value.length > 0 && users.find(x => x.value == data.value[0])">
                        {{users.find(x => x.value == data.value[0]).text}}
                    </span>
                    <span v-else></span>
                </template>

                <template v-slot:cell(contacts)="data">
                    <span v-if="data.item.contacts && data.item.contacts.length > 0 && hasAccess(data.item.creatorId)">
                        {{data.item.contacts[0].name}}
                    </span>
                    <span v-else></span>
                </template>

                <template v-slot:cell(email)="data">
                    <span v-if="hasAccess(data.item.creatorId)">
                        {{data.value}}
                    </span>
                    <span v-else></span>
                </template>

                <template v-slot:cell(phone)="data">
                    <span v-if="hasAccess(data.item.creatorId)">
                        {{data.value}}
                    </span>
                    <span v-else></span>
                </template>

                <template v-slot:cell(phone)="data">
                    <span v-if="hasAccess(data.item.creatorId)">
                        {{data.value}}
                    </span>
                    <span v-else></span>
                </template>

                <template v-slot:cell(contactDate)="data">
                    <span v-if="data.value" class="d-block">
                        <span v-if="$moment(data.value) > $moment()"
                              style="display: inline-block; border-radius: 4px; background: green; color: #fff; padding: 2px 8px;">
                            {{ $moment(data.value).format("DD-MM-YYYY") }}
                        </span>
                        <span v-if="$moment(data.value) < $moment() && $moment(data.value) > $moment().subtract(7, 'days')"
                              style="display: inline-block; border-radius: 4px; background: #ffc107; color: #000; padding: 2px 8px;">
                            {{ $moment(data.value).format("DD-MM-YYYY") }}
                        </span>
                        <span v-if="$moment(data.value) < $moment().subtract(7, 'days')"
                              style="display: inline-block; border-radius: 4px; background: red; color: #fff; padding: 2px 8px;">
                            {{ $moment(data.value).format("DD-MM-YYYY") }}
                        </span>
                        <span v-else></span>
                    </span>
                    <span v-else></span>
                </template>
                <template v-slot:cell(creatorId)="data">
                    <span v-if="data.value && users.find(x => x.value == data.value)">
                        {{users.find(x => x.value == data.value).text}}
                    </span>
                    <span v-else></span>
                </template>


                <template v-slot:cell(status)="data">
                    <b-badge :variant="status(data.item).class" pill class="py-2 px-3" style="font-size: 14px;">{{status(data.item).status}}</b-badge>
                </template>

                <template v-slot:cell(edit)="data">
                    <div>
                        <b-button :to="'/leads/' + data.item.id" variant="primary" size="sm" v-if="hasAccess(data.item.creatorId)">
                            <fa icon="edit" /> Rediger
                        </b-button>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import DataTable from "../_ui/dataTable";

export default {
    data: () => ({
        fields: [
            { key: "status", label: "Status" },
            { key: "owners", label: "Ansvarlig" },
			{ key: "name", label: "Navn", sortable: true },
            { key: "contacts", label: "Kontaktperson" },
			{ key: "email", label: "E-mail" },
			{ key: "phone", label: "Telefon" },
            { key: "contactDate", label: "Kontaktdato", sortable: true },
            { key: "creatorId", label: "Oprettet af" },
			{ key: "edit", label: "", thStyle: "width: 180px", class: "text-right" }
        ],
        statuses: [
            { text: "Alle", value: "" },
            { text: "Lead", value: "Lead" },
            { text: "Kunde", value: "Customer" },
            { text: "Opsagt", value: "Lost" },
        ],
        products: [],
        searchFilter: {},
        users: []
    }),
    components: { DataTable },
    computed: {
        filters() { 
            return [
                { field: "status", items: this.statuses, value: "Lead", label: "Status" },
                { field: "product", items: this.products, value: "", label: "Produkt" },
                { field: "creatorId", items: this.users, value: "", label: "Oprettet af" },
                { field: "owner", items: this.users, value: "", label: "Ansvarlig" },
            ];
        },
        userId() {
            return this.$store.state.userId
        },
        userRole() {
            return this.$store.state.role
        }
    },
	methods: {
        status(item) {
            if (item.status) {
                if (item.status == "Lead") {
                    return { status: "Lead", class: "warning" };
                }
                if (item.status == "Customer") {
                    return { status: "Kunde", class: "success" };
                }
                if (item.status == "Lost") {
                    return { status: "Opsagt", class: "danger" };
                }
            }

            return { status: "Ingen", class: "secondary" };
        },
        getProducts() {
            this.$http.get("/api/customers/products").then(x => {
                if (x.data) {
                    this.products = x.data.products.map(e => {
                        return { text: e.name, value: e.id };
                    });

                    this.products.splice(0, 0, { text: "Alle", value: "" })
                }
            });
        },
        getUsers() {
            this.$http.get("/api/customers/users").then(x => {
                if (x.data) {
                    this.users = x.data.map(e => {
                        return { text: e.name, value: e.id };
                    });

                    this.users.splice(0, 0, { text: "Alle", value: "" })
                }
            });
        },
        hasAccess(creatorId) {
            if (creatorId == null || creatorId == this.userId || this.userRole != 'Lead') {
                return true
            }

            return false
        }
	},
    created() {
        this.getProducts();
        this.getUsers();
    },
    mounted() {
    }
};
</script>
