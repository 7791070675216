<template>
    <div class="main">
        <NavMenu />

        <div class="container-fluid">
            <router-view :key="$route.fullPath"></router-view>
        </div>
        
    </div>
</template>

<script>
import NavMenu from "./nav-menu.vue";

export default {
	data: () => ({}),
    components: { NavMenu },
    created() {
    },
    mounted() {
    }
};
</script>

<style>

</style>