<template>
    <div class="row justify-content-md-center">
        <div class="col-12 col-md-4 col-lg-3 pt-3">
            <h2 class="text-center mb-4">Invitér bruger</h2>
            <b-form @submit.prevent="onSubmit" @reset="onReset" novalidate>
                <b-form-group :state="!errors.has('email')" :invalid-feedback="errors.first('email')">
                    <b-form-input type="text" v-model="form.email" name="email" 
                        v-validate="'required|email'" data-vv-as="E-mail" placeholder="E-mail"
                        :state="!errors.has('email') && form.email"></b-form-input>
                </b-form-group>

                <b-form-group :state="!errors.has('role')" :invalid-feedback="errors.first('role')" class="mb-4">
                    <b-form-select name="role" v-model="form.role" :options="roles"
                        v-validate="'required'" data-vv-as="Rolle"
                        :state="!errors.has('role') && form.role">
                        <template slot="first">
                            <option :value="null" disabled>-- Vælg en rolle --</option>
                        </template>
                    </b-form-select>
                </b-form-group>
                
                <div class="text-center">
                    <b-button type="submit" variant="primary">
                        <fa icon="envelope" /> Send invitation</b-button>
                    <b-button type="reset" variant="danger">
                        <fa icon="times" /> Fortryd</b-button>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
export default {
	data: () => ({
		form: {
            email: null,
            role: null
        },
        roles: [
            { value: 'Lead', text: 'Lead' },
            { value: 'Seller', text: 'Sælger' },
            { value: 'Accountant', text: 'Bogholder' },
            { value: 'Admin', text: 'Admin' }
        ]
	}),
	methods: {
		onSubmit() {
			this.$validator.validate().then(x => {
				if (x === true) {
					this.$http.post("/api/users/invite", this.form).then(x => {
						this.$router.push('/users');
					});
				}
			});
		},
		onReset() {
			this.$router.push('/users');
		}
	},
	mounted() {}
};
</script>