<template>
    <div class="row dashboard">
        <div class="col-12 col-md-4" v-if="renews">
            <h6 class="p-2 bg-light border">Kommende gentegninger</h6>
            <table class="table table-bordered table-striped table-sm">
                <thead>
                    <tr>
                        <th>Firma</th>
                        <th>Kontaktperson</th>
                        <th>Telefon</th>
                        <th width="100" class="text-right">Dato</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in renews" :key="item.id">
                        <td>
                            <router-link :to="'/customers/' + item.id">{{item.name}}</router-link>
                        </td>
                        <td>{{item.contact}}</td>
                        <td>{{item.phone}}</td>
                        <td class="text-right">{{$moment(item.date).format("DD-MM-YYYY")}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-12 col-md-4" v-if="invoices">
            <h6 class="p-2 bg-light border">Næste faktureringer</h6>
            <table class="table table-bordered table-striped table-sm">
                <thead>
                    <tr>
                        <th>Firma</th>
                        <th>Beløb</th>
                        <th>Kategori</th>
                        <th width="100" class="text-right">Dato</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in invoices" :key="item.id + '-' + key">
                        <td>
                            <router-link :to="'/customers/' + item.id">{{item.name}}</router-link>
                        </td>
                        <td>{{item.value}}</td>
                        <td>{{item.type}}</td>
                        <td class="text-right">{{$moment(item.date).format("DD-MM-YYYY")}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-12 col-md-4" v-if="leads">
            <h6 class="p-2 bg-light border">Opfølgning på leads</h6>
            <table class="table table-bordered table-striped table-sm">
                <thead>
                    <tr>
                        <th>Firma</th>
                        <th>Kontaktperson</th>
                        <th width="100" class="text-right">Dato</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in leads" :key="item.id">
                        <td>
                            <router-link :to="'/leads/' + item.id">{{item.name}}</router-link>
                        </td>
                        <td>{{item.contact}}</td>
                        <td class="text-right">
                            <span v-if="item.date">{{$moment(item.date).format("DD-MM-YYYY")}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>



        <div class="col-12 col-md-8">
            <div class="text-center">
                <b-form-spinbutton v-model="year" min="2020" max="2100" inline @change="changeYear"></b-form-spinbutton>
            </div>

            <BarChart ref="chart" v-if="chartData" :chart-data="chartData"></BarChart>

            <hr />

            <div class="text-center">
                <b-form-spinbutton v-model="year" min="2020" max="2100" inline @change="changeYear"></b-form-spinbutton>
            </div>

            <BarChart ref="typeChart" v-if="chartTypeData" :chart-data="chartTypeData"></BarChart>

        </div>

        <div v-if="statusData" class="col-12 col-md-4">
            <div class="pa-40 bg-light">
                <h5 class="text-center">Økonomisk status for nuværende aftaler</h5>

                <div class="px-75">
                    <PieChart ref="statusChart" :chart-data="statusData"></PieChart>
                </div>

                <div class="text-center pa-20" style="font-size: 18px;">
                    <strong>{{statusData.invoiced}}</strong> af <strong>{{statusData.total}}</strong> faktureret for <strong>{{year}}</strong>
                </div>
                <div class="d-flex justify-content-center" style="font-size: 18px;">
                    <div class="text-center alert alert-dark d-inline">
                        Resterende: <strong>{{statusData.rest}}</strong>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Chart from "chart.js/auto"
    import BarChart from "./bar-chart"
    import PieChart from "./pie-chart"

    export default {
        data: (self) => ({
            renews: null,
            invoices: null,
            leads: null,

            year: self.$moment().year(),
            chartData: null,
            chartTypeData: null,
            statusData: null
        }),
        components: { BarChart, PieChart },
        computed: {
            dashboardMode() {
                return this.$store.state.dashboardMode
            }
        },
        watch: {},
        methods: {
            getRenews() {
                this.$http.get("/api/dashboard/renews").then(x => {
                    this.renews = x.data;
                });
            },
            getInvoices() {
                this.$http.get("/api/dashboard/invoices").then(x => {
                    this.invoices = x.data;
                });
            },
            getLeads() {
                this.$http.get("/api/dashboard/leads").then(x => {
                    this.leads = x.data;
                });
            },

            changeYear() {
                if (this.dashboardMode == 0 || this.dashboardMode == 2) {
                    this.getSeasonChart()
                    this.getSeasonTypeChart()
                    this.getSeasonStatus()
                } else {
                    this.getInvoiceChart()
                    this.getInvoiceTypeChart()
                    this.getInvoiceStatus()
                }
            },

            getSeasonChart() {
                this.$http.get("/api/dashboard/season-chart").then(x => {
                    this.chartData = x.data;

                    if (this.$refs.chart) {
                        this.$refs.chart.update(x.data)
                    }
                });
            },
            getSeasonTypeChart() {
                this.$http.get("/api/dashboard/season-type-chart?year=" + this.year).then(x => {
                    this.chartTypeData = x.data;

                    if (this.$refs.typeChart) {
                        this.$refs.typeChart.update(x.data)
                    }
                });
            },
            getSeasonStatus() {
                this.$http.get("/api/dashboard/season-status?year=" + this.year).then(x => {
                    this.statusData = x.data;

                    if (this.$refs.statusChart) {
                        this.$refs.statusChart.update(x.data)
                    }
                });
            },


            getInvoiceChart() {
                this.$http.get("/api/dashboard/invoice-chart?year=" + this.year).then(x => {
                    this.chartData = x.data;

                    if (this.$refs.chart) {
                        this.$refs.chart.update(x.data)
                    }
                });
            },
            getInvoiceTypeChart() {
                this.$http.get("/api/dashboard/invoice-type-chart?year=" + this.year).then(x => {
                    this.chartTypeData = x.data;

                    if (this.$refs.typeChart) {
                        this.$refs.typeChart.update(x.data)
                    }
                });
            },
            getInvoiceStatus() {
                this.$http.get("/api/dashboard/invoice-status?year=" + this.year).then(x => {
                    this.statusData = x.data;

                    if (this.$refs.statusChart) {
                        this.$refs.statusChart.update(x.data)
                    }
                });
            },
        },
        created() {
            this.getRenews()
            this.getInvoices()
            this.getLeads()

            this.changeYear()

        },
        mounted() {

        }
    };
</script>
