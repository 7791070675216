<template>
    <div class="row">
        <div class="col-12 pt-3">
            <h3 class="" v-if="!this.form.id">Opret arrangement</h3>
            <h3 class="" v-else>Rediger arrangement</h3>
        </div>

        <b-tabs content-class="col-12" class="mt-2 col-12" lazy>
            <b-tab title="Arrangement" active>
                <div class="row">

                    <div class="col-12 col-md-4 col-lg-3 ">

                        <b-form-group label="Navn:" :state="fieldState('name')" :invalid-feedback="errors.first('name')">
                            <b-form-input type="text" v-model="form.name" name="name"
                                          v-validate="'required'" data-vv-as="Navn"
                                          :state="fieldState('name')"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Beskrivelse:">
                            <editor v-model="form.description"></editor>
                        </b-form-group>



                        <b-form-group label="Kampsponsor link:">
                            <b-form-input type="text" v-model="form.matchSponsorLink"></b-form-input>
                        </b-form-group>
                        <div class="row">
                            <div class="col-12 col-md-6" v-if="!creating">
                                <b-form-group label="Kampsponsor logo:">
                                    <b-form-file ref="file" placeholder="Upload logo..." @input.native="uploadLogo($event, 'matchsponsor')"></b-form-file>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-6" v-if="!creating">
                                <img :src="form.matchSponsorLogo" v-if="form.matchSponsorLogo" style="max-width: 100%;" class="mb-2" />
                            </div>
                        </div>

                        <b-form-group label="Madsponsor link:">
                            <b-form-input type="text" v-model="form.foodSponsorLink"></b-form-input>
                        </b-form-group>
                        <div class="row">
                            <div class="col-12 col-md-6" v-if="!creating">
                                <b-form-group label="Madsponsor logo:">
                                    <b-form-file ref="file" placeholder="Upload logo..." @input.native="uploadLogo($event, 'foodsponsor')"></b-form-file>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-6" v-if="!creating">
                                <img :src="form.foodSponsorLogo" v-if="form.foodSponsorLogo" style="max-width: 100%;" class="mb-2" />
                            </div>
                        </div>



                        <b-input-group prepend="Dato" class="mb-2">
                            <v-date-picker mode="single" popover-visibility="focus" autocomplete="off"
                                           :value="$moment(form.date).toDate()"
                                           @input="form.date = $moment($event).utc().format()"
                                           title-transition="none" weeks-transition="none" class="form-control"
                                           :input-props="{ class: 'form-control', readOnly: true }">
                            </v-date-picker>
                        </b-input-group>

                        <b-form-group label="Tidspunkt:">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-input-group prepend="Time">
                                        <b-form-input type="number" v-model="form.hour"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-input-group prepend="Minut">
                                        <b-form-input type="number" v-model="form.minutes"></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                        </b-form-group>


                        <b-input-group prepend="Tilmeldingsfrist" class="mb-2">
                            <v-date-picker mode="single" popover-visibility="focus" autocomplete="off"
                                           :value="$moment(form.registrationEnd).toDate()"
                                           @input="form.registrationEnd = $moment($event).utc().format()"
                                           title-transition="none" weeks-transition="none" class="form-control"
                                           :input-props="{ class: 'form-control', readOnly: true }">
                            </v-date-picker>
                        </b-input-group>


                        <b-form-group label="Tilmeldingsfrist tidspunkt:">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-input-group prepend="Time">
                                        <b-form-input type="number" v-model="form.endHour"></b-form-input>
                                    </b-input-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-input-group prepend="Minut">
                                        <b-form-input type="number" v-model="form.endMinutes"></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                        </b-form-group>


                        <b-form-group label="Antal billetter:">
                            <b-form-input type="number" v-model="form.totalTickets"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Maks. antal billetter pr. sponsor:">
                            <b-form-input type="number" v-model="form.maxTickets"></b-form-input>
                        </b-form-group>

                        <b-form-checkbox v-model="form.showAttendees">
                            Vis tilmeldte
                        </b-form-checkbox>

                        <b-form-checkbox v-model="form.openEvent">
                            Åben tilmelding
                        </b-form-checkbox>

                        <b-form-checkbox v-model="form.additionalTickets">
                            Tillad tilkøb af ekstra billetter
                        </b-form-checkbox>

                        <b-form-group label="Ekstrabillet pris:">
                            <b-form-input type="number" v-model="form.ticketPrice"></b-form-input>
                        </b-form-group>

                        <b-form-group label="E-mail notifikation interval:" class="mt-2">
                            <v-select :options="intervals" multiple placeholder="Ingen notifikation" label="text"
                                      :value="intervals.filter(x => form.emailInterval.includes(x.value))"
                                      @input="x => form.emailInterval = x.map(e => e.value)"></v-select>
                        </b-form-group>

                        <b-form-group label="SMS notifikation interval:">
                            <v-select :options="intervals" multiple placeholder="Ingen notifikation" label="text"
                                      :value="intervals.filter(x => form.smsInterval.includes(x.value))"
                                      @input="x => form.smsInterval = x.map(e => e.value)"></v-select>
                        </b-form-group>

                        <b-form-group label="Brugbare sæsonkort:" class="mb-2">
                            <v-select :options="seasonProducts" multiple label="name"
                                      :value="seasonProducts.filter(x => form.seasonProducts.includes(x.id))"
                                      @input="x => form.seasonProducts = x.map(e => e.id)"></v-select>
                        </b-form-group>

                        <b-form-group label="Brugbare gæstekort:" class="mb-2">
                            <v-select :options="ticketProducts" multiple label="name"
                                      :value="ticketProducts.filter(x => form.ticketProducts.includes(x.id))"
                                      @input="x => form.ticketProducts = x.map(e => e.id)"></v-select>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6 col-lg-5">

                        <table class="table table-striped" v-if="!creating">
                            <thead>
                                <tr>
                                    <th class="py-2 align-middle">
                                        Kunde<br>
                                        <span style="font-weight: normal;">Total: {{stats.total}}</span>
                                    </th>
                                    <th class="py-2 align-middle">
                                        Sæsonkort<br>
                                        <span style="font-weight: normal;">Stk: {{stats.seasonTickets}}</span>
                                    </th>
                                    <th class="py-2 align-middle">
                                        Billetter<br>
                                        <span style="font-weight: normal;">Stk: {{stats.tickets}}</span>
                                    </th>
                                    <th class="py-2 align-middle">
                                        Ekstrabilletter<br>
                                        <span style="font-weight: normal;">Stk: {{stats.extraTickets}}</span>
                                    </th>
                                    <th class="py-2 align-middle">Tilmelding</th>
                                    <th class="text-right py-2 align-middle">
                                        <b-button size="sm" variant="success" v-b-modal.invite-modal>
                                            Opret invitationer
                                        </b-button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, key) in form.invites" :key="'i-' + key">
                                    <td>{{item.customer}}</td>
                                    <td>{{item.seasonTickets}}</td>
                                    <td>{{item.tickets}}</td>
                                    <td>{{item.extraTickets}}</td>
                                    <td>
                                        <a :href="form.pageUrl + '?token=' + item.token" target="_blank">Tilmeld</a>
                                    </td>
                                    <td class="text-right">
                                        <b-button @click="deleteInvite(item)" variant="danger" size="sm" class="px-2 py-0"><fa icon="times" /></b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                    <div class="col-12 action-footer">
                        <b-button type="button" variant="primary" v-if="!form.id" @click="onSubmit">
                            <fa icon="plus" /> Opret arrangement
                        </b-button>

                        <b-button type="button" variant="primary" v-else @click="onSubmit">
                            <fa icon="save" /> Gem arrangement
                        </b-button>

                        <b-button type="reset" variant="danger" @click="onReset">
                            <fa icon="times" /> Fortryd
                        </b-button>

                        <b-button type="button" variant="info" v-if="!creating" :href="'/events/print/' + form.id" target="_blank">
                            <fa icon="print" /> Print tilmeldingsliste
                        </b-button>

                        <b-button type="button" variant="success" v-if="!creating" v-b-modal.scan-modal>
                            <fa icon="barcode" /> Start indscanning
                        </b-button>

                        <b-button type="button" variant="success" @click="sendReminder">
                            <fa icon="envelope" /> Send påmindelse
                        </b-button>

                        <b-button type="button" variant="secondary" v-b-modal.test-modal>
                            <fa icon="envelope" /> Send test
                        </b-button>
                    </div>

                </div>
            </b-tab>
            <b-tab title="Bordplanlægning" v-if="!creating">
                <Tables :event="form" />
            </b-tab>
        </b-tabs>


        <b-modal title="Opret invitationer" id="invite-modal" @ok="createInvites" @show="invite = {}" @close="showManualCard = false" ok-title="Send invitationer" cancel-title="Fortryd">
            <div v-if="invite">
                <b-form-group label="Produkt:" class="mb-2">
                    <v-select :options="inviteProducts" v-model="invite.productIds"
                              multiple label="name" :reduce="x => x.id"></v-select>
                </b-form-group>
            </div>
        </b-modal>

        <b-modal title="Send test invitation" id="test-modal" @ok="sendTestInvite" @show="invite = { email: $store.state.user }" ok-title="Send test" cancel-title="Fortryd">
            <div v-if="invite">
                <b-form-group label="E-mail:" class="mb-2">
                    <b-form-input type="text" v-model="invite.email"></b-form-input>
                </b-form-group>
            </div>
        </b-modal>


        <b-modal title="Scan billetter" id="scan-modal" @show="scan = {}; getScanCount();" ok-title="Luk" ok-only @shown="focusBarcode">
            <div class="text-right" v-if="scanCounter">
                Indscannet: {{scanCounter.usedTickets}} / {{scanCounter.tickets}}
            </div>

            <div v-if="scan && !showManualCard">
                <b-form-group label="Stregkode:">
                    <b-form-input type="text" v-model="scan.barcode" ref="barcode" @change="barcodeScan"></b-form-input>
                </b-form-group>

                <div v-if="scan.result" class="text-center" style="font-size: 16px;">
                    <div v-if="scan.result.success">
                        <fa :icon="['far', 'check-circle']" size="5x" :style="{ color: '#28a745' }" />
                        <p class="mb-1 mt-2">
                            <b>Godkendt!</b>
                        </p>
                        <p v-if="scan.result.tables" class="mb-1">
                            Bord: {{scan.result.tables}}
                        </p>
                        <p v-if="scan.result.customer" class="mb-1">
                            Kunde: {{scan.result.customer}}
                        </p>
                        <p class="mb-1 mt-2">
                            ID: {{scan.result.barcode}}
                        </p>
                    </div>
                    <div v-else>
                        <fa :icon="['far', 'times-circle']" size="5x" :style="{ color: '#dc3545' }" />
                        <p class="mb-1 mt-2">
                            <b>{{scan.result.error}}</b>
                        </p>
                        <p v-if="scan.result.customer" class="mb-1">
                            Kunde: {{scan.result.customer}}
                        </p>
                        <p class="mb-1 mt-2">
                            ID: {{scan.result.barcode}}
                        </p>
                    </div>
                </div>


                <!-- <div class="row">
                <div class="col-12">
                    <b-form-group label="Vælg virksomhed:" class="mb-2">
                        <v-select :options="form.invites" label="customer"
                            :value="form.invites.find(x => x.customerId == manualScan.customerId)"
                            @input="x => manualScan.customerId = (x != null ? x.customerId : null)"></v-select>
                    </b-form-group>
                </div>

                <div class="col-12 col-md-6" v-for="product in products" :key="product">
                    <b-form-group :label="getProduct(product).name">
                        <b-form-input type="number"></b-form-input>
                    </b-form-group>
                </div>

                {{products}}

                {{form.additionalTickets}}

                {{manualScan}}
            </div> -->

            </div>

            <div class="row" v-if="showManualCard">
                <div class="col-12">
                    <b-form-group label="Vælg virksomhed:" class="mb-2">
                        <v-select :options="form.invites" label="customer"
                                  :value="form.invites.find(x => x.customerId == manualCard.customerId)"
                                  @input="x => manualCard.customerId = (x != null ? x.customerId : null)"></v-select>
                    </b-form-group>
                </div>
            </div>

            <template v-slot:modal-footer>
                <div v-if="scan && !showManualCard">
                    <button @click="showManualCard = true" type="button" class="btn btn-success" v-if="scan.result && !scan.result.success">Registrer kort</button>
                    <button @click="$bvModal.hide('scan-modal')" type="button" class="btn btn-primary">Luk</button>
                </div>
                <div v-if="showManualCard">
                    <button @click="createManualCard()" type="button" class="btn btn-success">Tilføj kort</button>
                    <button @click="showManualCard = false" type="button" class="btn btn-secondary">Fortryd</button>
                </div>
            </template>

        </b-modal>

        <v-toast ref="toast"></v-toast>
    </div>
</template>

<script>
import Tables from "./tables";

export default {
	data: () => ({
		form: {
            id: null,
            name: null,
            description: null,
            pageUrl: null,
            date: null,
            hour: 0,
            minutes: 0,
            registrationEnd: null,
            endHour: 0,
            endMinutes: 0,
            invites: [],
            totalTickets: 0,
            maxTickets: 0,
            showAttendees: false,
            openEvent: false,
            additionalTickets: false,
            ticketPrice: 350,
            emailInterval: [],
            smsInterval: [1],
            seasonProducts: [],
            ticketProducts: [],
            matchSponsorLink: null,
            matchSponsorLogo: null,
            foodSponsorLink: null,
            foodSponsorLogo: null,
        },
        invite: null,
        ticketTypes: [
            { value: 0, text: "Ikke billet" },
            { value: 10, text: "Engangsbillet" },
            { value: 20, text: "Sæsonbillet" },
        ],
        intervals: [
            { value: 1, text: "1 dag før" },
            { value: 3, text: "3 dage før" },
            { value: 5, text: "5 dage før" }
        ],
        inviteProducts: [],
        seasonProducts: [],
        ticketProducts: [],
        scan: null,
        manualScan: { customerId: null },
        showManualCard: false,
        manualCard: null,
        toast: null,
        scanCounter: null
    }),
    components: { Tables },
    computed: {
        creating() {
            return this.$route.params.id == "new";
        },
        products() {
            var result = [...this.form.seasonProducts, ...this.form.ticketProducts];

            return result;
        },
        stats() {
            var seasonTickets = this.form.invites.reduce((a, x) => a + x.seasonTickets, 0);
            var tickets = this.form.invites.reduce((a, x) => a + x.tickets, 0);
            var extraTickets = this.form.invites.reduce((a, x) => a + x.extraTickets, 0);

            return {
                seasonTickets: seasonTickets,
                tickets: tickets,
                extraTickets: extraTickets,
                total: seasonTickets + tickets + extraTickets
            };
        }
    },
	methods: {
        getEvent() {
            var id = this.$route.params.id;
            this.$http.get("/api/events/" + id).then(x => {
                if (x.data) {
                    this.form = x.data;

                    this.form.emailInterval = x.data.emailInterval;
                    this.$set(this.form, "emailInterval", x.data.emailInterval);
                    
                }
            });
        },
        getProducts() {
            this.$http.get("/api/events/products").then(x => {
                this.inviteProducts = x.data.inviteProducts;
                this.seasonProducts = x.data.seasonProducts;
                this.ticketProducts = x.data.ticketProducts;
            });
        },
        getProduct(productId) {
            var product = this.seasonProducts.find(x => x.id == productId);
            if (product) {
                return product;
            }
            product = this.ticketProducts.find(x => x.id == productId);
            if (product) {
                return product;
            }
            product = this.inviteProducts.find(x => x.id == productId);
            if (product) {
                return product;
            }
            return { name: "Ukendt produkt" };
        },
        createInvites(evt) {
            if (confirm("Er du sikker på du vil sende nye oprettede invitationer?")) {
                var id = this.$route.params.id;
                this.$http.post("/api/events/invites/" + id, this.invite).then(x => {
                    this.getEvent();
                    this.invite = null;
                });
            } else {
                evt.preventDefault();
            }
        },
        deleteInvite(item) {
            if (confirm("Er du sikker på at du vil slette denne invitation?")) {
                var id = this.$route.params.id;
                this.$http.post("/api/events/invites/delete/" + id + "/" + item.token).then(x => {
                    this.getEvent();
                });
            }
        },

        sendTestInvite(evt) {
            if (confirm("Husk at gemme før du sender test!")) {
                var id = this.$route.params.id;
                this.$http.post("/api/events/test-invite/" + id, this.invite).then(x => {
                    this.getEvent();
                    this.invite = null;
                });
            } else {
                evt.preventDefault();
            }
        },

        focusBarcode(e) {
            this.$refs.barcode.focus();
        },
        barcodeScan(e) {
            var id = this.$route.params.id;
            this.$http.post("/api/events/" + id + "/scan?barcode=" + this.scan.barcode).then(x => {
                if (!x.data.success) {
                    this.manualCard = {
                        customerId: null,
                        barcode: this.scan.barcode,
                        eventId: id
                    };
                }

                this.scan.barcode = null;
                this.scan.result = x.data;

                this.$refs.barcode.focus();

                this.getScanCount();
            });
        },
        createManualCard() {
            var id = this.$route.params.id;

            this.$http.post("/api/events/" + id + "/manual-card", this.manualCard).then(x => {
                this.scan.barcode = null;
                this.scan.result = x.data;
                this.showManualCard = false;
                this.manualCard = null;

                setTimeout(() => {
                    this.$refs.barcode.focus();
                }, 200);

                this.getScanCount();
            });
        },

        getScanCount() {
             var id = this.$route.params.id;
             this.$http.get("/api/events/" + id + "/scanned-tickets").then(x => {
                 this.scanCounter = x.data;
             });
        },

		onSubmit() {
			this.$validator.validate().then(x => {
				if (x === true) {
					this.$http.post("/api/events/update", this.form).then(x => {
                        this.toast.showToast('Ændringerne blev gemt!', { theme: "success" });

                        if (this.creating) {
                            this.$router.push('/events/' + x.data);
                            this.getEvent();
                            this.getProducts();
                        } else {
                            this.getEvent();
                        }
					});
				}
			});
		},
		onReset() {
			this.$router.push('/events');
        },
        
        
        uploadLogo(e, type) {
            var formData = new FormData();
            formData.append("file", e.target.files[0]);

            this.$http.post("/api/events/" + this.form.id + "/upload-logo?type=" + type, formData).then(x => {
                if (x.data === true) {
                    this.toast.showToast('Filen blev uploadet', { theme: "success" });

                    this.getEvent();

                    evt.target.value = "";
                }
			});
        },

        sendReminder() {
            if (confirm("Er du sikker på at du vil sende en påmindelse?")) {
                this.$http.post("/api/email/push?id=" + this.form.id).then(x => {
                    if (x.data === true) {
                        this.toast.showToast('Påmindelse sendt', { theme: "success" });
                    }
                });
            }
        }
    },
    created() {
        this.getProducts();
        
        if (this.$route.params.id && this.$route.params.id != "new") {
            this.getEvent();
        }
    },
	mounted() {
        this.toast = this.$refs.toast;
        this.toast.setOptions({ position: "bottom right" });
    }
};
</script>
