<template>
    <div class="row">
        <div class="col-12 pt-3">
            <DataTable ref="dataTable" get-url="/api/customers" :fields="fields" filter="true" :take="10000"
                       cache-search="customers-search" :filters="filters" :searchFilter.sync="searchFilter">
                <template slot="create">
                    <div class="col-12 col-sm-3 text-right">
                        <b-btn :href="'/api/customers/export?filter=' + JSON.stringify(searchFilter)" variant="success" class="mb-3 mr-2">
                            <fa icon="file-export" /> Eksportér kunder
                        </b-btn>
                        <b-btn :href="'/api/customers/export-emails?filter=' + JSON.stringify(searchFilter)" variant="success" class="mb-3">
                            <fa icon="file-export" /> Eksportér kundemails
                        </b-btn>
                    </div>
                </template>

                <template v-slot:cell(owners)="data">
                    <span v-if="data.value.length > 0 && users.find(x => x.value == data.value[0])">
                        {{users.find(x => x.value == data.value[0]).text}}
                    </span>
                    <span v-else></span>
                </template>

                <template v-slot:cell(name)="data">
                    <router-link :to="'/customers/' + data.item.id" variant="primary" size="sm">
                        {{data.item.name}}
                    </router-link>
                </template>
                <template v-slot:cell(contact)="data">
                    {{ data.item.contacts && data.item.contacts[0] ? data.item.contacts[0].name : "" }}
                </template>
                <template v-slot:cell(email)="data">
                    {{ data.item.contacts && data.item.contacts[0] ? data.item.contacts[0].email : data.item.email }}
                </template>
                <template v-slot:cell(phone)="data">
                    {{
                         data.item.contacts && data.item.contacts[0] ?
                        (data.item.contacts[0].phone ? "T: " + data.item.contacts[0].phone : "") +
                        (data.item.contacts[0].phone && data.item.contacts[0].mobile ? " - " : "") +
                        (data.item.contacts[0].mobile ? "M: " + data.item.contacts[0].mobile : "") :
                        (data.item.phone ? data.item.phone : "")
                    }}
                </template>
                <template v-slot:cell(renegotiationDate)="data">
                    {{ data.item.renegotiationDate ? $moment(data.item.renegotiationDate).format("DD-MM-YYYY") : "" }}
                </template>
                <template v-slot:cell(status)="data">
                    <b-badge :variant="status(data.item).class" pill class="py-2 px-3" style="font-size: 14px;">{{status(data.item).status}}</b-badge>
                </template>

                <template v-slot:cell(contractStatus)="data">
                    <b-badge :variant="contractStatus(data.item)" pill class="py-2 px-3" style="font-size: 14px;">{{data.value}}</b-badge>
                </template>
                <template v-slot:cell(sponsorship)="data">
                    <div>
                        <template v-if="data.item.underRenegotiation">
                            <span>
                                Under genforhandling
                            </span>
                        </template>
                        <template v-else>
                            <span v-if="data.item.sponsorship > 0">
                                {{$numeral(data.item.sponsorship).format("#,#")}} DKK
                            </span>
                            <span v-else>
                                Bytteaftale
                            </span>
                        </template>
                    </div>
                </template>
                <template v-slot:cell(edit)="data">
                    <div>
                        <!-- <b-button :to="'/customers/' + data.item.id" variant="primary" size="sm">
                <fa icon="edit" /> Rediger
            </b-button> -->
                        <!-- <b-button @click="deleteItem(data.item)" variant="danger" size="sm">
                <fa icon="trash" /> Slet
            </b-button> -->
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import DataTable from "../_ui/dataTable";

export default {
    data: () => ({
        fields: [
            { key: "status", label: "Status", thStyle: "width: 50px" },
            { key: "contractStatus", label: "Aftale status", thStyle: "width: 90px" },
            { key: "owners", label: "Ansvarlig" },
			{ key: "name", label: "Firmanavn", sortable: true },
			{ key: "type", label: "Sponsorkategori", sortable: false },
			{ key: "contact", label: "Kontaktperson" },
			{ key: "email", label: "E-mail" },
			//{ key: "phone", label: "Telefon" },
			//{ key: "sponsorship", label: "Omsætning pr. sæson", sortable: true },
			{ key: "renegotiationDate", label: "Genforhandlingsdato", sortable: true },
			// { key: "edit", label: "", thStyle: "width: 50px", class: "text-right" }
        ],
        statuses: [
            { text: "Alle", value: "" },
            { text: "Lead", value: "Lead" },
            { text: "Kunde", value: "Customer" },
            { text: "Opsagt", value: "Lost" },
        ],
        products: [],
        users: [],
        searchFilter: {}
    }),
    components: { DataTable },
    computed: {
        filters() { 
            return [
                { field: "status", items: this.statuses, value: "Customer", label: "Status" },
                { field: "product", items: this.products, value: "", label: "Produkt" },
                { field: "owner", items: this.users, value: "", label: "Ansvarlig" }
            ];
        }
    },
	methods: {
        status(item) {
            if (item.status) {
                if (item.status == "Lead") {
                    return { status: "Lead", class: "warning" };
                }
                if (item.status == "Customer") {
                    return { status: "Kunde", class: "success" };
                }
                if (item.status == "Lost") {
                    return { status: "Opsagt", class: "danger" };
                }
            }

            return { status: "Ingen", class: "secondary" };
        },
        contractStatus(item) {
            if (item.contractStatus) {
                if (item.contractStatus == "Genforhandling") {
                    return "info";
                }
                if (item.contractStatus == "Faktura sendt") {
                    return "warning";
                }
                if (item.contractStatus == "Betalt") {
                    return "success";
                }
                if (item.contractStatus == "Manglende betaling") {
                    return "danger";
                }
            }

            return "";

        },
        deleteItem(item) {
            if (confirm("Er du sikker du vil slette " + item.name + "?" )) {
                this.$http.post("/api/customers/delete?id=" + item.id).then(x => {
                    this.$refs.dataTable.refresh();
                })
            }
        },
        getProducts() {
            this.$http.get("/api/customers/products").then(x => {
                if (x.data) {
                    this.products = x.data.products.map(e => {
                        return { text: e.name, value: e.id };
                    });

                    this.products.splice(0, 0, { text: "Alle", value: "" })
                }
            });
        },
        getUsers() {
            this.$http.get("/api/customers/users").then(x => {
                if (x.data) {
                    this.users = x.data.map(e => {
                        return { text: e.name, value: e.id };
                    });

                    this.users.splice(0, 0, { text: "Alle", value: "" })
                }
            });
        }
	},
    created() {
        this.getProducts();
        this.getUsers()
    },
    mounted() {
    }
};
</script>
