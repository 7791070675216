<template>
    <div class="esign">
        <div class="container">

            <div class="row">
                <div class="col-12 col-lg-9 document">

                    <iframe class="contract-view" :src="'/api/esign/' + id"></iframe>

                </div>

                <div class="col-12 col-lg-3">
                    <div class="sign">
                        <button class="btn btn-success btn-block btn-lg" v-b-modal.sign-modal :disabled="loading">
                            <fa v-if="!loading" icon="pen-alt" class="mr-1" />
                            <fa v-else icon="sync-alt" spin class="mr-1" />
                            Underskriv aftalen
                        </button>
                    </div>
                </div>

            </div>

        </div>



        <b-modal title="Underskriv aftale" id="sign-modal" @ok="signContract" @show="signature = null" ok-title="Underskriv" cancel-title="Fortryd">
            <div>
                <Signature v-model="signature"></Signature>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import Signature from "../_ui/signature";

    export default {
        data: () => ({
            signature: null,
            loading: false
        }),
        components: { Signature },
        computed: {
            id() {
                return this.$route.params.id;
            }
        },
        methods: {
            signContract() {
                this.loading = true
                this.$http.post("/api/esign/" + this.id, { signature: this.signature }).then(x => {
                    location = x.data
                }).catch(x => {
                    this.loading = false
                });
            }            
        },
        created() {

        },
        mounted() {

        }
    };
</script>

<style lang="scss" scoped>
    .esign {
        background: #f0f0f0;
        color: #323232;
        min-height: 100vh;
        padding: 20px;
    }

    .container {
    }

    .document {
        background: #fff;
        height: 100%;
        box-shadow: 0 0 Min(1.38889vw, .3125rem) 0 rgba(102,102,102,0.8);
        padding: 8px;
    }

    .contract-view {
        width: 100%;
        height: 750px;
        border: 0;
        display: block;
    }

    .sign {
        padding: 20px;
        background: #fff;
        box-shadow: 0 0 Min(1.38889vw, .3125rem) 0 rgba(102,102,102,0.3);
        border-radius: 4px;

        button {
            font-size: 15px
        }
    }

</style>
