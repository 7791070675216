<template>
    <div>
        <b-table striped :items="tickets" :fields="fields">
            <template v-slot:cell(name)="data">
                {{ data.item.name }}
            </template>

            <template v-slot:cell(barcode)="data">
                <b-form-input v-model="data.item.barcode" v-if="data.item.type == 20"></b-form-input>
                <span v-else>{{data.item.barcode}}</span>
            </template>

            <template v-slot:cell(type)="data">
                {{ types[data.item.type] }}
            </template>

            <template v-slot:cell(used)="data">
                {{ data.item.used ? "Ja" : "Nej" }}
            </template>

            <template v-slot:cell(event)="data">
                {{ data.item.event }}
            </template>

            <template v-slot:cell(season)="data">
                <!-- <b-form-input v-model="data.item.season" v-if="data.item.type == 20"></b-form-input>
                <span v-else>{{data.item.season}}</span> -->
                {{data.item.season}}
            </template>

            <template v-slot:cell(edit)="data">
                <b-button @click="saveTicket(data.item)" variant="primary" size="sm" v-if="data.item.type == 20">
                    <fa icon="save" /> Gem
                </b-button>
                <b-button @click="deleteTicket(data.item)" variant="danger" size="sm">
                    <fa icon="trash" /> Slet
                </b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        data: () => ({
            tickets: [],
            fields: [
                {
                    key: "name",
                    label: "Navn",
                    sortable: false
                },
                {
                    key: "barcode",
                    label: "Stregkode",
                    sortable: false
                },
                {
                    key: "type",
                    label: "Type",
                    sortable: false
                },
                {
                    key: "used",
                    label: "Brugt",
                    sortable: false
                },
                {
                    key: "event",
                    label: "Arrangement",
                    sortable: false
                },
                {
                    key: "season",
                    label: "Sæson",
                    sortable: false
                },
                {
                    key: "edit",
                    label: "",
                    sortable: false,
                    class: "text-right"
                }
            ],
            types: {
                10: "Engangsbillet",
                20: "Sæsonkort",
            }
        }),
        props: ["customer"],
        computed: {},
        methods: {
            getTickets() {
                this.$http.get("/api/tickets/" + this.customer.id).then(x => {
                    this.tickets = x.data;
                });
            },
            saveTicket(item) {
                this.$http.post("/api/tickets/update", item).then(x => {
                    this.getTickets();
                });
            },
            deleteTicket(item) {
                this.$http.post("/api/tickets/delete/" + item.id).then(x => {
                    this.getTickets();
                });
            }
        },
        created() {
            this.getTickets();
        },
        mounted() {
        }
    };
</script>
