import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const INIT_STATE = "INIT_STATE";
const AUTH_LOGIN = "AUTH_LOGIN";
const AUTH_LOGOUT = "AUTH_LOGOUT";

// STATE
const state = {
    init: false,
    loggedIn: false
};

// ACTIONS
const actions = {
    [INIT_STATE]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            axios.get("/api/init").then(x => {
                resolve(x.data)
            }).catch(x => {
                resolve(null)
            })
        })
    },
    [AUTH_LOGIN]: ({ commit, dispatch }, loggedIn) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_LOGIN, loggedIn);

            resolve();
        });
    },
    [AUTH_LOGOUT]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_LOGOUT);
            
            resolve();
        });
    }
};

// MUTATIONS
const mutations = {
    [AUTH_LOGIN]: (state, resp) => {
        state.loggedIn = resp;
    },
    [AUTH_LOGOUT]: state => {
        state.loggedIn = false;
    }
};

export default new Vuex.Store({
    state,
    mutations,
    actions
});
