<template>
    <div class="row pt-2">

        <div class="col-12 col-md-4 col-lg-3 ">
            <h6 class="mt-2 pb-2 mb-0">
                Tilgængelige pladser

                <button class="btn btn-sm btn-success float-right" v-b-modal.table-modal>
                    Tilføj bord
                </button>
            </h6>

            <p class="mb-1">
                Antal pladser: {{stats.seats}}
            </p>
            <p class="mb-3">
                Ledige: {{stats.seatsAvailable}}, optaget: {{stats.seated}}
            </p>

            <table class="table table-sm table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th>Bordnavn</th>
                        <th>Pladser</th>
                        <th>Ledige</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in tables" :key="item.id" :class="{ 'table-success': (placed && placed.id == item.id) }">
                        <td>
                            <button class="btn btn-sm btn-info" @click="placed = item" :disabled="placed && placed.id != item.id">
                                Placer
                            </button>
                        </td>
                        <td>{{item.name}}</td>
                        <td>{{item.seats}}</td>
                        <td>{{getTableSeats(item).remaining}}</td>
                        <td class="text-right">
                            <button class="btn btn-sm btn-primary" @click="editTable(item)">
                                <fa icon="pen" />
                            </button>
                            
                            <button class="btn btn-sm btn-danger" @click="deleteTable(item)">
                                <fa icon="times" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div class="col-12 col-md-6">
            <h6 class="mt-2 pb-2 mb-0">
                Deltagerliste

                <!-- <button class="btn btn-sm btn-success float-right" v-b-modal.table-modal>
                    Tilføj bord
                </button> -->
                
                <a :href="'/api/events/' + event.id + '/pdf/tables'" target="_blank" class="btn btn-sm btn-outline-primary float-right">
                    Download bordkort
                </a>

                <a :href="'/api/events/' + event.id + '/pdf/tables-list'" target="_blank" class="btn btn-sm btn-outline-primary float-right mr-2">
                    Download bordoversigt
                </a>
            </h6>

            <p class="mb-1">
                Antal tilmeldte: {{stats.participants}}
            </p>
            <p class="mb-1">
                Placeret: {{stats.seated}}, ikke placeret: {{stats.notPlaced}}
            </p>

            

            <div class="alert alert-success" v-if="placed">

                <h6>Placerede</h6>
                <table class="table table-sm table-striped bg-white">
                    <thead>
                        <tr>
                            <th>Kunde</th>
                            <th>Deltagere</th>
                            <th>Besked</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in placed.customers" :key="item.customerId">
                            <td>{{item.name}}</td>
                            <td>{{item.seated}}</td>
                            <td>{{item.message}}</td>
                            <td class="text-right">
                                <button class="btn btn-sm btn-danger" @click="removeSeating(placed, item)">
                                    <fa icon="times" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                
                <div class="text-center">
                    <button class="btn btn-sm btn-success" @click="saveTable()">
                        Gem bord
                    </button>
                    
                    <button class="btn btn-sm btn-secondary" @click="cancelTable()">
                        Fortryd
                    </button>
                </div>

                <h6>Tilføj</h6>
                <table class="table table-sm table-striped bg-white">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Kunde</th>
                            <th>Deltagere</th>
                            <th>Besked</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in getPlaceableCustomers(placed)" :key="item.id">
                            <td>
                                <button class="btn btn-sm btn-info" @click="placeCustomer(placed, item)">
                                    Placer alle
                                </button>
                                <button class="btn btn-sm btn-info" @click="placeCustomer(placed, item, 1)">
                                    Placer én
                                </button>
                            </td>
                            <td>{{item.customer}}</td>
                            <td class="">{{getCustomerTickets(item)}}</td>
                            <td>{{item.message}}</td>
                        </tr>
                    </tbody>
                </table>

            </div>



            <h6>Deltagere uden placering</h6>

            <table class="table table-sm table-striped">
                <thead>
                    <tr>
                        <th>Kunde</th>
                        <th>Deltagere</th>
                        <th>Besked</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in unplaced" :key="item.id">
                        <td>{{item.customer}}</td>
                        <td class="">{{getCustomerTickets(item)}}</td>
                        <td>{{item.message}}</td>
                    </tr>
                </tbody>
            </table>

        </div>

        <b-modal id="table-modal" @ok="createTable" ok-title="Gem bord" cancel-title="Fortryd" @cancel="getTables">
            <template v-slot:modal-title>
                {{table.id ? "Rediger bord" : "Opret bord"}}
            </template>

            <div v-if="table">
                <b-form-group label="Navn:" class="mb-2">
                    <b-form-input type="text" v-model="table.name" autocomplete="off"></b-form-input>
                </b-form-group>

                <b-form-group label="Beskrivelse:" class="mb-2">
                    <b-textarea type="text" v-model="table.description" autocomplete="off" rows="3"></b-textarea>
                </b-form-group>
                
                <b-form-group label="Pladser:" class="mb-2">
                    <b-form-input type="number" v-model="table.seats" autocomplete="off"></b-form-input>
                </b-form-group>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data: () => ({
        tables: [],
        table: {},
        placed: null
    }),
    props: ["event"],
    computed: {
        unplaced() {
            return this.event.invites.filter(x => {
                var remaining = this.getCustomerTickets(x);
                return remaining > 0;
            });
        },
        stats() {
            var seats = this.tables.reduce((a,x) => a + x.seats, 0);
            var seated = this.tables.flatMap(x => x.customers).reduce((a,x) => a + x.seated, 0);

            var participants = this.event.invites.reduce((a,x) => a + (x.tickets + x.extraTickets + x.seasonTickets), 0);
            var notPlaced = (participants - seated);

            return {
                seats: seats,
                seatsAvailable: (seats - seated),
                seated: seated,
                participants: participants,
                notPlaced: (participants - seated)
            };
        }
    },
    methods: {
        getTables() {
            this.$http.get("/api/events/" + this.event.id + "/tables").then(x => {
                this.tables = x.data;
            });
        },
        createTable() {
            this.$http.post("/api/events/" + this.event.id + "/table", this.table).then(x => {
                this.getTables();
                this.table = {};
            });
        },
        getPlaceableCustomers(table) {
            var remainingSeats = this.getTableSeats(table).remaining;
            if (remainingSeats <= 0) {
                return [];
            }
            return this.unplaced.sort((c, p) => {
                var remainingC = this.getCustomerTickets(c);
                var remainingP = this.getCustomerTickets(p);
                
                return Math.abs(remainingSeats-remainingC) - Math.abs(remainingSeats-remainingP);
            });
        },
        getCustomerSeats(customerId) {
            var tables = this.tables.filter(t => 
                t.customers.find(c => c.customerId == customerId)
            );
            var seated = tables.flatMap(t => t.customers)
                .filter(c => c.customerId == customerId)
                .reduce((s,v) => s + v.seated, 0);
            return seated;
        },
        getCustomerTableSeats(tableId, customerId) {
            var customer = this.tables.find(t => t.id == tableId)
                .customers.find(c => c.customerId == customerId);
            return customer.seats || 0;
        },
        getCustomerTickets(customer) {            
            var seated = this.getCustomerSeats(customer.customerId);
            return (customer.tickets + customer.extraTickets + customer.seasonTickets) - seated;
        },
        getTableSeats(table) {
            var seated = table.customers.reduce((s,v) => s + v.seated, 0);
            return {
                seats: table.seats,
                remaining: (table.seats - seated)
            }
        },
        placeCustomer(table, customer, qty) {
            if (!qty) {
                qty = this.getCustomerTickets(customer);
            }
            if (!table.customers) {
                table.customers = [];
            }

            var seats = this.getTableSeats(table).remaining;
            if (qty > seats) {
                qty = seats;
            }

            var tableCustomer = table.customers.find(x => x.customerId == customer.customerId);

            if (tableCustomer != null) {
                tableCustomer.seated += qty;
            } else {
                table.customers.push({
                    customerId: customer.customerId,
                    name: customer.customer,
                    seated: qty
                });
            }
        },
        removeSeating(table, seating) {
            var idx = table.customers.findIndex(x => x.customerId == seating.customerId);
            if (idx > -1) {
                table.customers.splice(idx, 1);
            }
        },
        saveTable() {
            this.$http.post("/api/events/" + this.event.id + "/table", this.placed).then(x => {
                this.getTables();
                this.placed = null;
            });
        },
        cancelTable() {
            this.getTables();
            this.placed = null;
        },
        deleteTable(table) {
            if (confirm("Er du sikker på at du vil slette " + table.name + "?")) {
                this.$http.delete("/api/events/" + this.event.id + "/table/" + table.id).then(x => {
                    this.getTables();
                });
            }
        },
        editTable(table) {
            this.$bvModal.show("table-modal");

            this.table = table;
        }
    },
    created() {
        this.getTables();
    },
    mounted() {
    }
};
</script>