<template>
    <article>
        <div class="loginNow">
            <div class="filler">
                <v-container>
                    <div class="centerIt">
                        <img class="loginLogo" src="/img/logo.png" />
                        <img class="loginBaxiLogo" src="/img/baxilogo.png" />

                        <div class="floatCard">
                            <h2>Opret ny adgangskode</h2>

                            <v-layout row wrap>
                                <v-flex xs12>
                                    <v-alert color="success" class="mt-3" icon="check_circle" v-model="success.show">
                                        {{success.message}}
                                    </v-alert>

                                    <v-alert color="error" class="mt-3" icon="warning" v-model="error.show">
                                        {{error.message}}
                                    </v-alert>
                                </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                                <v-flex xs12 class="text-xs-center mb-3">
                                    <form v-on:submit.prevent="submitForm" novalidate class="logOn">
                                        <input type="hidden" v-model="form.code" />
                                        <input type="hidden" v-model="form.userId" />

                                        <v-text-field name="password" v-model="form.password"
                                                      placeholder="Adgangskode"
                                                      type="password"
                                                      :error-messages="errors.collect('password')"
                                                      v-validate="'required'"
                                                      data-vv-as="Adgangskode"
                                                      required></v-text-field>

                                        <v-text-field name="confirmPassword" v-model="form.confirmPassword"
                                                      placeholder="Bekræft adgangskode"
                                                      type="password"
                                                      :error-messages="errors.collect('confirmPassword')"
                                                      v-validate="'required|confirmed:password'"
                                                      data-vv-as="Bekræft adgangskode"
                                                      required></v-text-field>

                                        <v-btn class="loginBtn trical2 mt-3" type="submit" :loading="loading" :disabled="loading">Opret ny adgangskode <i class="fa fa-sign-in" aria-hidden="true"></i></v-btn>
                                    </form>
                                </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                                <v-flex xs12 class="text-xs-center pa-3">
                                    <router-link class="mx-3 orange--text text--darken-2" to="/login">Log ind</router-link>
                                </v-flex>
                            </v-layout>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
    </article>
</template>

<script>
    import axios from 'axios';

    export default {
        data: () => ({
            xsrfToken: null,
            form: {},
            loading: false,
            error: {
                show: false,
                message: ''
            },
            success: {
                show: false,
                message: "Din adgangskode er blevet ændret. Du bliver viderestillet om få øjeblikke."
            }
        }),
        components: {},
        computed: {

        },
        methods: {
            submitForm() {
                this.$validator.validateAll().then(result => {
                    if (result === true) {
                        this.loading = true;

                        this.error.show = false;
                        this.success.show = false;

                        axios.post('/api/resetpassword', this.form, { headers: { 'X-XSRF-TOKEN': this.xsrfToken } }).then(x => {
                            if (x.data.error) {
                                this.error.show = true;
                                this.error.message = x.data.error;
                            } else {
                                this.errors.clear();
                                this.form = {};
                                this.success.show = true;

                                setTimeout(() => {
                                    this.$router.push("/login");
                                }, 2000);
                            }

                            this.loading = false;
                        }).catch(err => {
                            this.loading = false;
                        });
                    } else {

                    }
                });
            }
        },
        created() {
            if (this.$route.query) {
                this.form.code = this.$route.query.code;
                this.form.userId = this.$route.query.userId;
            }
        },
        mounted() {
            this.xsrfToken = $('#xsrfToken').val();
        }
    }
</script>