<template>
    <div class="row">
        <div class="col-12 col-sm-4 col-lg-3 mb-3" v-if="enableFilter">
            <b-input-group>
                <b-input-group-text slot="prepend">
                    <fa icon="search" />
                </b-input-group-text>
                <input v-model.lazy="search" type="text" class="form-control" />
            </b-input-group>
        </div>
        <div class="col mb-3">
            <b-form inline>
                <b-input-group v-for="(item, key) in filters" :key="key" :prepend="item.label">
                    <b-form-select @input="setFilter($event, item)" :value="item.value" :options="item.items" class="mr-2" />
                </b-input-group>
            </b-form>
        </div>

        <slot name="create"></slot>

        <div class="col-12">
            <b-table ref="dataTable" :busy.sync="loading" @filtered="onFiltered" :sort-desc="desc" :sort-by="sort"
                     :items="async ? items : dataProvider" :fields="fields" :filter="search"
                     :current-page="currentPage" :per-page="perPage" striped bordered responsive small>
                <template v-for="(item, key) in $scopedSlots" :slot="key" slot-scope="data">
                    <v-render :item="item(data)" :key="key" />
                </template>
            </b-table>
        </div>

        <div class="col-12" v-if="paging">
            <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage" class="mt-3" />
        </div>
    </div>

</template>

<script>
    //fields: [
    //    { label: '', class: 'text-right', key: 'edit', thStyle: 'width: 130px;' },
    //]

    export default {
        data: () => ({
            search: '',
            loading: false,
            currentPage: 1,
            totalItems: 0,
            searchFilter: {}
        }),
        props: {
            cacheSearch: String,
            getUrl: String,
            take: Number,
            filter: String,
            fields: Array,
            items: Array,
            sort: {
                type: String,
                default: "Id"
            },
            desc: {
                type: Boolean,
                default: true
            },
            filters: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        components: {},
        computed: {
            perPage() {
                return this.take ? this.take : 0;
            },
            paging() {
                return this.perPage > 0 ? true : false
            },
            enableFilter() {
                return this.filter ? true : false;
            },
            async() {
                return this.items ? true : false;
            }
        },
        watch: {
            search(val) {
                if (this.cacheSearch && localStorage) {
                    localStorage.setItem(this.cacheSearch, val);
                }
            },
            searchFilter: {
                handler() {
                    this.$refs.dataTable.refresh();
                },
                deep: true
            }
        },
        methods: {
            setFilter(evt, item) {
                this.$set(this.searchFilter, item.field, evt);

                this.$emit('update:searchFilter', this.searchFilter);
            },
            dataProvider(ctx) {
                // this.loading = true                
                let promise = this.$http.get(this.getUrl, {
                    params: {
                        q: ctx.filter,
                        sort: ctx.sortBy,
                        desc: ctx.sortDesc,
                        skip: ctx.currentPage,
                        take: ctx.perPage,
                        filter: this.searchFilter
                    }
                });

                return promise
                    .then(x => {
                        const _items = x.data.items;

                        this.totalItems = x.data.total;
                        // this.loading = false
                        return _items;
                    })
                    .catch(error => {
                        // this.loading = false
                        return [];
                    });
            },
            onFiltered(filteredItems) {
                this.totalItems = filteredItems.length
                this.currentPage = 1;
            },
            refresh() {
                this.$refs.dataTable.refresh();
            }
        },
        created() {
            if (this.filters) {
                this.filters.forEach(x => {
                    if (x.value != "") {
                        this.setFilter(x.value, x);
                    }
                });
            }

            if (this.cacheSearch && localStorage) {
                var q = localStorage.getItem(this.cacheSearch);
                
                this.search = q ? q : "";
            }

        },
        mounted() {

        }
    }
</script>
