<template>
    <div class="row">
        <div class="col-12 pt-3">
            <DataTable ref="dataTable" get-url="/api/users" :fields="fields">
                <template v-slot:cell(accepted)="data">
                    {{data.item.accepted}}
                </template>
                <template v-slot:cell(edit)="data">
                    <div>
                        <b-button @click="editUser(data.item)" variant="primary" size="sm" v-if="userRole == 'Admin' || superAdmin">
                            <fa icon="edit" /> Rediger
                        </b-button>
                        <b-button @click="removeUser(data.item.email)" variant="danger" size="sm" v-if="userRole == 'Admin' || superAdmin">
                            <fa icon="trash" /> Slet
                        </b-button>
                    </div>
                </template>
            </DataTable>
        </div>

        <b-modal v-model="userModal" @ok="saveUser" @cancel="user = { useSignature: false }" ok-title="Gem" cancel-title="Fortryd"
                 hide-header centered no-close-on-backdrop>
            <b-form-group v-if="user" label="Rolle:" :state="!errors.has('role')" :invalid-feedback="errors.first('role')">
                <b-form-select name="role" v-model="user.role" :options="roles"
                               v-validate="'required'" data-vv-as="Rolle"
                               :state="!errors.has('role') && user.role != null">
                    <template slot="first">
                        <option :value="null" disabled>-- Vælg en rolle --</option>
                    </template>
                </b-form-select>
            </b-form-group>

            <b-form-checkbox v-model="user.useSignature" class="mb-2">
                Brug informationer i e-mails
            </b-form-checkbox>

            <b-form-group label="Kontaktperson:" class="mb-2">
                <b-form-input type="text" v-model="user.contact"></b-form-input>
            </b-form-group>

            <b-form-group label="Titel:" class="mb-2">
                <b-form-input type="text" v-model="user.title"></b-form-input>
            </b-form-group>


            <b-form-group label="Klub e-mail:" class="mb-2">
                <b-form-input type="text" v-model="user.clubEmail"></b-form-input>
            </b-form-group>

            <b-form-group label="Telefon:" class="mb-2">
                <b-form-input type="text" v-model="user.phone"></b-form-input>
            </b-form-group>

            <b-form-group label="Afsender navn:" class="mb-2">
                <b-form-input type="text" v-model="user.emailFrom"></b-form-input>
            </b-form-group>

            <b-form-group label="Kontrakt emne:" class="mb-2">
                <b-form-input type="text" v-model="user.contractEmailSubject"></b-form-input>
            </b-form-group>

            <b-form-group label="Profilbillede URL:" class="mb-2">
                <b-form-input type="text" v-model="user.avatar"></b-form-input>
            </b-form-group>

            <Signature v-model="user.signature"></Signature>

        </b-modal>

    </div>
</template>

<script>
    import DataTable from "../_ui/dataTable";
    import Signature from "../_ui/signature";

    export default {
        data: () => ({
            fields: [
                { key: "email", label: "E-mail" },
                { key: "role", label: "Rolle", thStyle: "width: 200px" },
                { key: "accepted", label: "Accepteret", thStyle: "width: 200px" },
                { key: "edit", label: "", thStyle: "width: 180px", class: "text-right" }
            ],
            userModal: false,
            user: {
                useSignature: false
            },
            roles: [
                { value: 'Lead', text: 'Lead' },
                { value: 'Seller', text: 'Sælger' },
                { value: 'Accountant', text: 'Bogholder' },
                { value: 'Admin', text: 'Admin' }
            ]
        }),
        components: { DataTable, Signature },
        computed: {
            superAdmin() {
                return this.$store.state.superAdmin;
            },
            userRole() {
                return this.$store.state.role;
            },
        },
        methods: {
            removeUser(email) {
                if (confirm("Er du sikker på at du vil fjerne denne bruger?")) {
                    this.$http.post("/api/users/delete?email=" + email).then(x => {
                        this.$refs.dataTable.refresh();
                    });
                }
            },
            editUser(item) {
                this.user = {
                    id: item.user.id,
                    role: item.role,
                    useSignature: item.useSignature,
                    contact: item.contact,
                    title: item.title,
                    phone: item.phone,
                    emailFrom: item.emailFrom,
                    contractEmailSubject: item.contractEmailSubject,
                    signature: item.signature
                };
                this.userModal = true;
            },
            saveUser() {
                this.$validator.validate().then(x => {
                    if (x === true) {
                        this.$http.post("/api/users/edit", this.user).then(x => {
                            this.$refs.dataTable.refresh();
                        });
                    }
                });
            }
        },
        created() {

        },
        mounted() {
        }
    };
</script>
