<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 pt-3 contract-container" v-if="contract">
                <div class="row">
                    <div class="col-12 col-md-3 col-lg-2 offset-lg-1 order-md-last">
                        <template v-if="contract.status == 'Pending'">
                            <b-button @click="confirmContract" variant="success" block>
                                <fa icon="check" class="mr-2" /> Godkend aftale</b-button>
                        </template>

                        <template v-if="contract.status != 'Cancelled'">
                            <b-button variant="secondary" block :href="'/contract/pdf/' + id" target="_blank">
                                <fa icon="file-invoice" class="mr-2" /> Download aftale</b-button>
                        </template>

                        <template v-if="contract.status == 'Pending'">
                            <b-button variant="danger" block @click="cancelContract">
                                <fa icon="ban" class="mr-2" /> Annuller aftale</b-button>
                        </template>
                    </div>
                    <div class="col-12 col-md-9 col-lg-6 offset-lg-2 order-md-first py-2 text-center" v-if="status">
                        <b-badge :variant="status.class" pill class="py-2 px-3" style="font-size: 14px;">{{status.status}}</b-badge>
                    </div>
                    <div class="col-12 col-md-9 col-lg-6 offset-lg-2 order-md-first" v-if="contract.status && contract.status != 'Draft'">
                        <div class="contract-page">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <strong>
                                        {{contract.customer}}
                                    </strong>
                                    <p v-html="contract.customerAddress"></p>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="row">
                                        <div class="col-12 col-md-8 offset-md-4 text-right">
                                            <p>Dato: <strong>{{$moment(contract.date).format('DD-MM-YYYY')}}</strong></p>
                                            <p>
                                                Løbetid: 
                                                <strong v-if="contract.durationFrom">{{$moment(contract.durationFrom).format('DD-MM-YYYY')}}</strong>
                                                til
                                                <strong v-if="contract.durationTo">{{$moment(contract.durationTo).format('DD-MM-YYYY')}}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-3">
                                    <p v-html="contract.comment"></p>
                                </div>
                                <div class="col-12 pb-3">
                                    <h3>{{contract.title}}</h3>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm table-striped ">
                                        <tr>
                                            <th style="width: 45%">Produkt</th>
                                            <th>Antal</th>
                                            <th class="text-right">Stk. pris</th>
                                            <th class="text-right">Stk. rabat</th>
                                            <th class="text-right">Pris</th>
                                        </tr>
                                        <tr v-for="(item, key) in contract.items" :key="key">
                                            <td>
                                                {{item.name}}
                                                <small><br />{{item.description}}</small>
                                            </td>
                                            <td>
                                                {{item.quantity}}
                                            </td>
                                            <td class="align-middle text-right">
                                                {{$numeral(item.price).format('$ 0,0.00')}}
                                            </td>
                                            <td class="align-middle text-right">
                                                {{$numeral(item.discount).format('$ 0,0.00')}}
                                            </td>
                                            <td class="align-middle text-right">
                                                <strong>{{$numeral(item.quantity * (item.price - item.discount)).format('$ 0,0.00')}}</strong>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 offset-md-8">
                                    <table class="table table-sm mt-3">
                                        <tr>
                                            <td>Subtotal</td>
                                            <td class="text-right">{{$numeral(contract.subtotal).format('$ 0,0.00')}}</td>
                                        </tr>
                                        <tr>
                                            <td>Moms</td>
                                            <td class="text-right">{{$numeral(contract.vatAmount).format('$ 0,0.00')}}</td>
                                        </tr>
                                        <tr>
                                            <th>Total</th>
                                            <th class="text-right">{{$numeral(contract.total).format('$ 0,0.00')}}</th>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	data: () => ({
		contract: null,
	}),
	components: {},
	computed: {
        id() {
            return this.$route.params.id;
        },
        totals() {
            if (this.settings) {
                var subtotal = 0;
                for (let index = 0; index < this.contract.items.length; index++) {
                    const element = this.contract.items[index];
                    subtotal += (element.price - element.discount) * element.quantity;
                }
                var vatRate = (this.settings.vatRate + 100) / 100;
                var vatAmount = (subtotal * vatRate) - subtotal;
                var total = subtotal + vatAmount;

                return {
                    subtotal: subtotal,
                    vatAmount: vatAmount,
                    total: total
                };
            }

            return null;
        },
        status() {
            if (this.contract.status) {
                if (this.contract.status == "Accepted") {
                    return { status: "Godkendt", class: "success" };
                }
                if (this.contract.status == "CustomerAccepted") {
                    return { status: "Godkendt af kunden", class: "success" };
                }
                if (this.contract.status == "Draft") {
                    return { status: "Kladde", class: "warning" };
                }
                if (this.contract.status == "Pending") {
                    return { status: "Afventer godkendelse", class: "warning" };
                }
                if (this.contract.status == "Cancelled") {
                    return { status: "Opsagt", class: "danger" };
                }
            }

            return null;
        },
    },
	methods: {
        getContract() {
            this.$http.get("/api/contract/" + this.id).then(x => {
                if (x.data) {
                    this.contract = x.data;
                }
            });
        },
        confirmContract() {
            if (confirm("Er du sikker på du vil godkende denne aftale?")) {
                this.$http.post("/api/contract/approve/" + this.id).then(x => {
                    this.getContract();
                });
            }
        },
        cancelContract() {
            if (confirm("Er du sikker på du vil annullere denne aftale?")) {
                this.$http.post("/api/contract/cancel/" + this.id).then(x => {
                    this.getContract();
                });
            }
        }
	},
	created() {
        this.getContract();
	},
	mounted() {

    }
};
</script>
