<template>
    <div class="row">
        <div class="col-12 pt-3">
            <h3 class="" v-if="!form.id">Opret kampagne</h3>
            <h3 class="" v-else>Rediger kampagne</h3>
        </div>

        <div class="col-12 col-md-6">
            <div class="row">

                <div class="col-12 col-lg-6 ">

                    <b-form-group label="Navn:" :state="fieldState('name')" :invalid-feedback="errors.first('name')">
                        <b-form-input type="text" v-model="form.name" name="name"
                                      v-validate="'required'" data-vv-as="Navn"
                                      :state="fieldState('name')"></b-form-input>
                    </b-form-group>

                    <b-form-checkbox v-model="form.isOffer">
                        Tilbud
                    </b-form-checkbox>

                    <b-form-group label="Brugbare sæsonkort:" class="mb-2" v-if="form.isOffer">
                        <v-select :options="seasonProducts" multiple label="name"
                                  :value="seasonProducts.filter(x => form.seasonProducts.includes(x.id))"
                                  @input="x => form.seasonProducts = x.map(e => e.id)"></v-select>
                    </b-form-group>

                    <b-form-group label="Brugbare gæstekort:" class="mb-2" v-if="form.isOffer">
                        <v-select :options="ticketProducts" multiple label="name"
                                  :value="ticketProducts.filter(x => form.ticketProducts.includes(x.id))"
                                  @input="x => form.ticketProducts = x.map(e => e.id)"></v-select>
                    </b-form-group>


                    <b-input-group prepend="Dato" class="mb-2 mt-15">
                        <v-date-picker mode="single" popover-visibility="focus" autocomplete="off"
                                       :value="$moment(form.date).toDate()"
                                       @input="form.date = $moment($event).utc().format()"
                                       title-transition="none" weeks-transition="none" class="form-control"
                                       :input-props="{ class: 'form-control', readOnly: true }">
                        </v-date-picker>
                    </b-input-group>

                    <b-form-group label="Tidspunkt:">
                        <div class="row">
                            <div class="col-lg-6">
                                <b-input-group prepend="Time">
                                    <b-form-input type="number" v-model="form.hour"></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="col-lg-6">
                                <b-input-group prepend="Minut">
                                    <b-form-input type="number" v-model="form.minutes"></b-form-input>
                                </b-input-group>
                            </div>
                        </div>
                    </b-form-group>


                    <b-input-group prepend="Tilmeldingsfrist" class="mb-2" v-if="form.isOffer">
                        <v-date-picker mode="single" popover-visibility="focus" autocomplete="off"
                                       :value="$moment(form.registrationEnd).toDate()"
                                       @input="form.registrationEnd = $moment($event).utc().format()"
                                       title-transition="none" weeks-transition="none" class="form-control"
                                       :input-props="{ class: 'form-control', readOnly: true }">
                        </v-date-picker>
                    </b-input-group>


                    <b-form-group label="Tilmeldingsfrist tidspunkt:" v-if="form.isOffer">
                        <div class="row">
                            <div class="col-lg-6">
                                <b-input-group prepend="Time">
                                    <b-form-input type="number" v-model="form.endHour"></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="col-lg-6">
                                <b-input-group prepend="Minut">
                                    <b-form-input type="number" v-model="form.endMinutes"></b-form-input>
                                </b-input-group>
                            </div>
                        </div>
                    </b-form-group>


                    <b-form-group label="Total antal tilbud:" v-if="form.isOffer">
                        <b-form-input type="number" v-model="form.totalOffers"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Maks. antal tilbud pr. sponsor:" v-if="form.isOffer">
                        <b-form-input type="number" v-model="form.maxOffers"></b-form-input>
                    </b-form-group>


                    <b-form-group label="Pris:" v-if="form.isOffer">
                        <b-form-input type="number" v-model="form.price"></b-form-input>
                    </b-form-group>

                    <b-form-checkbox v-model="form.receipt" v-if="form.isOffer">
                        Send kvittering
                    </b-form-checkbox>


                    <b-form-group label="E-mail notifikation interval:" class="mt-2" v-if="form.isOffer">
                        <v-select :options="intervals" multiple placeholder="Ingen notifikation" label="text"
                                  :value="intervals.filter(x => form.emailInterval.includes(x.value))"
                                  @input="x => form.emailInterval = x.map(e => e.value)"></v-select>
                    </b-form-group>

                    <b-form-group label="SMS notifikation interval:" v-if="false && form.isOffer">
                        <v-select :options="intervals" multiple placeholder="Ingen notifikation" label="text"
                                  :value="intervals.filter(x => form.smsInterval.includes(x.value))"
                                  @input="x => form.smsInterval = x.map(e => e.value)"></v-select>
                    </b-form-group>

                </div>

                <div class="col-12 col-lg-6">
                    <b-form-group label="Email emnefelt" class="mb-3">
                        <b-form-input type="text" v-model="form.subject"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Overskrift">
                        <b-form-input type="text" v-model="form.title"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Beskrivelse:">
                        <editor v-model="form.description"></editor>
                    </b-form-group>

                    <div class="row">
                        <div class="col-12 col-md-6" v-if="!creating">
                            <b-form-group label="Billede:">
                                <b-form-file ref="file" placeholder="Upload billede..." @input.native="uploadImage($event, 'Image')"></b-form-file>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6" v-if="!creating">
                            <img :src="form.image" v-if="form.image" style="max-width: 100%;" class="mb-2" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6" v-if="!creating">
                            <b-form-group label="Billede bund:">
                                <b-form-file ref="file" placeholder="Upload billede..." @input.native="uploadImage($event, 'ImageBottom')"></b-form-file>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6" v-if="!creating">
                            <img :src="form.imageBottom" v-if="form.imageBottom" style="max-width: 100%;" class="mb-2" />
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="col-12 col-md-6 col-lg-5">

            <table ref="invites" class="table table-striped" v-if="!creating">
                <thead>
                    <tr>
                        <th class="py-2 align-middle">
                            Kunde<br>
                            <span style="font-weight: normal;">Total: {{stats.total}}</span>
                        </th>
                        <th class="py-2 align-middle">
                            Tilmeldinger<br>
                            <span style="font-weight: normal;">Stk: {{stats.extraTickets}}</span>
                        </th>
                        <th class="py-2 align-middle">Tilmelding</th>
                        <th class="text-right py-2 align-middle">
                            <b-button size="sm" variant="success" v-b-modal.invite-modal>
                                Opret invitationer
                            </b-button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in form.invites" :key="'i-' + key">
                        <td>{{item.customer}}</td>
                        <td>{{item.extraTickets}}</td>
                        <td>
                            <a :href="form.pageUrl + '?token=' + item.token" target="_blank">Tilmeld</a>
                        </td>
                        <td class="text-right">
                            <b-button @click="deleteInvite(item)" variant="danger" size="sm" class="px-2 py-0"><fa icon="times" /></b-button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>



        <div class="col-12 action-footer">
            <b-button type="button" variant="primary" v-if="!form.id" @click="onSubmit">
                <fa icon="plus" /> Opret kampagne
            </b-button>
            <b-button type="button" variant="primary" v-else @click="onSubmit">
                <fa icon="save" /> Gem kampagne
            </b-button>

            <b-button type="reset" variant="danger" @click="onReset">
                <fa icon="times" /> Fortryd
            </b-button>


            <b-button type="button" variant="success" v-if="!creating" :href="'/offers/print/' + form.id" target="_blank">
                <fa icon="print" /> Print tilmeldingsliste
            </b-button>


            <b-button type="button" variant="info" v-if="form.id" @click="sendReminder">
                <fa icon="envelope" /> Send påmindelse
            </b-button>


            <b-button type="button" variant="secondary" v-b-modal.test-modal>
                <fa icon="envelope" /> Send test
            </b-button>
        </div>


        <b-modal title="Opret invitationer" id="invite-modal" @ok="createInvites" @show="invite = {}" @close="showManualCard = false" ok-title="Send invitationer" cancel-title="Fortryd">
            <div v-if="invite">
                <b-form-group label="Produkt:" class="mb-2">
                    <v-select :options="inviteProducts" v-model="invite.productIds"
                              multiple label="name" :reduce="x => x.id"></v-select>
                </b-form-group>
            </div>
        </b-modal>

        <b-modal title="Send test invitation" id="test-modal" @ok="sendTestInvite" @show="invite = { email: $store.state.user }" ok-title="Send test" cancel-title="Fortryd">
            <div v-if="invite">
                <b-form-group label="E-mail:" class="mb-2">
                    <b-form-input type="text" v-model="invite.email"></b-form-input>
                </b-form-group>
            </div>
        </b-modal>


        <v-toast ref="toast"></v-toast>
    </div>
</template>

<script>
export default {
	data: () => ({
		form: {
            id: null,
            name: null,
            description: null,
            pageUrl: null,
            date: null,
            hour: 0,
            minutes: 0,
            registrationEnd: null,
            endHour: 0,
            endMinutes: 0,
            invites: [],
            totalTickets: 0,
            maxTickets: 0,
            showAttendees: false,
            openEvent: false,
            additionalTickets: false,
            ticketPrice: 350,
            emailInterval: [],
            smsInterval: [1],
            seasonProducts: [],
            ticketProducts: [],
            foodSponsorLink: null,
            foodSponsorLogo: null,
        },
        invite: null,
        ticketTypes: [
            { value: 0, text: "Ikke billet" },
            { value: 10, text: "Engangsbillet" },
            { value: 20, text: "Sæsonbillet" },
        ],
        intervals: [
            { value: 1, text: "1 dag før" },
            { value: 3, text: "3 dage før" },
            { value: 5, text: "5 dage før" }
        ],
        inviteProducts: [],
        seasonProducts: [],
        ticketProducts: [],
        scan: null,
        manualScan: { customerId: null },
        showManualCard: false,
        manualCard: null,
        toast: null,
        scanCounter: null
    }),
    components: {  },
    computed: {
        creating() {
            return this.$route.params.id == "new";
        },
        products() {
            var result = [...this.form.seasonProducts, ...this.form.ticketProducts];

            return result;
        },
        stats() {
            var seasonTickets = this.form.invites.reduce((a, x) => a + x.seasonTickets, 0);
            var tickets = this.form.invites.reduce((a, x) => a + x.tickets, 0);
            var extraTickets = this.form.invites.reduce((a, x) => a + x.extraTickets, 0);

            return {
                seasonTickets: seasonTickets,
                tickets: tickets,
                extraTickets: extraTickets,
                total: seasonTickets + tickets + extraTickets
            };
        }
    },
	methods: {
        getEvent() {
            var id = this.$route.params.id;
            this.$http.get("/api/offers/" + id).then(x => {
                if (x.data) {
                    this.form = x.data;

                    this.form.emailInterval = x.data.emailInterval;
                    this.$set(this.form, "emailInterval", x.data.emailInterval);
                    
                }
            });
        },
        getProducts() {
            this.$http.get("/api/offers/products").then(x => {
                this.inviteProducts = x.data.inviteProducts;
                this.seasonProducts = x.data.seasonProducts;
                this.ticketProducts = x.data.ticketProducts;
            });
        },
        getProduct(productId) {
            var product = this.seasonProducts.find(x => x.id == productId);
            if (product) {
                return product;
            }
            product = this.ticketProducts.find(x => x.id == productId);
            if (product) {
                return product;
            }
            product = this.inviteProducts.find(x => x.id == productId);
            if (product) {
                return product;
            }
            return { name: "Ukendt produkt" };
        },
        createInvites(evt) {
            if (confirm("Er du sikker på du vil sende nye oprettede invitationer? ")) {
                var id = this.$route.params.id;
                this.$http.post("/api/offers/invites/" + id, this.invite).then(x => {
                    this.getEvent();
                    this.invite = null;
                });
            } else {
                evt.preventDefault();
            }
        },
        deleteInvite(item) {
            if (confirm("Er du sikker på at du vil slette denne invitation?")) {
                var id = this.$route.params.id;
                this.$http.post("/api/offers/invites/delete/" + id + "/" + item.token).then(x => {
                    this.getEvent();
                });
            }
        },

        sendTestInvite(evt) {
            if (confirm("Husk at gemme før du sender test!")) {
                var id = this.$route.params.id;
                this.$http.post("/api/offers/test-invite/" + id, this.invite).then(x => {
                    this.getEvent();
                    this.invite = null;
                });
            } else {
                evt.preventDefault();
            }
        },

        focusBarcode(e) {
            this.$refs.barcode.focus();
        },
        barcodeScan(e) {
            var id = this.$route.params.id;
            this.$http.post("/api/offers/" + id + "/scan?barcode=" + this.scan.barcode).then(x => {
                if (!x.data.success) {
                    this.manualCard = {
                        customerId: null,
                        barcode: this.scan.barcode,
                        eventId: id
                    };
                }

                this.scan.barcode = null;
                this.scan.result = x.data;

                this.$refs.barcode.focus();

                this.getScanCount();
            });
        },
        createManualCard() {
            var id = this.$route.params.id;

            this.$http.post("/api/offers/" + id + "/manual-card", this.manualCard).then(x => {
                this.scan.barcode = null;
                this.scan.result = x.data;
                this.showManualCard = false;
                this.manualCard = null;

                setTimeout(() => {
                    this.$refs.barcode.focus();
                }, 200);

                this.getScanCount();
            });
        },

        getScanCount() {
             var id = this.$route.params.id;
             this.$http.get("/api/offers/" + id + "/scanned-tickets").then(x => {
                 this.scanCounter = x.data;
             });
        },

		onSubmit() {
			this.$validator.validate().then(x => {
				if (x === true) {
					this.$http.post("/api/offers/update", this.form).then(x => {
                        this.toast.showToast('Ændringerne blev gemt!', { theme: "success" });

                        if (this.creating) {
                            this.$router.push('/campaigns/' + x.data);
                            this.getEvent();
                            this.getProducts();
                        } else {
                            this.getEvent();
                        }
					});
				}
			});
		},
		onReset() {
			this.$router.push('/campaigns');
        },
        
        
        uploadImage(e, field) {
            var formData = new FormData();
            formData.append("file", e.target.files[0]);

            this.$http.post("/api/offers/" + this.form.id + "/upload?field=" + field, formData).then(x => {
                if (x.data === true) {
                    this.toast.showToast('Filen blev uploadet', { theme: "success" });

                    this.getEvent();

                    evt.target.value = "";
                }
			});
        },

        sendReminder() {
            if (confirm("Er du sikker på at du vil sende en påmindelse?")) {
                this.$http.post("/api/offers/email/push?id=" + this.form.id).then(x => {
                    if (x.data === true) {
                        this.toast.showToast('Påmindelse sendt', { theme: "success" });
                    }
                });
            }
        },
    },
    created() {
        this.getProducts();
        
        if (this.$route.params.id && this.$route.params.id != "new") {
            this.getEvent();
        }
    },
	mounted() {
        this.toast = this.$refs.toast;
        this.toast.setOptions({ position: "bottom right" });
    }
};
</script>
