<template>
    <div class="login-bg" style="background-image:url(/images/login-bg.jpg);">
        <div class="overlay" style="background-image:url(/images/effekt.png);"></div>
        <div class="login-box">
            <div class="logo-login">
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 1096.84364 178.46582">
                    <g>
                        <g>
                            <g>
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M13.51219,79.8208l3.24941-12.127A31.74043,31.74043,0,0,1,47.96034,43.80176H97.55409L94.11065,56.65283A2.81379,2.81379,0,0,1,91.58639,58.644h-47.603c-5.97314,0-9.82627,2.896-11.42664,8.86866l-1.40647,5.249a1.67794,1.67794,0,0,0,1.09585,1.99121L84.63443,87.96582c2.75051.543,4.06268,3.07715,3.2867,5.97314l-4.31636,16.10889c-4.21941,15.74707-17.721,24.25391-32.38211,24.25391H0l3.44343-12.85108A2.81317,2.81317,0,0,1,5.96757,119.46H55.1995c5.97315,0,12.14364-3.439,13.74414-9.41211L71.223,101.541a1.40793,1.40793,0,0,0-1.1439-1.81006L16.97956,85.794A4.54924,4.54924,0,0,1,13.51219,79.8208Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M153.12451,134.30176H132.30957c-5.61084,0-9.36025-2.896-10.75615-5.792l-12.85214,47.96484a2.8138,2.8138,0,0,1-2.52426,1.99121H92.42067L127.00025,49.41309a7.934,7.934,0,0,1,7.29554-5.61133h43.07812c14.48,0,24.38882,7.602,19.58745,25.521l-10.57274,39.458C182.3147,123.98486,167.60449,134.30176,153.12451,134.30176Zm28.09027-64.979c2.23086-8.32568-2.02585-10.67871-7.81784-10.67871H142.2651a2.81375,2.81375,0,0,0-2.52462,1.99073l-13.2402,49.41308c-.89917,4.70606,1.654,8.688,6.21456,9.231H157.1499c5.792,0,11.94018-4.70606,13.54068-10.6792Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M231.51742,135.56885c-14.66065,0-23.27332-11.76514-19.34489-26.42627l10.76664-40.18164c3.8799-14.48,18.846-26.42627,33.50668-26.42627h23.89209c14.66113,0,23.40594,11.94629,19.526,26.42627l-10.76664,40.18164c-3.92844,14.66113-19.0267,26.42627-33.68783,26.42627Zm7.36285-67.332L227.72558,109.8667c-1.6005,5.97314,1.79616,10.85986,7.76882,10.85986h23.89209c5.97314,0,12.16925-4.88672,13.76975-10.85986l11.15469-41.62988c1.552-5.792-2.02523-10.6792-7.99838-10.6792H252.42046C246.44781,57.55762,240.43223,62.44482,238.88027,68.23682Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M382.08094,43.80176,378.589,56.834a2.81377,2.81377,0,0,1-2.52462,1.99073h-22.625c-4.88721,0-9.34472,3.80127-10.87044,8.145l-18.04157,67.332H310.77148a1.48647,1.48647,0,0,1-1.45766-1.99121l23.71586-88.50879h13.75585a1.38279,1.38279,0,0,1,1.50621,1.81006l-1.067,3.98193a20.20574,20.20574,0,0,1,13.86006-5.792Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M427.20731,121.26953l-3.492,13.03223H405.43408c-14.48,0-23.27332-11.76514-19.345-26.42578L414.99435,0h13.75586a1.48636,1.48636,0,0,1,1.4578,1.99072l-12.1247,45.25a7.73826,7.73826,0,0,1,6.122-3.25781h19.186l-3.49184,13.03174a2.582,2.582,0,0,1-2.47622,1.81006H414.97939l-13.33715,49.7749c-1.6005,5.97314,1.84457,10.6792,7.81723,10.6792h16.29A1.48636,1.48636,0,0,1,427.20731,121.26953Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M512.65337,100.27393l-51.03982-20.272c-2.47227-.90527-3.82025-3.98193-3.04427-6.87793l2.425-9.05029c3.34636-12.48877,15.38694-20.272,27.8762-20.272h41.62988l-1.455,5.43017a2.81377,2.81377,0,0,1-2.52463,1.99073H486.88207c-7.96435,0-15.65738,5.06836-17.79129,13.03222l-1.7944,6.69678a2.21484,2.21484,0,0,0,1.131,2.53418l50.678,20.272c2.47289.90479,3.82025,3.98194,3.04427,6.87793l-3.68587,13.75586c-3.3949,12.66992-15.47114,19.91016-27.77876,19.91016h-48.689l1.50342-5.61084a2.81381,2.81381,0,0,1,2.52427-1.99121H492.722c7.78272,0,15.82455-4.34375,17.91005-12.127l3.15246-11.76513A2.064,2.064,0,0,0,512.65337,100.27393Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M629.51349,66.06494l-12.36714,46.15479c-3.39489,12.66992-15.87194,22.082-28.36071,22.082H553.49023c-2.53369,0-4.31751-1.44824-4.1464-3.439l-12.22165,45.61182a2.8138,2.8138,0,0,1-2.52426,1.99121h-6.1543L563.9932,45.793a2.81441,2.81441,0,0,1,2.52476-1.99121h46.51709C625.52382,43.80176,633.00547,53.03271,629.51349,66.06494Zm-8.04794-.3623c2.13391-7.96387-2.45506-14.48-10.41893-14.48H570.68334l-14.84069,55.38623-4.5588,17.01367a2.51231,2.51231,0,0,0,2.25263,3.07715h37.28613c7.96387,0,16.04481-6.51612,18.17873-14.48Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M659.21664,135.56885c-12.48877,0-19.72786-10.13623-16.3815-22.625l12.75519-47.603c3.34636-12.48877,16.066-22.80615,28.55474-22.80615h27.33105c12.30811,0,19.49865,10.31738,16.1523,22.80615l-12.75519,47.603c-3.34636,12.48877-16.01743,22.625-28.32554,22.625Zm22.89147-85.43213c-7.96387,0-16.04482,6.51611-18.17873,14.48L650.78613,113.668c-2.13391,7.96387,2.5036,14.29883,10.46747,14.29883h27.33105c7.78272,0,15.81562-6.335,17.94953-14.29883L719.67743,64.6167c2.13391-7.96387-2.45555-14.48-10.23827-14.48Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M818.03147,65.70264c2.13391-7.96387-2.63621-14.48-10.41942-14.48H767.97338a2.81439,2.81439,0,0,0-2.52475,1.99121l-21.1939,79.09668a2.81327,2.81327,0,0,1-2.52426,1.99121h-6.1543l22.11549-82.53613c1.16391-4.34375,5.80244-8.14454,10.14619-8.14454h41.811c12.30811,0,19.67994,10.3169,16.33358,22.80567l-18.187,67.875H801.46a1.464,1.464,0,0,1-1.276-1.99121Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M902.70416,100.27393l-51.03983-20.272c-2.47227-.90527-3.82025-3.98193-3.04427-6.87793l2.425-9.05029c3.34636-12.48877,15.38694-20.272,27.8762-20.272h41.62988l-1.455,5.43017a2.81377,2.81377,0,0,1-2.52462,1.99073H876.93285c-7.96435,0-15.65738,5.06836-17.79129,13.03222l-1.79439,6.69678a2.21482,2.21482,0,0,0,1.131,2.53418l50.678,20.272c2.47289.90479,3.82025,3.98194,3.04427,6.87793L908.5146,114.3916c-3.3949,12.66992-15.47114,19.91016-27.77876,19.91016h-48.689l1.50342-5.61084a2.81379,2.81379,0,0,1,2.52426-1.99121H882.7728c7.78272,0,15.82455-4.34375,17.91006-12.127l3.15245-11.76513A2.064,2.064,0,0,0,902.70416,100.27393Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M951.52767,135.56885c-12.48877,0-19.72785-10.13623-16.3815-22.625l12.7552-47.603c3.34635-12.48877,16.066-22.80615,28.55474-22.80615h27.33105c12.30811,0,19.49816,10.31738,16.15181,22.80615l-12.7552,47.603c-3.34635,12.48877-16.01694,22.625-28.325,22.625Zm22.89147-85.43213c-7.96387,0-16.04481,6.51611-18.17873,14.48L943.09717,113.668c-2.13392,7.96387,2.5036,14.29883,10.46747,14.29883h27.331c7.7832,0,15.81561-6.335,17.94952-14.29883l13.14325-49.05127c2.13391-7.96387-2.45506-14.48-10.23826-14.48Z" />
                                <path class="a09c4918-a87c-496a-a695-93d310e85b5c"
                                      d="M1060.28163,43.80176l-1.6005,5.97314s5.22062-5.97314,17.52872-5.97314h20.63379l-1.50355,5.61133a2.58133,2.58133,0,0,1-2.4756,1.80957h-22.26318c-12.66992,0-15.80008,13.03222-15.80008,13.03222l-18.769,70.04688h-8.145l24.2494-90.5Z" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <b-form @submit.prevent="onSubmit"
                    novalidate>
                <b-form-group label="E-mail:"
                              :state="!errors.has('email')"
                              :invalid-feedback="errors.first('email')">
                    <b-form-input placeholder="E-mailadresse"
                                  type="email"
                                  v-model="form.username"
                                  name="email"
                                  v-validate="'required|email'"
                                  data-vv-as="E-mail"
                                  :state="!errors.has('email')">
                    </b-form-input>
                    <p v-if="errors.length">
                        <b>Please correct the following error(s):</b>
                    </p>
                </b-form-group>
                <b-form-group label="Adgangskode:"
                              :state="!errors.has('password')"
                              :invalid-feedback="errors.first('password')">
                    <b-form-input placeholder="Adgangskode"
                                  type="password"
                                  v-model="form.password"
                                  name="password"
                                  v-validate="'required'"
                                  data-vv-as="Adgangskode"
                                  :state="!errors.has('password')">
                    </b-form-input>
                </b-form-group>

                <b-btn type="submit"
                       variant="primary">Log ind</b-btn>
                <!-- <b-btn
                  to="/register"
                  variant="secondary"
                >Opret bruger</b-btn> -->
            </b-form>
        </div>
    </div>
</template>

<script>
    import "../../css/login.scss";

    export default {
        data: () => ({
            form: {
                username: null,
                password: null
            }
        }),
        methods: {
            onSubmit() {
                this.$validator.validate().then(x => {
                    if (x === true) {
                        this.$http.post("/api/login", this.form).then(x => {
                            if (x.data === true) {
                                this.$store.dispatch("AUTH_LOGIN", x.data).then(c => {
                                    if (this.$route.query && this.$route.query.ReturnUrl != null) {
                                        window.location = this.$route.query.ReturnUrl;
                                    } else {
                                        window.location = "/";
                                    }
                                });
                            }
                        });
                    }
                });
            }
        },
        mounted() { }
    };
</script>