<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-4 ">
                <h2>Opret bruger</h2>

                <b-form @submit.prevent="onSubmit" @reset="onReset" novalidate>
                    <b-form-group label="Navn:">
                        <b-form-input type="text" v-model="form.name">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group label="E-mail:">
                        <b-form-input type="email" v-model="form.email">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group label="Adgangskode:">
                        <b-form-input type="password" v-model="form.password">
                        </b-form-input>
                    </b-form-group>

                    <b-btn type="submit" variant="primary">Opret bruger</b-btn>
					<b-btn type="reset" variant="secondary">Fortryd</b-btn>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            form: {
                name: null,
                email: null,
                password: null,
                token: null
            }
        }),
        components: {},
        methods: {
            onSubmit() {
                this.$validator.validate().then(x => {
                    if (x === true) {
                        this.form.token = this.$route.query.token;

                        this.$http.post("/api/register", this.form).then(x => {
                            if (this.$route.query.ReturnUrl != null) {
                                window.location = this.$route.query.ReturnUrl;
                            } else {
                                window.location = "/";
                            }
                        });
                    }
                });
            },
            onReset() {
                this.$router.push('/login');
            }
        },
        created() {

        },
        mounted() {
            
        }
    }
</script>
