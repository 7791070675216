<template>
    <div class="row">
        <div class="col-12 pt-3">
            <DataTable ref="dataTable" get-url="/api/events" :fields="fields" filter="true" :take="25" cache-search="events-search">
                <template v-slot:cell(name)="data">
                    <router-link :to="'/events/' + data.item.id" variant="primary" size="sm">
                        {{data.item.name}}
                    </router-link>
                </template>

                <template v-slot:cell(date)="data">
                    <span v-if="data.value">
                        {{$moment(data.value).format("DD-MM-YYYY")}}
                    </span>
                </template>

                <template v-slot:cell(registrationEnd)="data">
                    <span v-if="data.value">
                        {{$moment(data.value).format("DD-MM-YYYY")}}
                    </span>
                </template>

                <template v-slot:cell(edit)="data">
                    <div>
                        <b-button :to="'/events/' + data.item.id" variant="primary" size="sm">
                            <fa icon="edit" /> Rediger
                        </b-button>
                        <b-button @click="deleteEvent(data.item.id)" variant="danger" size="sm">
                            <fa icon="trash" /> Slet
                        </b-button>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import DataTable from "../_ui/dataTable";

export default {
    data: () => ({
        fields: [
			{ key: "name", label: "Arrangement" },
			{ key: "date", label: "Dato" },
			{ key: "registrationEnd", label: "Tilmeldingsfrist" },
			// { key: "category", label: "Kategori" },
			{ key: "edit", label: "", thStyle: "width: 180px", class: "text-right" }
        ]
    }),
    components: { DataTable },
	methods: {
        deleteEvent(id) {
            if (confirm("Er du sikker på at du vil slette dette arrangement?")) {
                this.$http.post("/api/events/delete?id=" + id).then(x => {
                    this.$refs.dataTable.refresh();
                });
            }
        }
	},
    created() {

    },
    mounted() {
    }
};
</script>
