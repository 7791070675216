<template>
    <canvas ref="chart"></canvas>
</template>

<script>
    import Chart from "chart.js/auto"

    export default {
        data: () => ({
            chartConfig: {
                type: 'pie',
                data: null,
                options: {
                    parsing: {
                        key: "value"
                    }
                }
            },
            chart: null
        }),
        props: ['chartData', 'options'],
        methods: {
            update(data) {
                this.chartConfig.data = data
                this.chart.data = data

                setTimeout(() => {
                    this.chart.update()
                }, 300)
            }
        },
        mounted() {
            this.chartConfig.data = this.chartData

            this.chart = new Chart(
                this.$refs.chart,
                this.chartConfig
            )
        }
    }
</script>
