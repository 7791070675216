<template>
    <div class="row">
        <div class="col-12 pt-3">

        </div>
    </div>
</template>

<script>
export default {
    data: () => ({

    }),
    components: {},
	methods: {

	},
    created() {

    },
    mounted() {
    }
};
</script>