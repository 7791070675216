<template>
    <div class="row customer">
        <div class="col-12 pt-3">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Firmanavn" class="mb-2">
                                <b-form-input type="text" v-model="customer.name" name="name" v-validate="'required'" 
                                    data-vv-as="Firmanavn" :state="!errors.has('name') && customer.name != null"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Adresse" class="mb-2">
                                <b-form-textarea :rows="1" v-model="customer.address" name="address"></b-form-textarea>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Postnr." class="mb-2">
                                <b-form-input type="text" v-model="customer.zipCode" name="zipCode"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="By" class="mb-2">
                                <b-form-input type="text" v-model="customer.city" name="city"></b-form-input>
                            </b-input-group>
                        </div>

                    </div>
                
                    <div class="row" v-for="(item, i) in customer.contacts" :key="i">
                        <div class="col-12 col-lg-6">
                            <b-input-group prepend="Navn" class="mb-2">
                                <b-form-input type="text" v-model="item.name"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-10 col-lg-5">
                            <b-input-group prepend="Mobil" class="mb-2">
                                <b-form-input type="text" v-model="item.mobile"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-2 col-lg-1 text-right">
                            <b-button type="button" variant="danger" size="sm" @click="customer.contacts.splice(i,1)"><fa icon="times" /></b-button>
                        </div>
                        <div class="col-12 col-lg-6">
                            <b-input-group prepend="E-mail" class="mb-2">
                                <b-form-input type="text" v-model="item.email"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-lg-6">
                            <b-input-group prepend="Tlf." class="mb-2">
                                <b-form-input type="text" v-model="item.phone"></b-form-input>
                            </b-input-group>
                        </div>
                    </div>
                    <b-button type="button" variant="outline-dark" size="sm" @click="addContact"><fa icon="plus" /> Tilføj kontaktperson</b-button>

                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="E-mail" class="mb-2">
                                <b-form-input type="text" v-model="customer.email" name="email"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Telefon" class="mb-2">
                                <b-form-input type="text" v-model="customer.phone" name="phone"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="CVR" class="mb-2">
                                <b-form-input type="text" v-model="customer.vatNumber" name="vatNumber"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="EAN" class="mb-2">
                                <b-form-input type="text" v-model="customer.eanNumber" name="eanNumber"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Status" class="mb-2">
                                <b-form-select v-model="customer.status" :options="statuses"></b-form-select>
                            </b-input-group>
                        </div>


                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Ansvarlige" class="mb-2 mt-0" v-if="users">
                                <b-form-select v-model="owner" :options="users" text-field="name" value-field="id" @input="selectUser" autocomplete="disabled"></b-form-select>
                            </b-input-group>

                            <b-input-group v-for="(item, key) in customer.owners" :key="item" class="mb-2 mr-2" :prepend="getUser(item, key).name" v-if="users">
                                <b-btn size="sm" variant="danger" @click="removeOwner(key)">
                                    <fa icon="times"></fa>
                                </b-btn>
                            </b-input-group>
                        </div>


                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Kontaktdato" class="mb-2">
                                <v-date-picker mode="single" popover-visibility="focus" autocomplete="disabled"
                                               :value="$moment(customer.contactDate).toDate()" @input="customer.contactDate = $moment($event).utc().format()"
                                               title-transition="none" weeks-transition="none" class="form-control"
                                               :input-props="{ class: 'form-control', readOnly: true }">
                                </v-date-picker>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Oprettet af" class="mb-2 mt-0" v-if="users">
                                <b-form-select v-model="customer.creatorId" :options="users" text-field="name" value-field="id" autocomplete="disabled" :disabled="creatorId != null"></b-form-select>
                            </b-input-group>
                        </div>


                    </div>

                </div>
            </div>
        </div>

        <div class="col-12">
            <hr />
        </div>

        <div class="col-12 col-lg-6">
            <b-form-group label="Interne kommentarer:">
                <editor v-model="customer.comment"></editor>
            </b-form-group>
        </div>

        <div class="col-12 action-footer">
            <b-button type="button" variant="primary" v-if="!this.customer.id" @click="onSubmit">
                <fa icon="plus" /> Opret kunde</b-button>
            <b-button type="button" variant="primary" v-else @click="onSubmit">
                <fa icon="save" /> Gem ændringer på kunden</b-button>

            <b-button type="button" variant="danger" @click="onReset">
                <fa icon="times" /> Fortryd</b-button>
        </div>

        <v-toast ref="toast"></v-toast>
    </div>
</template>

<script>
export default {
    data: () => ({
        customer: {
            contacts: [],
            type: null,
            optionalText: null,
            status: "Lead"
        },
        statuses: [
            { text: "Lead", value: "Lead" },
            { text: "Kunde", value: "Customer" },
            { text: "Opsagt", value: "Lost" },
        ],
        settings: null,
        toast: null,
        changes: false,
        users: [],
        owner: null,
        creatorId: null
    }),
    components: {},
    computed: {
        id() {
            return this.$route.params.id;
        },
        newCustomer() {
            return this.$route.params.id == 'new';
        },
        sponsorTypes() {
            if (this.settings) {
                return this.settings.sponsorTypes;
            }
            return [];
        }
    },
    watch: {
        customer: {
            handler(val, oldVal) {
                if (oldVal) {
                    this.changes = true;
                }
            },
            deep: true
        },
    },
	methods: {
        status(item) {
            if (item) {
                if (this.$moment().isAfter(item.durationTo)) {
                    return { status: "Udløbet", class: "info" };
                }
            }

            if (item.status) {
                if (item.status == "Accepted") {
                    return { status: "Godkendt", class: "success" };
                }
                if (item.status == "CustomerAccepted") {
                    return { status: "Godkendt af kunden", class: "success" };
                }
                if (item.status == "Draft") {
                    return { status: "Kladde", class: "warning" };
                }
                if (item.status == "Pending") {
                    return { status: "Afventer godkendelse", class: "warning" };
                }
                if (item.status == "Cancelled") {
                    return { status: "Opsagt", class: "danger" };
                }
            }

            return null;
        },
        onSubmit() {
            this.$validator.validate().then(x => {
                if (x === true) {
                    this.$http.post("/api/leads/update", this.customer).then(x => {
                        if (x.data !== false) {
                            this.toast.showToast('Ændringerne blev gemt!', { theme: "success" });

                            if (this.newCustomer) {
                                this.$router.push('/leads/' + x.data);
                            } else {
                                setTimeout(() => {
                                    this.changes = false;
                                }, 200);
                            }
                        }
                    });
                }
            });
        },
        onReset() {
            this.$router.push('/leads');
        },
        getSettings() {
            this.$http.get("/api/settings").then(x => {
                if (x.data) {
                    this.settings = x.data;
                }
            });
        },
        getUsers() {
            this.$http.get("/api/customers/users").then(x => {
                if (x.data) {
                    this.users = x.data;
                }
            });
        },
        getUser(e, key) {
            var user = this.users.find(x => x.id == e);

            if (user) {
                return this.users.find(x => x.id == e);
            }

            this.customer.owners.splice(key, 1);

            return { name: "" };
        },
        addContact() {
            if (this.customer.contacts == null) {
                this.customer.contacts = [];
            }
            this.customer.contacts.push({
                name: null,
                email: null,
                phone: null
            });
        },
        selectUser(e) {
            if (e) {
                if (this.customer.owners == null) {
                    this.customer.owners = [];
                }
                if (this.customer.owners.indexOf(e) == -1) {
                    this.customer.owners.push(e);
                }
                setTimeout(() => {
                    this.owner = null;
                }, 100);
            }
        },
        removeOwner(key) {
            this.customer.owners.splice(key, 1);
        },
	},
    created() {
        this.getSettings();
        this.getUsers();

        if (!this.newCustomer) {
            this.$http.get("/api/leads/" + this.id).then(x => {
                if (x.data) {
                    this.customer = x.data;
                    this.creatorId = x.data.creatorId
                    setTimeout(() => {
                        this.changes = false;
                    }, 200);
                }
            });
        }
    },
    mounted() {
        this.toast = this.$refs.toast;
        this.toast.setOptions({ position: "bottom right" });
    },
    beforeRouteLeave(to, from, next) {
        if (this.changes) {
            const answer = window.confirm('Du har ændringer som ikke er gemt! Er du sikker på, at du vil forlade denne side?');
            if (answer) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    }
};
</script>
