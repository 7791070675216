<template>
    <div>
        <div class="row pt-3" v-if="club">
            <div class="col-12 col-md-6 col-lg-4">
                <h2>Rediger klub</h2>

                <b-form @submit.prevent="onSubmit" @reset="onReset" novalidate>

                    <div class="row">
                        <div class="col-12">
                            <b-input-group prepend="Klubnavn" class="mb-2">
                                <b-form-input type="text" v-model="club.name" name="name" v-validate="'required'" data-vv-as="Klubnavn" :state="!errors.has('name') && club.name != null"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12">
                            <b-input-group prepend="Adresse" class="mb-2">
                                <b-form-textarea v-model="club.address" :rows="2">
                                </b-form-textarea>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Postnr" class="mb-2">
                                <b-form-input type="text" v-model="club.zipCode"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="By" class="mb-2">
                                <b-form-input type="text" v-model="club.city"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Telefonnummer" class="mb-2">
                                <b-form-input type="text" v-model="club.phone"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="E-mail" class="mb-2">
                                <b-form-input type="text" v-model="club.email"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="CVR" class="mb-2">
                                <b-form-input type="text" v-model="club.vatNumber"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Kontaktperson" class="mb-2">
                                <b-form-input type="text" v-model="club.contact"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Bogholder e-mail" class="mb-2">
                                <b-form-input type="text" v-model="club.accountantEmail"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Momssats" class="mb-2">
                                <b-form-input type="number" v-model="club.vatRate"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Sæson start" class="mb-2">
                                <b-form-select v-model="club.seasonStart" :options="months" text-field="label" value-field="value"></b-form-select>
                            </b-input-group>
                        </div>

                        <!-- <div class="col-12 col-md-6">
        <b-input-group prepend="Sæson slut" class="mb-2">
            <b-form-select v-model="club.seasonEnd" :options="months" text-field="label" value-field="value"></b-form-select>
        </b-input-group>
    </div> -->

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Genforhandling måneder" class="mb-2">
                                <b-form-input type="number" v-model="club.renegotiationInterval"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Dashboard type" class="mb-2">
                                <b-form-select v-model="club.dashboardMode" :options="{ 0: 'Sæson', 1: 'Fakturering', 2: 'Fast sæson' }" text-field="label" value-field="value"></b-form-select>
                            </b-input-group>
                        </div>


                        <div class="col-12">
                            <b-form-group label="Kontrakt underskrift:">
                                <Signature v-model="club.signature"></Signature>
                            </b-form-group>
                        </div>

                        <div class="col-12">
                            <b-form-group label="Logo:">
                                <b-form-file ref="file" placeholder="Upload logo..." @input.native="uploadFile"></b-form-file>
                            </b-form-group>
                            <img :src="club.logo" v-if="club.logo" height="100" class="mb-4" />
                        </div>

                        <div class="col-12">
                            <h3>E-mail indstillinger</h3>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Afsender navn" class="mb-2">
                                <b-form-input type="text" v-model="club.emailFrom"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Standard skabelon" class="mb-2">
                                <b-form-input type="text" v-model="club.emailTemplate"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Invitation skabelon" class="mb-2">
                                <b-form-input type="text" v-model="club.userEmailTemplate"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Klub farve" class="mb-2">
                                <b-form-input type="text" v-model="club.emailColor"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Kontrakt overskrift" class="mb-2">
                                <b-form-input type="text" v-model="club.contractEmailHeadline"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Kontrakt emne" class="mb-2">
                                <b-form-input type="text" v-model="club.contractEmailSubject"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Print overskrift" class="mb-2">
                                <b-form-input type="text" v-model="club.printEmailHeadline"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Print emne" class="mb-2">
                                <b-form-input type="text" v-model="club.printEmailSubject"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Skiltemaler e-mail" class="mb-2">
                                <b-form-input type="text" v-model="club.painterEmail"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12">
                            <b-input-group prepend="Skilteprodukter" class="mb-2">
                                <b-form-textarea v-model="club.printProducts"
                                                 placeholder="Kommaseparerede produkt id'er"
                                                 rows="3"
                                                 max-rows="6" />
                            </b-input-group>
                        </div>

                        <div class="col-12">
                            <h3>Fakturering</h3>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="E-conomic secret token" class="mb-2">
                                <b-form-input type="text" v-model="club.economicSecretToken"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="E-conomic auth token" class="mb-2">
                                <b-form-input type="text" v-model="club.economicAuthToken"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="E-conomic moms zone" class="mb-2">
                                <b-form-input type="number" v-model="club.economicVat"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="E-conomic momsfritaget zone" class="mb-2">
                                <b-form-input type="number" v-model="club.economicVatExemption"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="E-conomic layout" class="mb-2">
                                <b-form-input type="number" v-model="club.economicLayout"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="E-conomic payment terms" class="mb-2">
                                <b-form-input type="number" v-model="club.economicPaymentTerms"></b-form-input>
                            </b-input-group>
                        </div>


                        <div class="col-12">
                            <h3>Arrangementer</h3>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Sæsonkort navn" class="mb-2">
                                <b-form-input type="text" v-model="club.eventsSeasonCardName"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Gæstekort navn" class="mb-2">
                                <b-form-input type="text" v-model="club.eventsGuestCardName"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12">
                            <b-input-group prepend="Tilmeldingsside URL" class="mb-2">
                                <b-form-input type="text" v-model="club.eventsRegistrationUrl"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Mailchimp invitation template" class="mb-2">
                                <b-form-input type="text" v-model="club.invitationTemplate"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Mailchimp billet template" class="mb-2">
                                <b-form-input type="text" v-model="club.ticketTemplate"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12">
                            <b-input-group prepend="Bordkort baggrund" class="mb-2">
                                <b-form-textarea v-model="club.tableTemplateBackground"
                                                 rows="3"
                                                 max-rows="6" />
                            </b-input-group>
                        </div>

                        <div class="col-12 py-10">
                            <b-form-checkbox v-model="club.eventsUsePeople">
                                Navne på tilmelding af arrangement
                            </b-form-checkbox>
                        </div>


                        
                        <div class="col-12">
                            <h3>Billetter</h3>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Sæsonkort navn" class="mb-2">
                                <b-form-input type="text" v-model="club.ticketEventsSeasonCardName"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Gæstekort navn" class="mb-2">
                                <b-form-input type="text" v-model="club.ticketEventsGuestCardName"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12">
                            <b-input-group prepend="Tilmeldingsside URL" class="mb-2">
                                <b-form-input type="text" v-model="club.ticketEventsRegistrationUrl"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Mailchimp invitation template" class="mb-2">
                                <b-form-input type="text" v-model="club.ticketEventInvitationTemplate"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Mailchimp billet template" class="mb-2">
                                <b-form-input type="text" v-model="club.ticketEventTemplate"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 py-10">
                            <b-form-checkbox v-model="club.ticketEventsUsePeople">
                                Navne på tilmelding af arrangement
                            </b-form-checkbox>
                        </div>



                        <div class="col-12">
                            <h3>Kampagner</h3>
                        </div>

                        <div class="col-12">
                            <b-input-group prepend="Tilmeldingsside URL" class="mb-2">
                                <b-form-input type="text" v-model="club.offerRegistrationUrl"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Mailchimp kampagne template" class="mb-2">
                                <b-form-input type="text" v-model="club.offerCampaignTemplate"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 col-md-6">
                            <b-input-group prepend="Mailchimp tilbud template" class="mb-2">
                                <b-form-input type="text" v-model="club.offerInvitationTemplate"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12">
                            <b-input-group prepend="Mailchimp tilbud kvittering template" class="mb-2">
                                <b-form-input type="text" v-model="club.offerReceiptTemplate"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-12 py-10">
                            <b-form-checkbox v-model="club.offerUsePeople">
                                Navne på tilmelding af kampagne
                            </b-form-checkbox>
                        </div>


                        <div v-if="$store.state.superAdmin" class="my-4">
                            <div class="col-12">
                                <h3>Admin</h3>

                                <b-form-checkbox v-model="club.eventsEnabled">
                                    Arrangementer
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>


                    <b-button type="submit" variant="primary">
                        <fa icon="save" /> Gem
                    </b-button>
                    <b-button type="reset" variant="danger">
                        <fa icon="times" /> Fortryd
                    </b-button>
                </b-form>
            </div>
            <div class="col-12 col-md-6 col-lg-5" v-if="club">
                <h4 class="mb-3">Bankinfo: reg + kontonummer (til faktura)</h4>
                <editor v-model="club.bankDetails"></editor>
                <hr />
                <h4 class="mb-3">Handelsbetingelser (klub og sponsor imellem)</h4>
                <editor v-model="club.terms"></editor>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
                <b-input-group prepend="Faktura grupper" class="mb-2">
                    <b-form-input type="text" @change="addInvoiceGroup" v-model="invoiceGroup"></b-form-input>
                </b-input-group>

                <draggable class="dragzone" v-model="club.invoiceGroups" :options="{}">
                    <b-input-group v-for="(item, key) in club.invoiceGroups" :key="'i-' + key" class="mb-2 mr-2 drag-item" :prepend="item">
                        <b-btn size="sm" variant="danger" @click="club.invoiceGroups.splice(key, 1)">
                            <fa icon="times"></fa>
                        </b-btn>
                    </b-input-group>
                </draggable>



                <b-input-group prepend="Produkt kategorier" class="mb-2 mt-4">
                    <b-form-input type="text" @change="addProductGroup" v-model="productGroup"></b-form-input>
                </b-input-group>

                <draggable class="dragzone" v-model="club.productGroups" :options="{}">
                    <b-input-group v-for="(item, key) in club.productGroups" :key="'p-' + key" class="mb-2 mr-2 drag-item" :prepend="item">
                        <b-btn size="sm" variant="danger" @click="club.productGroups.splice(key, 1)">
                            <fa icon="times"></fa>
                        </b-btn>
                    </b-input-group>
                </draggable>

                <b-input-group prepend="Sponsortyper" class="mb-2 mt-4">
                    <b-form-input type="text" @change="addSponsorType" v-model="sponsorType"></b-form-input>
                </b-input-group>

                <draggable class="dragzone" v-model="club.sponsorTypes" :options="{}">
                    <b-input-group v-for="(item, key) in club.sponsorTypes" :key="'s-' + key" class="mb-2 mr-2 drag-item" :prepend="item">
                        <b-btn size="sm" variant="danger" @click="club.sponsorTypes.splice(key, 1)">
                            <fa icon="times"></fa>
                        </b-btn>
                    </b-input-group>
                </draggable>

            </div>

        </div>

        <v-toast ref="toast"></v-toast>
    </div>
</template>

<script>
    import Signature from "../_ui/signature";

    export default {
        data: () => ({
            toast: null,
            club: null,
            invoiceGroup: null,
            productGroup: null,
            sponsorType: null,
            months: [
                { label: "Januar", value: 1 },
                { label: "Februar", value: 2 },
                { label: "Marts", value: 3 },
                { label: "April", value: 4 },
                { label: "Maj", value: 5 },
                { label: "Juni", value: 6 },
                { label: "Juli", value: 7 },
                { label: "August", value: 8 },
                { label: "September", value: 9 },
                { label: "Oktober", value: 10 },
                { label: "November", value: 11 },
                { label: "December", value: 12 },
            ]
        }),
        components: { Signature },
        methods: {
            onSubmit() {
                this.$validator.validate().then(x => {
                    if (x === true) {
                        this.$http.post("/api/settings", this.club).then(x => {
                            if (x.data === true) {
                                this.toast.showToast('Ændringerne blev gemt', { theme: "success" });

                                this.getClub();
                            }
                        });
                    }
                });
            },
            onReset() {
                this.club = null;
                this.getClub();

                for (let index = 0; index < array.length; index++) {
                    const element = array[index];

                }
            },
            getClub() {
                this.$http.get("/api/settings").then(x => {
                    this.club = x.data;
                });
            },
            addInvoiceGroup(item) {
                if (this.club.invoiceGroups == null) {
                    this.club.invoiceGroups = [];
                }

                if (this.club.invoiceGroups.find(x => x.toLowerCase() == item.toLowerCase()) == null) {
                    this.club.invoiceGroups.push(item);
                }

                this.invoiceGroup = null;
            },
            addProductGroup(item) {
                if (this.club.productGroups == null) {
                    this.club.productGroups = [];
                }
                if (this.club.productGroups.find(x => x.toLowerCase() == item.toLowerCase()) == null) {
                    this.club.productGroups.push(item);
                }

                this.productGroup = null;
            },
            addSponsorType(item) {
                if (this.club.sponsorTypes == null) {
                    this.club.sponsorTypes = [];
                }
                if (this.club.sponsorTypes.find(x => x.toLowerCase() == item.toLowerCase()) == null) {
                    this.club.sponsorTypes.push(item);
                }

                this.sponsorType = null;
            },
            uploadFile(e) {
                var formData = new FormData();
                formData.append("file", e.target.files[0]);

                this.$http.post("/api/settings/upload", formData).then(x => {
                    if (x.data === true) {
                        this.toast.showToast('Filen blev uploadet', { theme: "success" });

                        this.getClub();

                        this.$refs.file.files = null;
                    }
                });
            },
        },
        created() {
            this.getClub();
        },
        mounted() {
            this.toast = this.$refs.toast;
            this.toast.setOptions({ position: "bottom right" });
        }
    };
</script>
