<template>
	<div>
		<b-navbar id="menu" toggleable="md" type="dark" variant="dark" fixed="top" v-if="menu">

			<b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

			<b-dropdown href="/" class="mr-1 club-header" v-if="clubs.length > 1">
				<template slot="button-content">
					<span class="mr-2">
						<fa icon="futbol" class="mr-1" /> {{selectedClub}}
					</span>
				</template>

				<b-dropdown-item :href="'/change-club/' + item.id" v-for="item in clubs" :key="item.id"><fa icon="futbol" class="mr-1" /> {{item.name}}</b-dropdown-item>

				<b-dropdown-divider v-if="superAdmin"></b-dropdown-divider>
				<b-dropdown-item v-if="superAdmin" to="/create-club"><fa icon="plus" class="mr-1" /> Opret klub</b-dropdown-item>
			</b-dropdown>
			<b-navbar-brand href="/" class="mx-1 club-header" style="font-size: 12pt" v-if="clubs.length == 1">
				<span class="mr-2 ">
					<fa icon="futbol" class="mr-1" /> {{selectedClub}}
				</span>
			</b-navbar-brand>

			<b-collapse is-nav id="nav_collapse">
				<b-navbar-nav>
					<template v-for="(item, key) in menu">
						<b-nav-item-dropdown :to="item.url" v-if="item.children" :key="key">
							<template slot="button-content">
								<fa :icon="item.icon" /> {{item.name}}
							</template>

							<b-dropdown-item :to="child.url" v-for="child in item.children" :key="child.url">
								<fa :icon="child.icon" /> {{child.name}}
							</b-dropdown-item>
						</b-nav-item-dropdown>
						<b-nav-item :to="item.url" v-else :exact="item.url == '/'" :key="key">
							<fa :icon="item.icon" /> {{item.name}}
						</b-nav-item>
					</template>
				</b-navbar-nav>


				<b-navbar-nav class="ml-auto">
					<!--<b-nav-form>
						<b-form-input size="sm" class="mr-sm-2" type="text" placeholder="Search" />
						<b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
					</b-nav-form> -->

					<b-nav-item-dropdown right>
						<!-- Using button-content slot -->
						<template slot="button-content">
							<fa icon="user" /> {{user}}
						</template>
						<b-dropdown-item to="/profile">Profil</b-dropdown-item>
						<b-dropdown-item href="/logout">Log ud</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
		
		<b-navbar id="sub-menu" toggleable="md" type="light" variant="light" fixed="top" v-if="subMenu && !createPage">
			<b-navbar-toggle target="nav_collapse_sub"></b-navbar-toggle>

			<b-collapse is-nav id="nav_collapse_sub">
				<b-navbar-nav>
					<b-nav-item :to="item.url" v-for="item in subMenu" :key="item.url">
						<fa :icon="item.icon" class="mr-2" /> {{item.name}}
					</b-nav-item>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>

	</div>
</template>

<script>
export default {
	data: () => ({

	}),
	components: {},
	computed: {
		menu() {
			if (this.$store.state.menu) {
				return this.$store.state.menu;
			}

			return null;
		},
		selectedClub() {
			return this.$store.state.selectedClub;
		},
		clubs() {
			return this.$store.state.clubs;
		},
		user() {
			return this.$store.state.user;
		},
		superAdmin() {
			return this.$store.state.superAdmin;
		},
		subMenu() {
			if (!this.menu) {
				return null;
			}

			var menu = this.menu.find(
				x => x.area == this.$route.matched[0].meta.area
			);

			if (menu) {
				return menu.subMenu;
			}

			return null;
		},
		createPage() {
			return this.$route.params.id == "new";
		}
	},
	methods: {}
};
</script>
