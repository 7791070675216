<template>
    <canvas ref="chart" height="80"></canvas>
</template>

<script>
    import Chart from "chart.js/auto"

    export default {
        data: () => ({
            chartConfig: {
                type: 'bar',
                data: null,
                options: {
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                }
            },
            chart: null
        }),
        props: ['chartData', 'options'],
        methods: {
            update(data) {
                this.chartConfig.data = data
                this.chart.data = data

                setTimeout(() => {
                    this.chart.update()
                }, 300)
            }
        },
        mounted() {
            this.chartConfig.data = this.chartData
            //this.chartConfig.options = this.options

            this.chart = new Chart(
                this.$refs.chart,
                this.chartConfig
            )
        }
    }
</script>
