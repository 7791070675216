<template>
    <div>    
        <component :is="icon"></component>
    </div>
</template>

<script>
    //import bee from './bee.vue';


    export default {
        props: ['icon'],
        components: {
            //bee
        },
        computed: {

        },
        methods: {

        }
    }
</script>