<template>
    <div class="register-event">
        <form v-if="event" @submit.prevent="registerTickets">
            <h3>{{event.event.name}}</h3>

            <div class="row mb-3">
                <div class="col-sm-6">
                    <b>Dato:</b> {{$moment(event.event.date).format("D. MMMM YYYY [kl.] HH:mm")}}
                </div>
                <div class="col-sm-6">
                    <b>Tilmeldingsfrist:</b> {{$moment(event.event.registrationEnd).format("D. MMMM YYYY [kl.] HH:mm")}}
                </div>
            </div>

            <div class="mb-3" v-html="event.event.description"></div>

            <div v-if="$moment(event.event.registrationEnd) > $moment()">
                <!-- event.event.seasonCardName -->
                <b-form-group :label="'Billetter:'" label-cols-sm="3" :invalid-feedback="errors.first('seasonTickets')" v-if="event.event.seasonTickets">
                    <b-input-group>
                        <b-form-input type="number" v-model="register.seasonTickets" readonly name="seasonTickets"
                                      v-validate="seasonTicketsValidation" :state="errorState('seasonTickets')" data-vv-as="Billetter"></b-form-input>

                        <b-input-group-append>
                            <div class="input-group-text"> af {{seasonTicketsLeft}}</div>
                            <b-button variant="danger" @click="updateTicket(false, 'seasonTickets')">
                                <fa icon="minus" />
                            </b-button>
                            <b-button variant="success" @click="updateTicket(true, 'seasonTickets')">
                                <fa icon="plus" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <b-form-group :label="event.event.guestCardName + ':'" label-cols-sm="3" :invalid-feedback="errors.first('tickets')" v-if="event.event.flexTickets">
                    <b-input-group>
                        <b-form-input type="number" v-model="register.tickets" readonly name="tickets"
                                      v-validate="ticketsValidation" :state="errorState('tickets')" :data-vv-as="event.event.guestCardName"></b-form-input>

                        <b-input-group-append>
                            <div class="input-group-text"> af {{ticketsLeft}}</div>
                            <b-button variant="danger" @click="updateTicket(false, 'tickets')">
                                <fa icon="minus" />
                            </b-button>
                            <b-button variant="success" @click="updateTicket(true, 'tickets')">
                                <fa icon="plus" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <b-form-group label="Ekstra billetter:" label-cols-sm="3" :invalid-feedback="errors.first('extraTickets')" v-if="event.event.additionalTickets"
                              :description="'Pris: ' + event.event.ticketPrice + ' DKK (inkl. moms) pr. stk.'">
                    <b-input-group>
                        <b-form-input type="number" v-model="register.extraTickets" readonly name="extraTickets"
                                      v-validate="extraTicketsValidation" :state="errorState('extraTickets')" data-vv-as="Ekstra billetter"></b-form-input>

                        <b-input-group-append>
                            <div class="input-group-text"> af {{extraTicketsLeft}}</div>
                            <b-button variant="danger" @click="updateTicket(false, 'extraTickets')">
                                <fa icon="minus" />
                            </b-button>
                            <b-button variant="success" @click="updateTicket(true, 'extraTickets')">
                                <fa icon="plus" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>


                <b-form-group label="E-mail:" label-cols-sm="3" :invalid-feedback="errors.first('email')">
                    <b-form-select v-model="register.email" :options="event.customer.emails" class="" name="email"
                                   v-validate="'required'" :state="errorState('email')" data-vv-as="E-mail">
                        <template slot="first">
                            <option :value="null">-- Vælg en modtager --</option>
                        </template>
                    </b-form-select>
                </b-form-group>



                <div class="people" v-if="event.event.usePeople">
                    <b-form-group :label="'Person ' + (pkey+1)" label-cols-sm="3" v-for="(person, pkey) in register.people" :key="'person-' + pkey">
                        <div class="row">
                            <div class="col">
                                <b-form-select v-model="person.name" :options="event.customer.contacts" class="" :disabled="!person.edit">
                                    <template slot="first">
                                        <option :value="null">-- Vælg --</option>
                                    </template>
                                </b-form-select>
                            </div>
                            <div class="col-auto d-flex align-items-center">
                                <strong>eller</strong>
                            </div>
                            <div class="col">
                                <b-input v-model="person.name" type="text" :name="'person'+pkey" placeholder="Indtast navn" :disabled="!person.edit"
                                         v-validate="'required'" :state="errorState('person'+pkey)" data-vv-as="Navn"></b-input>
                            </div>
                            <div class="col col-auto" v-if="person.id">
                                <b-button variant="secondary" class="" v-if="!person.edit" @click="person.edit = true">
                                    <fa icon="pen" />
                                </b-button>
                                <b-button variant="success" class="" v-else @click="savePerson(person)">
                                    <fa icon="check" />
                                </b-button>

                                <b-button variant="danger" class="" @click="deletePerson(person)">
                                    <fa icon="trash" />
                                </b-button>
                            </div>
                        </div>
                    </b-form-group>
                </div>


              
                <b-form-group label="Besked:" label-cols-sm="3">
                    <b-textarea rows="4" v-model="register.message"></b-textarea>
                </b-form-group>

                <div>
                    <p v-if="usedTickets > 0">
                        Du har allerede bestilt <b>{{usedTickets}}</b> billetter til stadion
                    </p>
                    <p v-else>
                        Du har ikke bestilt nogle billetter til stadion endnu.
                    </p>
                    <b-button type="submit" size="lg" variant="success" :disabled="loading">
                        <fa icon="check" class="mr-2" v-if="!loading" />
                        <fa icon="sync" class="mr-2" spin v-if="loading" />
                        Bestil billetter
                    </b-button>
                </div>

            </div>
            <div v-else class="text-center font-weight-bold py-3">
                Tilmeldingsfristen er overskredet!
            </div>

            <b-alert class="mt-3" variant="success" show v-if="registerResult && registerResult.success">
                <fa icon="check-circle" size="2x" class="align-middle" /> <span class="ml-2 align-middle">Tak for din tilmelding!</span>
            </b-alert>
            <b-alert class="mt-3" variant="danger" show v-if="registerResult && !registerResult.success">
                <fa icon="exclamation-triangle" size="2x" class="align-middle" /> <span class="ml-2 align-middle">{{registerResult.error}}</span>
            </b-alert>

        </form>
    </div>
</template>

<script>
export default {
    data: () => ({
        event: null,
        register: {
            seasonTickets: 0,
            tickets: 0,
            extraTickets: 0,
            email: null,
            message: null,
            people: []
        },
        registerResult: null,
        loading: false
    }),
    watch: {
        register: {
            handler() {
                var peopleCount = this.register.seasonTickets + this.register.tickets + this.register.extraTickets

                var diff = peopleCount - this.register.people.filter(x => x.id == null).length
                if (diff > 0) {
                    for (var i = 0; i < diff; i++) {
                        this.register.people.push({
                            name: null,
                            edit: true
                        })
                    }
                } else if (diff < 0) {
                    this.register.people.splice(diff)
                }
            },
            deep: true
        }
    },
    computed: {
        token() {
            return this.$route.query.token;
        },
        ticketsLeft() {
            var tickets = this.event.customer.tickets;
            var totalTickets = this.event.event.totalTickets;
            var maxTickets = this.event.event.maxTickets;

            var result = maxTickets < totalTickets ? maxTickets : totalTickets;
            result = tickets < result ? tickets : result;

            if (this.register.tickets > result) {
                this.register.tickets = result;
                this.$validator.validate();
            }
            return result;
        },
        ticketsValidation() {
            return {
                required: true,
                numeric: true,
                max_value: this.ticketsLeft,
                min_value: 0
            };
        },
        seasonTicketsLeft() {
            var seasonTickets = this.event.customer.seasonTickets;
            var totalTickets = this.event.event.totalTickets;
            var maxTickets = this.event.event.maxTickets;

            var result = maxTickets < totalTickets ? maxTickets : totalTickets;
            result = seasonTickets < result ? seasonTickets : result;
            
            if (this.register.seasonTickets > result) {
                this.register.seasonTickets = result;
                this.$validator.validate();
            }
            return result;
        },
        seasonTicketsValidation() {
            return {
                required: true,
                numeric: true,
                max_value: this.seasonTicketsLeft,
                min_value: 0
            };
        },
        extraTicketsLeft() {
            var totalTickets = this.event.event.totalTickets;
            var maxTickets = this.event.event.maxTickets;

            var result = maxTickets < totalTickets ? maxTickets : totalTickets;
            if (this.register.extraTickets > result) {
                this.register.extraTickets = result;
                this.$validator.validate();
            }
            return result;
        },
        extraTicketsValidation() {
            return {
                required: true,
                numeric: true,
                max_value: this.extraTicketsLeft,
                min_value: 0
            };
        },
        usedTickets() {
            if (!this.event) {
                return 0;
            }
            return this.event.invite.tickets + this.event.invite.seasonTickets + this.event.invite.extraTickets;
        }
    },
    methods: {
        getEvent() {
            this.$http.get("/api/ticketevents/invite/" + this.token).then(x => {
                this.event = x.data;

                this.register.message = x.data.invite.message;

                this.register.people = x.data.invite.people
            });
        },
        updateTicket(add, item) {
            var value = this.register[item];            
            if (add) {
                value++;
            } else {
                value--;
                if (value < 0) {
                    value = 0;
                }
            }
            this.$set(this.register, item, value);

            this.$validator.validate();
        },

        savePerson(person) {
            this.loading = true;

            this.$http.post("/api/ticketevents/invite/save-person/" + this.token, person).then(x => {
                this.getEvent();
                this.loading = false;
            });
        },
        deletePerson(person) {
            if (confirm("Er du sikker på at du vil slette " + person.name)) {
                this.loading = true;

                this.$http.post("/api/ticketevents/invite/delete-person/" + this.token, person).then(x => {
                    this.getEvent();
                    this.loading = false;
                });
            }
        },

        registerTickets() {
            this.$validator.validate().then(valid => {
                if (valid === true) {
                    this.loading = true;

                    this.$http.post("/api/ticketevents/invite/register/" + this.token, this.register).then(x => {
                        this.registerResult = x.data;
                        this.getEvent();

                        if (x.data.success) {
                            this.register = {
                                seasonTickets: 0,
                                tickets: 0,
                                extraTickets: 0,
                                email: null
                            }
                        }

                        this.loading = false;
                        this.$validator.reset();
                    });
                }
            });
        },
        errorState(name) {
            if (this.errors.has(name)) {
                return false;
            }

            if (this.errors.first(name)) {
                return false;
            }
            
            return null;
        }
    },
    created() {
        this.getEvent();
    },
    mounted() {
        this.$validator.reset();
    }
};
</script>

<style lang="scss">
    .register-event {
        margin: auto;
        padding: 10px;
        height: 1000px;
        max-width: 576px;
        width: 100%;

        .invalid-feedback {
            font-size: 13px;
        }

        .input-group {

            .form-control {
                height: 33px;
            }
        }

        .form-text.text-muted {
            font-size: 14px;
        }

        .people {
            button {
                border-radius: 50%;
                padding: 4px 4px;
                font-size: 10px;
                line-height: 14px;
                width: 30px;
                height: 30px;
                text-align: center;
                opacity: 0.8;
            }
        }

        .custom-select {
            font-size: 12px;
            padding: 4px 5px;
            line-height: 22px;
        }
    }
</style>

