<template>
    <component v-if="event" is="invitation"></component>
    <component v-else-if="ticketEvent" is="ticketInvitation"></component>
</template>

<script>
    import Invitation from "./events/invite.vue";
    import TicketInvitation from "./tickets/invite.vue";

    export default {
        data: () => ({
            event: null,
            ticketEvent: null
        }),
        components: {
            invitation: Invitation,
            ticketInvitation: TicketInvitation
        },
        computed: {
            token() {
                return this.$route.query.token;
            }
        },
        methods: {
            getEvent() {
                this.$http.get("/api/events/invite/" + this.token).then(x => {
                    this.event = x.data;
                });
            },
            getTicketEvent() {
                this.$http.get("/api/ticketevents/invite/" + this.token).then(x => {
                    this.ticketEvent = x.data;
                });
            },
        },
        created() {
            this.getEvent()
            this.getTicketEvent()
        },
        mounted() {
        }
    };
</script>