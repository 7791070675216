<template>
    <div v-if="!loading">
        <tiny-editor ref="editor" v-model="editorValue" :init="editorConfig" api-key="y4jogowaxy47b99e6974w6mcy3tav2zikhkccn8iugbwzj2b"></tiny-editor>
    </div>
</template>

<script>
    export default {
        data: () => ({
            loading: true,
            editorConfig: {
                height: 300,
                plugins: [
                    'lists link image paste code'
                ],
                toolbar: 'undo redo | formatselect | bold italic link image removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code',
                menubar: "",
                images_upload_handler: (blobInfo, success, failure) => {
                    success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64())
                }
            }
        }),
        props: {
            value: null,
            config: Object
        },
        computed: {
            editorValue: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            }
        },
        created() {
            //console.log(this.$refs.editor)

            if (this.config) {
                this.editorConfig = this.config
            }
        },
        mounted() {
            setTimeout(() => {
                this.loading = false
            }, 30)
        }
    };
</script>
