<template>
    <div class="row">
        <div class="col-12 col-md-6 pt-3">
            <DataTable ref="dataTable" get-url="/api/templates" :fields="fields">
                <template slot="create">
                    <div class="col-12 mb-3">
                        <b-button @click="createTemplate" variant="success" size="sm">
                            <fa icon="plus" /> Opret skabelon
                        </b-button>
                    </div>
                </template>
                <template slot="edit" slot-scope="data">
                    <div>
                        <b-button @click="editTemplate(data.item)" variant="primary" size="sm">
                            <fa icon="edit" /> rediger
                        </b-button>
                        <b-button @click="deleteTemplate(data.item.id)" variant="danger" size="sm">
                            <fa icon="trash" /> Slet
                        </b-button>
                    </div>
                </template>
            </DataTable>
        </div>

        <b-modal ref="templateModal" id="templateModal" size="lg" @ok="saveTemplate" no-close-on-backdrop>
            <template slot="modal-title">
                <h5 v-if="template && !template.id">Opret skabelon</h5>
                <h5 v-if="template && template.id">Rediger skabelon</h5>
            </template>
            <div class="row">
                <div class="col-6">
                    <b-form-group label="Navn:" :state="!errors.has('title')" :invalid-feedback="errors.first('title')">
                        <b-form-input type="text" v-model="template.title" name="title" 
                            v-validate="'required'" data-vv-as="Navn"
                            :state="!errors.has('title') && template.title"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12">
                    <b-form-group label="Kommentarer:">
                        <editor v-model="template.comment"></editor>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-sm table-striped table-bordered">
                        <tr>
                            <th style="width: 45%">Produkt</th>
                            <th>Antal</th>
                            <th>Stk. pris</th>
                            <th>Rabat</th>
                            <th style="width: 12%">Pris</th>
                            <th></th>
                        </tr>
                        <tr v-for="(item, key) in template.items" :key="key">
                            <td>
                                <p class="m-0">
                                    {{item.name}}
                                </p>
                                <div class="product-description">
                                    <b-form-textarea v-model="item.description" rows="2" size="sm" v-if="item.hide || item.description" placeholder="Beskrivelse ..."></b-form-textarea>
                                    <button class="btn btn-link" @click="$set(contract.items[key], 'hide', !item.hide); item.description = null" size="sm">
                                        <span v-if="!item.hide && !item.description">Tilføj beskrivelse</span>
                                        <span v-if="item.hide || item.description">Fjern</span>
                                    </button>
                                </div>
                            </td>
                            <td>
                                <b-form-input type="number" v-model="item.quantity"></b-form-input>
                            </td>
                            <td>
                                <b-form-input type="number" v-model="item.price"></b-form-input>
                            </td>
                            <td>
                                <b-form-input type="number" v-model="item.discount"></b-form-input>
                            </td>
                            <td class="align-middle text-right">{{item.quantity * (item.price - item.discount)}}</td>
                            <td class="align-middle text-right">
                                <b-button @click="removeItem(key)" variant="danger" size="sm">
                                    <fa icon="times" />
                                </b-button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-12">
                    <b-button @click.stop.prevent="$refs.addProductModal.show()" variant="primary" size="sm">
                        <fa icon="plus" /> Tilføj produkter
                    </b-button>
                </div>
            </div>
        </b-modal>

        <b-modal ref="addProductModal" id="add-product-modal" centered title="Tilføj produkter" no-close-on-backdrop
            @ok="addProducts" ok-title="Tilføj" cancel-title="Fortryd" size="lg"
            @cancel="items = []; $refs.templateModal.show();">
            <div class="row">
                <div class="col-6 col-md-5 categories">
                    <b-btn v-for="(item, key) in products" :key="key" v-b-toggle="'product-' + key" variant="secondary" block size="sm">
                        {{item.group || 'Ingen kategori'}}
                    </b-btn>
                </div>
                <div class="col-6 col-md-7">
                    <b-collapse :id="'product-' + key" v-for="(item, key) in products" :key="key" accordion="product-groups">
                        <b-form-checkbox v-for="product in item.products" :key="product.id"
                                        v-model="items" :value="product">
                            {{product.name}}
                        </b-form-checkbox>
                    </b-collapse>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
import DataTable from "../_ui/dataTable";

export default {
    data: () => ({
        template: {
            id: null,
            title: null,
            comment: null,
            items: []
        },
        fields: [
			{ key: "title", label: "Navn" },
			{ key: "edit", label: "", thStyle: "width: 180px", class: "text-right" }
        ],
        products: [],
        items: []
    }),
    components: { DataTable },
	methods: {
        createTemplate() {
            this.$refs.templateModal.show();
            this.template = {
                id: null,
                title: null,
                comment: null,
                items: []
            };
        },
        getProducts() {
			this.$http.get("/api/templates/products").then(x => {
				this.products = x.data;
			});
        },
        editTemplate(item) {
            this.$refs.templateModal.show();
            this.template = item;
        },
        removeItem(key) {
            this.template.items.splice(key, 1);
        },
        deleteTemplate(id) {
            if (confirm("Er du sikker på at du vil slette denne skabelon?")) {
                this.$http.post("/api/templates/delete?id=" + id).then(x => {
                    this.$refs.dataTable.refresh();
                });
            }
        },
        saveTemplate() {
            this.$validator.validate().then(valid => {
                if (valid === true) {
                    this.$http.post("/api/templates/update", this.template).then(x => {
                        this.$refs.dataTable.refresh();
                    });
                }
            });
        },
        addProducts() {
            for (let index = 0; index < this.items.length; index++) {
                const element = this.items[index];
                
                this.template.items.push({
                    productId: element.id,
                    name: element.name,
                    description: element.description,
                    quantity: 1,
                    price: element.price,
                    discount: 0
                });
            }

            this.items = [];

            this.$refs.templateModal.show();
        }
	},
    created() {
        this.getProducts();
    },
    mounted() {
    }
};
</script>
