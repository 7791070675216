<template>
    <div class="row">
        <div class="col-12 pt-3">
            <DataTable ref="dataTable" get-url="/api/contracts" :fields="fields" filter="true" :take="25" cache-search="contracts-search" :filters.sync="filters">
                <template slot="title" slot-scope="data">
                    <router-link :to="'/contracts/' + data.item.id" variant="primary" size="sm">
                        <fa icon="file-contract" /> {{data.item.title}}
                    </router-link>
                </template>
                <template slot="customer" slot-scope="data">
                    <router-link :to="'/customers/' + data.item.customerId" variant="primary" size="sm">
                        {{data.item.customer}}
                    </router-link>
                </template>
                <template slot="owners" slot-scope="data">
                    {{data.item.owners.join(', ')}}
                </template>
                <template slot="status" slot-scope="data">
                    <b-badge :variant="status(data.item).class" pill class="py-2 px-3" style="font-size: 14px;">{{status(data.item).status}}</b-badge>
                </template>
                <template slot="date" slot-scope="data">
                    {{$moment(data.item.date).format('DD-MM-YYYY')}}
                </template>
                <template slot="edit" slot-scope="data">
                    <div>
                        <b-button :to="'/contracts/' + data.item.id" variant="primary" size="sm">
                            <fa icon="edit" /> Vis aftale
                        </b-button>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import DataTable from "../_ui/dataTable";

export default {
    data: () => ({
        fields: [
			{ key: "title", label: "Aftale" },
			{ key: "customer", label: "Kunde" },
			{ key: "owners", label: "Ansvarlige" },
			{ key: "status", label: "Status" },
			{ key: "date", label: "Dato", sortable: true },
			{ key: "edit", label: "", thStyle: "width: 180px", class: "text-right" }
        ],
        statuses: [
            { text: "Alle", value: "" },
            { text: "Kladde", value: "Draft" },
            { text: "Afventer", value: "Pending" },
            { text: "Godkendt", value: "Accepted" },
            { text: "Opsagt", value: "Cancelled" },
        ]
    }),
    components: { DataTable },
    computed: {
        filters() { 
            return [
                { field: "status", items: this.statuses, value: "", label: "Status" }
            ];
        }
    },
	methods: {
        status(item) {
            if (item) {
                if (this.$moment().isAfter(item.durationTo)) {
                    return { status: "Udløbet", class: "info" };
                }
            }

            if (item.status) {
                if (item.status == "Accepted") {
                    return { status: "Godkendt", class: "success" };
                }
                if (item.status == "CustomerAccepted") {
                    return { status: "Godkendt af kunden", class: "success" };
                }
                if (item.status == "Draft") {
                    return { status: "Kladde", class: "warning" };
                }
                if (item.status == "Pending") {
                    return { status: "Afventer godkendelse", class: "warning" };
                }
                if (item.status == "Cancelled") {
                    return { status: "Opsagt", class: "danger" };
                }
            }

            return null;
        },
        paymentStatus(status) {
            if (status) {
                if (status == "Unsent") {
                    return { status: "Faktura ikke sendt", class: "warning" };
                }
                if (status == "Billed") {
                    return { status: "Faktureret", class: "warning" };
                }
                if (status == "Paid") {
                    return { status: "Betalt", class: "success" };
                }
                if (status == "Credited") {
                    return { status: "Krediteret", class: "danger" };
                }
            }

            return null;
        }
	},
    created() {

    },
    mounted() {
    }
};
</script>